const keyMirror = require('keymirror');

export const endPoints = {
  GET_COMPANIES: 'api/posv3/maintenance/GetLsp',

  GET_ASSIGNED_LSPS: 'api/posv3/user/GetAssignedLsps',
  GET_AVAILABLE_LSPS: 'api/posv3/user/GetAvailableLsps',
  ASSIGN_LSP: 'api/posv3/user/AssignLsp',
  REMOVE_LSP: 'api/posv3/user/RemoveLsp',

  SET_DEFAULT_LOC: 'api/posv3/maintenance/UpdateDefaultLspLocation',
  SET_DEFAULT_COMPANY: 'api/posv3/maintenance/UpdateDefaultLsp',

  UPDATE_USER_PASSWORD: 'api/posv3/maintenance/UpdateUserPassword',

  EMPLOYEE_CLOCK: 'api/posv3/maintenance/UpdateEmployeeClock',
  CHECK_EMPLOYEE_CLOCK: 'api/posv3/maintenance/GetEmployeeClockStatus',
  SET_EMPLOYEE_CLOCK: 'api/posv3/maintenance/UpdateEmployeeClockAdjustment',
  GET_EMPLOYEE_CLOCKS: 'api/posv3/maintenance/GetEmployeeClocks',

  CHECK_MANAGER_PASSWORD: 'api/v2/discount/CheckManagerApproval',

  SET_SINGLE_USER_SETTING: 'api/posv3/user/SetSingleUserSettings',
  GET_USER_SETTINGS: 'api/posv3/user/GetUserSettings',
  SET_USER_SETTINGS: 'api/posv3/user/SetUserSettings',

  GET_CUSTOMER_TYPES: 'api/posv3/maintenance/get-customer-types',

  GET_USER_GROUP_LIST_FOR_LSP_LOC: 'api/posv3/maintenance/get-user-group-list-for-lsp-loc',

  CHECK_MANAGER_PIN: 'api/posv3/maintenance/EmployeePinCode',
};

export const actions = keyMirror({
  GET_COMPANIES: null,
  SELECT_COMPANY: null,
  SELECT_LOCATION: null,
  SET_USER: null,
  SET_TABLE_SETTINGS: null,
  SET_FEATURES: null,
  SET_VALIDATED_FORMS: null,
  SET_RESTRICTED_HOURS: null,
  SET_DELIVERY_HOURS: null,
});

export const events = keyMirror({
  CHANGE_EVENT: 'changeEvent',
});
