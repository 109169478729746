import UserStore from 'src/app_deprecated/stores/UserStore';
import AjaxPromises from 'src/app_deprecated/utils/AjaxPromises';
import { getDutchiePOSURL } from 'src/app/utils/get-dutchie-pos-url';

export async function redirectToBackoffice() {
  let tokenParam = '';

  try {
    const payload = UserStore.getApiData();
    const endpoint = 'api/posv3/user/login-token/generate';
    const response = await AjaxPromises.POST(endpoint, payload);

    const token = response.Data;

    if (token) {
      tokenParam = `?llx-token=${String(token)}`;
    }
  } catch (error) {
    console.error(error);
  } finally {
    const url = getDutchiePOSURL();
    window.location.replace(`${url}${tokenParam}`);
  }
}
