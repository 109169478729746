import { atom } from 'recoil';
import { getPersistedValue, setPersistedValue } from '../utils/use-persisted-value';

export const localOptionalRedirectFlagAtom = atom({
  key: 'localOptionalRedirectFlag',
  default: false,
  effects_UNSTABLE: [useLocalStorageEffect],
});

function useLocalStorageEffect({ setSelf, onSet }) {
  const key = 'use-dutchie-pos';
  const savedValue = getPersistedValue(key, false);
  const params = new URLSearchParams(window.location.search);
  const disabled = params.get('disable-dutchie-pos') === 'true';

  if (disabled) {
    setSelf(false);
    setPersistedValue(key, false);
    window.history.pushState(null, '', window.location.pathname);
  } else if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet((newValue) => {
    setPersistedValue(key, newValue);
  });
}
