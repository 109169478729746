const keyMirror = require('keymirror');

export const endPoints = {
  GET_ENVIRONMENT_DETAILS: 'api/admin/environment-details',
  EMPLOYEE_LOGIN_DETAILS: 'api/posv3/user/EmployeeLoginDetails',

  GET_RELEASE_NOTES: 'api/posv3/maintenance/ReleaseNotes',
  UPDATE_RELEASE_NOTES: 'api/posv3/maintenance/UpdateReleaseNotes',

  GET_FAQ_NOTES: 'api/posv3/maintenance/FaqNotes',
  UPDATE_FAQ_NOTES: 'api/posv3/maintenance/UpdateFaqNotes',

  GET_FEATURES: 'api/posv3/maintenance/get_loc_features',
  SET_FEATURES: 'api/posv3/maintenance/set_loc_features',

  GET_VALIDATED_FROMS: 'api/posv3/maintenance/get_validated_forms',
  SET_VALIDATED_FROMS: 'api/posv3/maintenance/set_validated_forms',

  GET_STYLING: 'api/posv3/maintenance/get_loc_styling',
  SET_STYLING: 'api/posv3/maintenance/set_loc_styling',

  GET_SPROUT: 'api/posv3/maintenance/get_sprout_configuration',
  SET_SPROUT: 'api/posv3/maintenance/set_sprout_configuration',

  GET_LOCATION_SETTINGS: 'api/posv3/maintenance/get_loc_settings',

  GET_OPTIONS: 'api/posv3/maintenance/get_loc_options',
  SET_OPTIONS: 'api/posv3/maintenance/set_loc_options',

  GET_PACKAGE_IDENTITY_FORMATS: 'api/posv3/maintenance/get_package_identity_formats',
  SET_PACKAGE_IDENTITY_FORMAT: 'api/posv3/maintenance/set_package_identity_format',

  GET_BATCH_IDENTITY_FORMAT: 'api/posv3/maintenance/get_batch_identity_format',
  SET_BATCH_IDENTITY_FORMAT: 'api/posv3/maintenance/set_batch_identity_format',

  GET_SKU_FORMATS: 'api/posv3/maintenance/get_sku_formats',
  SET_SKU_FORMAT: 'api/posv3/maintenance/set_sku_format',

  GET_DELIVERY_TITLE_FORMATS: 'api/posv3/maintenance/get_deliverytitle_formats',
  SET_DELIVERY_TITLE_FORMAT: 'api/posv3/maintenance/set_deliverytitle_format',

  ERROR: '/api/test/broken',
  ERROR_BIOTRACK: '/api/test/broken-biotrack',
  ERROR_METRC: '/api/test/broken-metrc',
  ERROR_HYPUR: '/api/test/broken-hypur',

  CREATE_COMPANY: 'api/posv3/lsplocation/create-company',
  CREATE_LOCATION: 'api/posv3/lsplocation/create-location',
  GET_HELP_DESK: 'api/posv3/lsplocation/get-lsp-help-desk',
  GET_LSP_LOC_INFO: 'api/posv3/lsplocation/get-lsp-loc-info',
  SAVE_LSP_INFO: 'api/posv3/lsplocation/save-lsp-info',
  SAVE_LOC_INFO: 'api/posv3/lsplocation/save-loc-info',
  UPDATE_LOGO: 'api/posv3/lsplocation/update-logo',
  UPDATE_LOCATION_LOGO: 'api/posv3/lsplocation/update-location-logo',
  GET_AUDIT_DATA: 'api/posv3/lsplocation/get-audit-data',
  SET_DEFAULT_DELIVERY_WINDOW: 'api/posv3/lsplocation/set_default_delivery_window',

  GET_SELECTED_MANIFEST: 'api/posv3/lsplocation/get-selected-manifest',
  GET_MANIFEST_OPTIONS: 'api/posv3/lsplocation/get-manifest-options',
  SET_MANIFEST_OPTIONS: 'api/posv3/lsplocation/set-manifest-options',

  SYNC_LOOKER_USERS: 'api/looker/sync-users',

  METRC_BATCHMODE_GET_SHIPMENTS: 'api/metrc/get-batch-shipments',
  METRC_BATCHMODE_QUEUE: 'api/metrc/queue-for-batch',
  METRC_BATCHMODE_RECONCILE: 'api/metrc/reconcile-batchmode-shipments',
  METRC_BATCHMODE_UNRECONCILE: 'api/metrc/unreconcile-batchmode-shipments',
};

export const actions = keyMirror({
  APP_BOOT: null,
  BUILD_NUMBER: null,
  API_VERSION_NUMBER: null,
  REGION: null,
  LOCATION_LOADED: null,
  APP_BOOTED: null,
  BANNER: null,
});
export const events = keyMirror({
  CHANGE_EVENT: 'changeEvent',
  BUILD_NUMBER: null,
  API_VERSION_NUMBER: null,
  BANNER: null,
  REGION: null,
});

export const fileSize = {
  FOUR_MB: 4194304,
};
