import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import XHR from 'i18next-xhr-backend';

import translationEn from './locales/en/translation.json';
import translationEs from './locales/es/translation.json';

const backEndOptions = {
  loadPath: './locales/{(lng)}/{(ns)}.json',
};

i18n
  .use(LanguageDetector)
  .use(XHR)
  .use(reactI18nextModule)
  .init({
    debug: false,
    fallbackLng: 'en', // use en if detected lng is not available
    keySeparator: '>',
    nsSeparator: '|',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    resources: {
      en: {
        translations: translationEn,
      },
      es: {
        translations: translationEs,
      },
    },
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
  });

export default i18n;
