import _ from 'lodash';
// Safe localstorage access helpers pulled from GetDutchie/Dutchie codebase

const inMemoryStore = {
  setItem(key, value) {
    if (key === 'setItem' || key === 'getItem') {
      throw new Error(`Trying to overwrite ${key} which is a reserved key in storage`);
    }
    inMemoryStore[key] = value;
  },
  getItem(key) {
    return inMemoryStore[key];
  },
};

export function setPersistedValue(key, value, sessionOnly = false) {
  const setValue = (storage) => {
    if (_.isNil(value)) {
      storage.removeItem(key);
    } else {
      value = JSON.stringify(value);
      storage.setItem(key, value);
    }
  };
  // try local storage first
  if (!sessionOnly) {
    try {
      setValue(localStorage);
      return;
    } catch (e) {
      console.error(e);
    }
  }

  // then session storage
  try {
    setValue(sessionStorage);
    return;
  } catch (e) {
    console.error(e);
  }

  // then just use in-memory
  try {
    setValue(inMemoryStore);
  } catch (e) {
    console.error(e);
  }
}

export function getPersistedValue(key, defaultValue) {
  try {
    if (typeof sessionStorage !== 'undefined' && sessionStorage[key]) {
      return JSON.parse(sessionStorage[key]);
    }
  } catch (e) {
    console.error(e);
  }

  try {
    if (typeof localStorage !== 'undefined' && localStorage[key]) {
      return JSON.parse(localStorage[key]);
    }
  } catch (e) {
    console.error(e);
  }

  try {
    if (inMemoryStore[key]) {
      return JSON.parse(inMemoryStore[key]);
    }
  } catch (e) {
    console.error(e);
  }

  return defaultValue ?? null;
}
