import _ from 'lodash';

/**
 * Launch Darkly is user-centric, so we're using JSON feature flags to enable flagging features by location.
 * This helper checks if a location has a feature enabled, or warns if the value is not an object.
 *
 * type LocationFeatureFlagValue {
 *   enabledForAll: Boolean
 *   enabledForLocations?: string[]
 *   variant: string
 * }
 *
 * @param {string} key
 * @param {(Object|boolean)} featureFlagValue
 * @param {string} locationId
 * @param {boolean} defaultValue
 */
export default function evaluateLocationFeatureFlag(key, featureFlagValue, locationId, defaultValue = false) {
  if (!_.isUndefined(featureFlagValue)) {
    if (_.isObject(featureFlagValue)) {
      if (featureFlagValue?.disabledForLocations && _.includes(featureFlagValue.disabledForLocations, locationId)) {
        return false;
      }

      if (featureFlagValue?.enabledForAll) {
        return true;
      }

      if (!locationId) {
        console.warn(
          // eslint-disable-next-line max-len
          `Warning - evaluateLocationFeatureFlag called on ${key}, but no locationId was provided. Default value (${defaultValue}) returned.`
        );
        return defaultValue;
      }

      return _.includes(featureFlagValue?.enabledForLocations, locationId);
    }

    if (_.isBoolean(featureFlagValue)) {
      console.warn(
        // eslint-disable-next-line max-len
        `Warning - evaluateLocationFeatureFlag called on ${key}, but received boolean featureFlagValue instead of an object; please configure flag to use location pattern. featureFlagValue (${featureFlagValue}) returned.`
      );
      return featureFlagValue;
    }

    console.warn(
      // eslint-disable-next-line max-len
      `Warning - evaluateLocationFeatureFlag called on ${key}, but featureFlagValue received was not an object; please configure flag to use location pattern. Default value (${defaultValue}) returned.`
    );
  }

  return defaultValue;
}

/**
 * Helper for checking flags in a class component
 *
 * @param {string} key: launch darkly key for feature flag
 * @param {Object} flags: launch darkly flags use withLDConsumer to access this via props (props.flags)
 * @param {string} locationId
 * @param {boolean} defaultValue default returned if locationId is not provided or proper LD response is not received
 */
export function flagIsEnabledForLocation(key, flags, locationId, defaultValue = false) {
  const featureFlagValue = flags[key];
  return evaluateLocationFeatureFlag(key, featureFlagValue, locationId, defaultValue);
}
