import { EventEmitter } from 'events';
import _ from 'lodash';
import AppDispatcher from '../utils/AppDispatcher';
import { events as InventoryEvents, endPoints } from '../constants/InventoryConstants';
import { actions as AppEvents } from '../constants/AppConstants';
import UserStore from './UserStore';
import AjaxPromises from '../utils/AjaxPromises.jsx';
import NotificationActions from '../actions/NotificationActions';

export abstract class SimpleCachedDataStore extends EventEmitter {
  protected sourceUrl = null;
  protected entity = null;
  private _data = null;
  private _loaded = false;
  private _loading = false;

  constructor() {
    super();
    this.refresh = this.refresh.bind(this);
    this.clear = this.clear.bind(this);
    this.actionHandler = this.actionHandler.bind(this);
    AppDispatcher.register(this.actionHandler);
  }

  protected get data() {
    if (!this._loaded && !this._loading) {
      this.refresh();
    }
    return this._data || [];
  }

  public get loading() {
    return this._loading;
  }

  public async refresh(showSuccess = false) {
    this._loading = true;

    if (this.sourceUrl === null) {
      console.error('sourceUrl cannot be null');
      return;
    }
    try {
      const data = UserStore.getApiData();
      const resp = await AjaxPromises.POST(this.sourceUrl, data);
      if (resp.Result) {
        this._loaded = true;
        this._data = resp.Data;
        if (showSuccess) {
          NotificationActions.success(`${this.entity} Refreshed`);
        }
      }
    } catch {
      console.warn(`${this.sourceUrl} failed to load`);
      this._loaded = true;
    } finally {
      this._loading = false;
      this._loaded = true;
      this.emitChange(this._data);
    }
  }

  protected emitChange(callback) {
    this.emit(InventoryEvents.CHANGE_EVENT, callback);
  }

  public addChangeListener(callback) {
    this.on(InventoryEvents.CHANGE_EVENT, callback);
  }

  public removeChangeListener(callback) {
    this.removeListener(InventoryEvents.CHANGE_EVENT, callback);
  }
  private clear() {
    this._data = null;
    this._loaded = false;
    this._loading = false;
  }

  public actionHandler(action) {
    switch (action.actionType) {
      case AppEvents.APP_BOOTED:
        this.clear();
        break;
      case AppEvents.LOCATION_LOADED:
        this.clear();
        this.emitChange(this._data);
        break;
    }
  }
}
