import _ from 'lodash';
import AjaxPromises from '../utils/AjaxPromises.jsx';
import UserStore from './UserStore';
import { SimpleCachedDataStore } from './SimpleCachedDataStore';
import { endPoints as InventoryEndPoints } from '../constants/InventoryConstants';

class MMURFormsStore extends SimpleCachedDataStore {
  constructor() {
    super();
  }

  sourceUrl = InventoryEndPoints.GET_MMUR_FORMS;
  entity = 'MMUR Forms';

  get forms() {
    return this.data;
  }

  get orderTypes() {
    return [
      { Name: 'Low-THC Cannabis', value: 1 },
      { Name: 'Medical Cannabis', value: 2 },
      { Name: 'Smoking', value: 3 },
      { Name: 'Low THC and Medical Cannabis', value: 99 },
    ];
  }

  emitChange(change) {
    super.emitChange(change);
  }
}
export default new MMURFormsStore();
