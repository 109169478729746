import moment from 'moment';
import { EventEmitter } from 'events';
import AppDispatcher from '../utils/AppDispatcher';

import {
  actions as ReportDashboardActions,
  events as ReportDashboardEvents,
} from '../constants/ReportDashboardConstants';

const _state = {
  date: moment(new Date()).format('YYYY-MM-DD'),
  month: moment(new Date()).format('M'),
  year: moment(new Date()).format('YYYY'),

  transactionSummary: false,
  transactionPerDate: false,
  transactionPerCategory: false,

  dailySalesSummary: false,
};

class ReportDashboardStoreClass extends EventEmitter {
  getState() {
    return _state;
  }

  emitChange(callback) {
    this.emit(ReportDashboardEvents.CHANGE_EVENT, callback);
  }

  addChangeListener(callback) {
    this.on(ReportDashboardEvents.CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(ReportDashboardEvents.CHANGE_EVENT, callback);
  }
}

const ReportDashboardStore = new ReportDashboardStoreClass();

ReportDashboardStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case ReportDashboardActions.SET_DATE:
      _state.date = action.data.date;
      _state.transactionSummary = action.data.transactionSummary;
      _state.transactionPerDate = action.data.transactionPerDate;
      _state.transactionPerCategory = action.data.transactionPerCategory;
      ReportDashboardStore.emitChange();
      break;

    case ReportDashboardActions.SET_MONTH:
      _state.month = action.data.month;
      _state.year = action.data.year;
      _state.dailySalesSummary = action.data.dailySalesSummary;
      ReportDashboardStore.emitChange();
      break;

    // case ReportDashboardActions.REPORT_LOADED:
    //     switch (action.reportId)
    //     {
    //         case 1078:
    //             _state.transactionSummary = action.data;
    //             break;

    //         case 1082:
    //             _state.transactionPerDate = action.data;
    //             break;

    //         case 19010:
    //             _state.transactionPerCategory = action.data;
    //             break;
    //     }
    //     ReportDashboardStore.emitChange();
    //     break;
  }
});

export default ReportDashboardStore;
