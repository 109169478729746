import { EventEmitter } from 'events';
import _ from 'lodash';
import NotificationActions from '../actions/NotificationActions';
import AppDispatcher from '../utils/AppDispatcher';
import { events as InventoryEvents, endPoints } from '../constants/InventoryConstants';
import { actions as AppEvents } from '../constants/AppConstants';
import { actions as UserActions } from '../constants/UserConstants';
import UserStore from './UserStore';
import AjaxPromises from '../utils/AjaxPromises.jsx';
import { endPoints as RecipeEndpoints } from '../constants/RecipeBatchConstants';

class RecipeBatchStore extends EventEmitter {
  state = {
    batches: [],
    history: [],
  };
  status = false;
  refreshing = false;

  constructor() {
    super();
    this.refresh = this.refresh.bind(this);
    this.clear = this.clear.bind(this);
    this.actionHandler = this.actionHandler.bind(this);
    AppDispatcher.register(this.actionHandler);
  }

  async deleteBatch(batch) {
    const data = UserStore.getApiData();
    data.RecipeBatchId = batch.RecipeBatchId;
    try {
      const resp = await AjaxPromises.POST(RecipeEndpoints.DELETE_BATCH, data);
      if (resp.Result) {
        this.state.batches = _.filter(this.state.batches, (b) => b.RecipeBatchId !== batch.RecipeBatchId);
        this.emitChange(this.state);
        NotificationActions.success('Batch deleted');
      } else {
        NotificationActions.error(`Error deleting batch. ${resp.Message}`);
      }
    } catch (ex) {
      NotificationActions.error('Error deleting batch.');
    }
  }

  get batches() {
    if (!this.status) {
      this.status = true;
      this.refresh();
    }
    return this.state.batches;
  }

  get history() {
    if (!this.status) {
      this.status = true;
      this.refresh();
    }
    return this.state.history;
  }

  public async refresh() {
    if (this.refreshing) {
      return;
    }

    this.refreshing = true;
    this.status = true;
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(RecipeEndpoints.GET_ACTIVE_BATCHES, data);
      const respHistory = await AjaxPromises.POST(RecipeEndpoints.GET_BATCH_HISTORY, data);
      if (!!resp.Result && !!respHistory.Result) {
        this.state.batches = resp.Data;
        this.state.history = respHistory.Data;
        this.emitChange(this.state);
      }
    } catch (ex) {
      console.warn('Recipe Batches failed to load:', ex);
      this.status = false;
    } finally {
      this.refreshing = false;
    }
  }

  emitChange(callback) {
    this.emit(InventoryEvents.CHANGE_EVENT, callback);
  }

  addChangeListener(callback) {
    this.on(InventoryEvents.CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(InventoryEvents.CHANGE_EVENT, callback);
  }
  private clear() {
    this.state.batches = [];
    this.state.history = [];
    this.status = false;
  }

  actionHandler(action) {
    switch (action.actionType) {
      case AppEvents.APP_BOOTED:
        this.refresh();
        break;
      case AppEvents.LOCATION_LOADED:
        this.clear();
        this.emitChange(this.state);
    }
  }
}
export default new RecipeBatchStore();
