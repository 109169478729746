{
  "- Abilit to archive customers": "- Posibilidad de archivar clientes",
  "- Ability to create a waste manager entry upon Plant retirement": "- Posibilidad de crear una entrada de administrador de residuos al retirarse de la planta",
  "- Ability to track immature plants individually": "- Capacidad para rastrear plantas inmaduras individualmente",
  " add discounts to group": " agregar descuentos al grupo",
  "- AZDHS Integration": "- Integración AZDHS",
  "- BioTrack Integration upgrades": "- Actualizaciones de Integración BioTrack",
  "- Brand support for products & menus": "- Soporte de marca para productos y menús.",
  "- Caregiver selection": "- Selección de cuidador",
  "- Completion of the Purchase Order module": "- Finalización del módulo de orden de compra",
  "- Coupons can now be assigned a product category in product master": "- a Los cupones ahora se les puede asignar una categoría de producto en el maestro de productos",
  "- Coupons can now be set to \"Taxable\"": "- Los cupones ahora se pueden configurar en \"imponible \"",
  " discount groups": " grupos de descuento",
  " edit discounts in group": " editar descuentos en grupo",
  "- Effecive permissions viewer": "- Visor de permisos efectivo",
  "- Electronic signatures can now be required upon checkout.": "- ahora se pueden requerir firmas electrónicas al momento de pagar.",
  "- Emailed receipt options": "- opciones de recibo por correo electrónico",
  "- Flight Discounts": "- Descuentos en vuelos",
  "- METRC client can now self-service the maintenance of individual employee API keys": "- el cliente METRC ahora puede autoservicio el mantenimiento de las claves API de Los empleados individuales",
  "- METRC Integration upgrades": "- Actualizaciones de Integración METRC",
  "- New configuration options": "- Nuevas opciones de configuración",
  "- New lab result fields": "- Nuevos campos de resultados de laboratorio",
  "- Package History viewer": "- Visor de historial de paquetes",
  "- Plant manager performance improvements": "- Mejoras de rendimiento del gerente de planta",
  "- PMP Enhancements": "- Mejoras de PMP",
  "-- Select Label --": "-- Seleccione etiqueta --",
  "-- Select Loc --": "- Seleccione Loc -",
  "-- Select Lsp --": "-- Seleccione Lsp --",
  "- Users can now remove a product image from product master using the \"remove product image\" button at the bottom": "- Los usuarios ahora pueden eliminar una imagen de producto del producto maestro usando el botón \"eliminar imagen de producto \" en la parte inferior",
  "-$ = Reduce Register": "- $ = Reducir registro",
  "(Canada Locations) Use OCS SKU/UPC": "(Ubicaciones de Canadá) Utilizar OCS SKU/UPC",
  "(Drivers) License Expiration": "(Conductores) Caducidad de la licencia",
  "(Drivers) License Number": "(Número de carnet de conducir",
  "(The new closing report is most easily identified by having a start and end date with time as opposed to just a single date input).": "(El nuevo informe de cierre se identifica más fácilmente al tener una fecha de inicio y finalización con tiempo en lugar de una sola entrada de fecha).",
  "+$ = Increase Register": "+ $ = Aumentar Registro",
  "24 Hour Period Starting:": "Inicio del Período de 24 Horas:",
  "6/11/19 Release - Highlights Include:": "11/06/19 Lanzamiento - Los aspectos más destacados incluyen:",
  "A brand new permissions system has been released.  Managers and administrators can now assign very granular permissions to users and user groups using the maintenance menus. ": "Se ha lanzado un nuevo sistema de permisos. Los administradores y administradores ahora pueden asignar permisos muy granulares a usuarios y grupos de usuarios utilizando los menús de mantenimiento.",
  "A new Register Close Out UI has been released.  Users will now see a link saying \"Try the new version\" at the top of Register Transactions Screen under POS > Daily Activities.  ": "Se ha lanzado una nueva interfaz de usuario de Registro de cierre. Los usuarios verán ahora un enlace que dice \"Pruebe la nueva versión \" en la parte superior de la pantalla Registrar transacciones en POS> Actividades diarias.",
  "A new window will open where you will be prompted to login. When it closes, return to this window to complete setup.": "Se abrirá una nueva ventana donde se le pedirá que inicie sesión. Cuando se cierre, regrese a esta ventana para completar la configuración.",
  "Abbreviation": "Abreviatura",
  "Absolute": "Absoluto",
  "Accept Can Pay": "Aceptar Can Pay",
  "Accept Hypur": "Aceptar Hypur",
  "Accept Linx": "Aceptar Linx",
  "Accessory": "Accesorio",
  "Account Type": "Tipo de Cuenta",
  "Accounts Payable": "Cuentas por Pagar",
  "Accounts Receivable": "Cuentas por Cobrar",
  "Accrual Rate": "Tasa de Acumulación",
  "Acct Status": "Estado de la Cuenta",
  "Action": "Acción",
  "Actions": "Comportamiento",
  "Active": "Activo",
  "Active Plants": "Plantas activas",
  "Actual": "Real",
  "Actual Arrival Date": "Fecha de Llegada Real",
  "Add": "Añadir",
  "ADD": "AÑADIR",
  "Add Delivery Options to Ecom": "Agregar opciones de entrega a Ecom",
  "Add Discounts": "Añadir Descuentos",
  "Add Doctor": "Añadir Doctor",
  "Add Inventory To Order": "Añadir Inventario al Pedido",
  "Add item to manifest": "Agregar elemento al manifiesto",
  "Add Key": "Añadir llave",
  "Add new": "Agregar nuevo",
  "Add New Coupon": "Añadir Nuevo Cupón",
  "Add new discount": "Añadir nuevo descuento",
  "Add new label": "Agregar nueva etiqueta",
  "Add new note": "Agregar nueva nota",
  "Add new Reconciliation": "Agregar nueva Reconciliación",
  "Add new status": "Agregar nuevo estado",
  "Add NewTransaction to POS - allows dispensaries to add items to a users cart, without checking them in and polluting the checkin list. Useful for pre-orders.": "Agregar NewTransaction a POS: permite que los dispensarios agreguen artículos a un carrito de usuarios, sin registrarlos y contaminar la lista de registro. Útil para pedidos anticipados.",
  "Add Package": "Agregar Paquete",
  "Add Plant": "Agregar Planta",
  "Add Plant/Clones to Inventory": "Agregar Planta/Clones al Inventario",
  "Add Plants to Inventory": "Agregar Plantas al Inventario",
  "Add Recipe:": "Agregar Receta:",
  "Add Selected": "Añadir Seleccionados",
  "Add Table": "Agregar Tabla",
  "Add Tags": "Agregar Etiquetas",
  "Add to Order": "Agregar a la Orden",
  "Add/Edit Step": "Añadir/Editar Paso",
  "Added from": "Agregado desde",
  "Additional Fields": "Campos Adicionales",
  "Address": "Dirección",
  "Address 1": "Dirección 1",
  "Address 2": "Dirección 2",
  "Address 3": "Dirección 3",
  "Address added successfully": "Dirección agregada exitosamente",
  "Address Line 1": "Dirección Línea 1",
  "Address Line 2": "Dirección Línea 2",
  "Adds an option in POS settings to display the cart in a 2nd window.": "Agrega una opción en la configuración de POS para mostrar el carrito en una segunda ventana.",
  "Adds support on POS to allow adjust caregiver loyalty points.": "Agrega soporte en POS para permitir ajustar los puntos de fidelidad del cuidador.",
  "Adjust": "Ajustar",
  "Adjust and Close": "Ajustar y Cerrar",
  "Adjust Available": "Ajuste Disponible",
  "Adjust Balance": "Ajustar el Equilibrio",
  "Adjust Employee Clock": "Ajustar Reloj de Empleado",
  "Adjusted From": "Ajustado Desde",
  "Adjusted To": "Ajustado A",
  "Adjustment posted succesfully": "Ajuste publicado con éxito",
  "Adjustment Reasons": "Razones de Ajuste",
  "Adjustment Type": "Tipo de Ajuste",
  "Adjustments": "Ajustes",
  "Administrative": "Administrativo",
  "All": "Todas",
  "All actions": "Todas las acciones",
  "All Batches": "Todos los Lotes",
  "All Cannabis": "Todo el Cannabis",
  "All Categories": "Todas las Categorias",
  "All Inventory": "Todo el Inventario",
  "All inventory actions have been moved to the gray action wheel on the far right side of the screen.": "Todas las acciones de inventario se han movido a la rueda de acción gris en el extremo derecho de la pantalla.",
  "All Registers": "Todos los Registros",
  "All Rooms": "Todas las Habitaciones",
  "All Stages": "Todas las Etapas",
  "All Status": "Todo el Estado",
  "All Strains": "Todas las Cepas",
  "All systems will be upgraded to the new closing report with the next release. If you would like to move over sooner please let us know at support@leaflogix.com.": "Todos los sistemas se actualizarán al nuevo informe de cierre con la próxima versión. Si desea mudarse antes, infórmenos en support@leaflogix.com.",
  "All Testing Statuses": "Todos los Estados de Prueba",
  "All Types": "Todos los Tipos",
  "Allergens": "Alergenos",
  "Allow Adjust Caregiver Loyalty Points": "Permitir ajuste de puntos de fidelidad del cuidador",
  "Allow Adjustments": "Permitir Ajustes",
  "Allow Backdated Register Transactions": "Permitir transacciones de registro retroactivas",
  "Allow Customer to Exceed Allotment": "Permitir al cliente exceder la asignación",
  "Allow Default Product Labels": "Permitir etiquetas de producto predeterminadas",
  "Allow Employee to Exceed Allotment": "Permitir al empleado exceder la asignación",
  "Allow Exp Dt Through": "Permitir Exp Dt a través",
  "Allow Medical Only Packages": "Permitir paquetes solo médicos",
  "Allows clients to edit days supply for completed transactions on the transactions page in the backend": "Permite a los clientes editar el suministro de días para las transacciones completadas en la página de transacciones en el back-end",
  "Allows LSP (CANADA ONLY) to use the OCS SKU and UPC fields on the Product Master": "Permite que LSP (SOLO CANADÁ) use los campos OCS SKU y UPC en el Product Master",
  "Alternate Description": "Descripción Alternativa",
  "Alternate Name": "Nombre Alternativo",
  "Amount": "Cantidad",
  "Amount Off Price": "Cantidad de Precio",
  "Amount to Restore": "Cantidad Para Restaurar",
  "An error occurred": "Ocurrió un error",
  "An identical discontinue action needs to be manually performed in METRC": "Una acción de descontinuación idéntica debe realizarse manualmente en METRC",
  "API Key": "Llave API",
  "API Notice": "Aviso de API",
  "API Pre Order Noitfy": "API Pre Order Noitfy",
  "API Token": "Token API",
  "APIKey": "LlaveAPI",
  "Application Method": "Método de Aplicación",
  "Applied By": "Aplicado Por",
  "Applied On": "Aplicado En",
  "Applies to All Customers": "Aplica a Todos los Clientes",
  "Applies to All Products": "Aplica a Todos los Productos",
  "Applies to type": "Se aplica al tipo",
  "Apply": "Aplicar",
  "Apply Loyalty as a Discount": "Aplicar lealtad como descuento",
  "Apply loyalty pre-tax as a discount spread across all items in the cart": "Aplique lealtad antes de impuestos como un descuento extendido en todos los artículos en el carrito",
  "Apply Nutrients": "Aplicar Nutrientes",
  "Are you sure you want to remove these items from inventory?": "¿Estás seguro de que deseas eliminar estos artículos del inventario?",
  "Are you sure?": "¿Estás seguro?",
  "Arrival": "Llegada",
  "Asset Account": "Cuenta de Activo",
  "Assign Mother Status": "Asignar Estado Materno",
  "Assign Plant Group": "Asignar Grupo de Plantas",
  "Assign Plant Stage": "Asignar Etapa de Planta",
  "Assign POS Registers to Physical Hardware": "Asignar registros POS al hardware físico",
  "Assign Products to Coupon": "Asignar Productos al Cupón",
  "Assign Room and Table": "Asignar Habitación y Tabla",
  "Assign Status": "Asignar Estado",
  "Assign to Batch": "Asignar a Lote",
  "Assign Users": "Asignar Usuarios",
  "Assign/Remove Mother": "Asignar/Eliminar Madre",
  "AssignCultivationStageToRooms": "AsignarEtapaDeCultivoALasHabitaciones",
  "Attestation": "Atestación",
  "Auto print labels": "Etiquetas de impresión automática",
  "Auto Print Labels?": "Etiquetas de Impresión Automática?",
  "Auto print receipts": "Recibos de impresión automática",
  "Auto Print Receipts?": "Recibos de Impresión Automática?",
  "Auto Print?": "Auto Impresión?",
  "Auto-Fill Order": "Orden de Autocompletar",
  "AutoFill order?": "Orden de autocompletar?",
  "Auto-Fulfill Order": "Orden de Autocompletar",
  "Automatic": "Automático",
  "Automatically create and send Daily Sales Receipt Rollups IIF Export via Email for QuickBooks Desktop": "Cree y envíe automáticamente Exportaciones diarias de resúmenes de recibos Exportación IIF por correo electrónico para QuickBooks Desktop",
  "Automatically Create New Products On Transfer": "Crear Automáticamente Nuevos Productos en Transferencia",
  "Automatically pushes Daily Sales Receipt Rollups to QuickBooks Online": "Empuja automáticamente los paquetes acumulativos de recibos de ventas diarias a QuickBooks Online",
  "Available": "Disponible",
  "Average Cart": "Carrito Promedio",
  "Average Sale per Cart": "Venta Promedio por Carro",
  "Avg $ / Cart": "Promedio $ / Carrito",
  "Avg Response Time (ms)": "Tiempo de Respuesta Promedio (ms)",
  "Balance Adjustment": "Ajuste de Balance",
  "Batch": "Lote",
  "Batch #": "Lote #",
  "Batch Closed": "Lote Cerrado",
  "Batch Date": "Fecha de Lote",
  "Batch deleted": "Lote Eliminado",
  "Batch Documentation": "Documentación de Lote",
  "Batch Ingredient Details": "Detalles de Ingredientes de Lote",
  "Batch Journal": "Diario de Lote",
  "Batch Manager": "Administrador de Lotes",
  "Batch Name": "Nombre del Lote",
  "Batch Number": "Número de Lote",
  "Batch Size": "Tamaño del Lote",
  "Beta Caryophyllene": "Beta Cariofileno",
  "Beta Myrcene": "Beta Mirceno",
  "Beta Pinene": "Beta Pineno",
  "Big Online Description": "Descripción en Línea Grande",
  "BioT Driver Alt Id": "BioT Id Alt del Conductor",
  "BioT Driver Id": "BioT ID del Conductor",
  "BioTrack End": "BioTrack End",
  "BioTrack Start": "Inicio de BioTrack",
  "BioTrack Used": "BioTrack Usado",
  "Blind Audit": "Auditoría a Ciegas",
  "Blind Register Close Out": "Registro a ciegas Cerrar",
  "Book to Account": "Libro a Cuenta",
  "Book to Sales Account?": "Libro a la Cuenta de Ventas?",
  "Brand": "Marca",
  "Bud Weight": "Peso del Brote",
  "Budtender": "Presupuesto",
  "Budtenders": "Presupuestos",
  "Bud-Tenders": "Presupuestos",
  "Buy X Get 1 - All Products": "Compre X y obtenga 1 - Todos los productos",
  "Buy X Get 1 - Single Product": "Compre X y Obtenga 1 - Producto único",
  "By": "Por",
  "By pressing the shift key on your keyboard you toggle the quick-select options on/off.": "Al presionar la tecla shift en su teclado, activa / desactiva las opciones de selección rápida.",
  "Bypass 2FA": "Derivación 2FA",
  "Bypass State System": "Derivación Sistema Estatal",
  "Calendar Days": "Días del Calendario",
  "Can Access Facilities": "Puede Acceder a las Instalaciones",
  "Can Access Harvests": "Puede Acceder a las Cosechas",
  "Can Access Items": "Puede Acceder a Elementos",
  "Can Access Packages": "Puede Acceder a Paquetes",
  "Can Access Patients": "Puede Acceder a Pacientes",
  "Can Access Plants": "Puede Acceder a las Plantas",
  "Can Access Receipts": "Puede Acceder a los Recibos",
  "Can Access Strains": "Puede Acceder a las Cepas",
  "Cancel": "Cancelar",
  "Cancellations": "Cancelaciones",
  "Cannabinoids": "Cannabinoides",
  "Cannabis (Qty)": "Cannabis (Cantidad)",
  "Cannabis (Wgt)": "Cannabis (Wgt)",
  "Cannabis inputs will be limited to the selected batch": "Los insumos de cannabis se limitarán al lote seleccionado",
  "Cannabis Product": "Producto de Cannabis",
  "Cannabis Product (Y/N)": "Producto de cannabis (S / N)",
  "Cannabis Products": "Productos de Cannabis",
  "Cannabis Sales": "Venta de Cannabis",
  "Cannot discontinue because the package was converted or sold": "No se puede suspender porque el paquete se convirtió o se vendió",
  "Capture ReceiptIDs": "Capturar ID de Recibo",
  "Car": "Coche",
  "Caregiver": "Cuidador",
  "Caregiver DOB": "FDN del Cuidador",
  "Caregiver Email": "Correo Electrónico del Cuidador",
  "Caregiver Expiration Date": "Fecha de Vencimiento del Cuidador",
  "Caregiver Name": "Nombre del Cuidador",
  "Caregiver Notes": "Notas del Cuidador",
  "Caregiver Phone": "Teléfono del cuidador",
  "Caregiver StateId": "Id del Estado del Cuidador",
  "Caregivers and patients earn and spend the same loyalty.": "Los cuidadores y pacientes ganan y gastan la misma lealtad.",
  "Cars": "Coches",
  "Cars updated successfully": "Autos actualizados exitosamente",
  "Cart Avg": "Carrito Promedio",
  "Cartridge": "Cartucho",
  "Caryophyllene Oxide": "Óxido de Cariofileno",
  "Cash": "Efectivo",
  "Cash Activity": "Actividad de Efectivo",
  "Cash Back from Debit": "Devolución de Efectivo del Débito",
  "Cash Back From Debit (-)": "Devolución de Efectivo del Débito (-)",
  "Cash Counted": "Efectivo Contado",
  "Cash Deposits": "Depósitos en Efectivo",
  "Cash Drop": "Caída de Efectivo",
  "Cash Income": "Ingresos en Efectivo",
  "Cash Payments": "Pagos en Efectivo",
  "Cash Sales": "Ventas en Efectivo",
  "Cashflow": "Flujo de Efectivo",
  "Cashier": "Cajero",
  "Cashier Title": "Título del Cajero",
  "Categories": "Categorías",
  "Categories updated successfully": "Categorías actualizadas con éxito",
  "Categories will also need to be assigned to limit categories in order for them to count against a specific limit.  This is configurable in Maintenance > Product Categories.": "Las categorías también deberán asignarse a categorías límite para que puedan contar en un límite específico. Esto es configurable en Mantenimiento> Categorías.",
  "Category": "Categoría",
  "Category Name": "Nombre de la Categoría",
  "Category Summary": "Resumen de Categoría",
  "CBD Content": "Contenido de CBD",
  "Cell Phone": "Teléfono Móvil",
  "Change": "Cambio",
  "Change Permissions": "Cambiar Permisos",
  "Change Product": "Cambiar Producto",
  "Change to passwords in the \"Users\" section.": "Cambie a las contraseñas en la sección \"Usuarios \".",
  "Changing the product on this package will update all active packages that share Package ID in this location": "Cambiar el producto en este paquete actualizará todos los paquetes activos que comparten ID de paquete en esta ubicación",
  "Check": "Cheque",
  "Check out check": "Echa un vistazo",
  "Check out credit card": "Echa un vistazo a la tarjeta de crédito",
  "Check out debit": "Echa un vistazo a débito",
  "Check Patient Allotments": "Verifique las Asignaciones de Pacientes",
  "Chemical Residue": "Residuo Quimico",
  "Choose a merge survivor": "Elige un superviviente de fusión",
  "Choose File": "Elija el Archivo",
  "City": "Ciudad",
  "Clear": "Claro",
  "Clear Menu": "Borrar Menú",
  "Clear Search": "Borrar Búsqueda",
  "Click the checkbox on the left side of the table": "Haga clic en la casilla de verificación en el lado izquierdo de la tabla",
  "Click to select from list": "Haga clic para seleccionar de la lista",
  "Click to upload Image": "Haga clic para cargar la imagen",
  "Client Download": "Descarga del Cliente",
  "Client Secret": "Secreto del Cliente",
  "ClientId": "Id del Cliente",
  "Clock In": "Reloj En",
  "Clock Out": "Reloj Fuera",
  "Clocked In": "Registrado En",
  "Clocked Out": "Reloj",
  "Clone": "Clon",
  "Clones": "Clones",
  "Close": "Cerrar",
  "Close Date": "Fecha de Cierre",
  "Close Out": "Cerrar",
  "Close Reconciliation": "Cerrar Reconciliación",
  "Closed Batches": "Lotes Cerrados",
  "Closed On": "Cerrado El",
  "Closing Plant Count": "Recuento de Planta de Cierre",
  "Closing Report": "Informe de Cierre",
  "Closing report failed to load": "Informe de cierre no se pudo cargar",
  "Closing report loaded successfully": "Informe de cierre cargado correctamente",
  "Code": "Código",
  "Code / License Number": "Código / Número de Licencia",
  "Collect Data": "Recolectar Datos",
  "Color Styling": "Estilo de Color",
  "Combine": "Combinar",
  "Combine Caregiver and Patient Loyalty": "Combine el cuidador y la lealtad del paciente",
  "Comment": "Comentario",
  "Comments": "Comentarios",
  "Company": "Empresa",
  "Company created successfully": "Empresa creada con éxito",
  "Company Flag": "Bandera de la Empresa",
  "Company ID": "ID de la Compañía",
  "Company info": "Información de la compañía",
  "Company Name": "Nombre de Empresa",
  "Compare Inventory Rooms": "Comparar Salas de Inventario",
  "Complete Order": "Orden Completa",
  "Completed": "Terminado",
  "Computer": "Computadora",
  "Concentrate": "Concentrado",
  "Concentrates": "Concentrados",
  "Cones": "Conos",
  "Confirm": "Confirmar",
  "Confirm retire": "Confirmar retiro",
  "Confirm unretire": "Confirmar retirar",
  "Connect": "Conectar",
  "Connect this LeafLogix location with": "Conecte esta ubicación de LeafLogix con",
  "Connection Settings": "Configuraciones de Conexión",
  "Contact Email": "Email de Contacto",
  "Contact Name": "Nombre de Contacto",
  "Contact Phone": "Teléfono de Contacto",
  "Contaminants": "Contaminantes",
  "Conversion Date": "Fecha de Conversión",
  "Conversion Error": "Error de Conversión",
  "Convert": "Convertir",
  "Convert Clones to Plants": "Convertir Clones a Plantas",
  "Convert to Plant(s)": "Convertir a planta(s)",
  "converted from": "convertido de",
  "converted into": "convertido en",
  "Converted packages": "Paquetes convertidos",
  "Copy": "Copiar",
  "Copy could not be created": "No se pudo crear la copia",
  "Copy Created": "Copia Creada",
  "Copy Label": "Copiar Etiqueta",
  "Cost": "Costo",
  "Cost of Goods": "Coste de Bienes",
  "Cost of Goods Sold": "Costo de los Bienes Vendidos",
  "Cost Per Unit": "Costo Por Unidad",
  "Could not add item": "No se pudo agregar el elemento.",
  "Could not autofulfill item": "No se pudo completar automáticamente el artículo",
  "Could not create a new reconciliation": "No se pudo crear una nueva reconciliación",
  "Could not create group": "No se pudo crear el grupo",
  "Could not create sample": "No se pudo crear la muestra",
  "Could not load batch details.": "No se pudieron cargar los detalles del lote.",
  "Could not load data": "No se pudieron cargar los datos.",
  "Could not load journal entries.": "No se pudieron cargar entradas de diario.",
  "Could not load notes": "No se pudieron cargar notas",
  "Could not load packages": "No se pudieron cargar paquetes",
  "Could not load plants for batch": "No se pudieron cargar plantas para el lote",
  "Could not load users assigned to group.": "No se pudieron cargar los usuarios asignados al grupo.",
  "Could not move plants": "No se pudieron mover las plantas.",
  "Could not parse file": "No se pudo analizar el archivo",
  "Could not save features": "No se pudieron guardar las funciones",
  "Could not save forms": "No se pudieron guardar los formularios",
  "Could not save sprout configuration!": "¡No se pudo guardar la configuración del Sporut!",
  "Could not save table layout": "No se pudo guardar el diseño de la tabla",
  "Could not set time clock": "No se pudo configurar el reloj",
  "Could not update journal entry": "No se pudo actualizar la entrada del diario",
  "Could not update plant group.": "No se pudo actualizar el grupo de plantas.",
  "Could not update plant mother status.": "No se pudo actualizar el estado de la madre de la planta.",
  "Could not update plant mother.": "No se pudo actualizar la planta madre.",
  "Could not update plant stage.": "No se pudo actualizar la etapa de la planta.",
  "Could not update settings": "No se pudo actualizar la configuración",
  "Count": "Contar",
  "Counted By": "Contado Por",
  "Coupon Code": "Código Promocional",
  "Coupon Name": "Nombre del Cupón",
  "Coupon Orders": "Órdenes de Cupones",
  "Coupon updated": "Cupón actualizado",
  "Coupon Value ($)": "Valor del Cupón ($)",
  "Coupons": "Cupones",
  "Create": "Crear",
  "Create Batch": "Crear Lote",
  "Create Company": "Crear Empresa",
  "Create Group": "Crea un Grupo",
  "Create Lab Sample": "Crear Muestra de Laboratorio",
  "Create Manifest": "Crear Manifiesto",
  "Create New": "Crear Nuevo",
  "Create New Batch": "Crear Nuevo Lote",
  "Create New Mother Plant": "Crear Nueva Planta Madre",
  "Create New Pricing Tier": "Crear Nuevo Nivel de Precios",
  "Create New Recipe Batch": "Crear Nuevo Lote de Recetas",
  "Create Package": "Crear Paquete",
  "Create Packages": "Crear Paquetes",
  "Create Plant Reconciliation": "Crear Reconciliación de Plantas",
  "Create Product": "Crear Producto",
  "Create Purchase Order": "Crear Orden de Compra",
  "Create Quantity": "Crear Cantidad",
  "Create waste on plant retirement": "Crear desperdicio en el retiro de la planta",
  "Created": "Creado",
  "Created By": "Creado Por",
  "Created new package.": "Creado nuevo paquete.",
  "Created On": "Creado En",
  "Created Package Id": "ID del paquete creado",
  "Credit": "Crédito",
  "Credit Sales": "Ventas a Crédito",
  "Ctrl key": "Tecla Ctrl",
  "Cultivation": "Cultivo",
  "Cultivation Stage": "Etapa de Cultivo",
  "Cultivation System": "Sistema de cultivo",
  "Cultivator": "Cultivador",
  "Current Balance": "Saldo Actual",
  "Current calendar month": "Mes calendario actual",
  "Current calendar week": "Semana calendario actual",
  "Current Phase": "Fase actual",
  "Current Room": "Habitación Actual",
  "Current Step": "Paso Actual",
  "Cust Cnt": "Clnt Cnt",
  "Custom Entries": "Entradas Personalizadas",
  "Custom Result Name and Value": "Nombre de Resultado Personalizado y Valor",
  "Customer": "Cliente",
  "Customer Files": "Archivos de Clientes",
  "Customer ID": "ID del Cliente",
  "Customer List Count": "Lista de Clientes",
  "Customer Name": "Nombre del Cliente",
  "Customer Notes": "Notas de los Clientes",
  "Customer on Receipt": "Cliente en Recibo",
  "Customer Profile (Backend)": "Perfil del cliente (Backend)",
  "Customer Profile (POS)": "Perfil del Cliente (PDV)",
  "Customer Summary": "Resumen del Cliente",
  "Customer Title": "Titulo del Cliente",
  "Customer To Bill": "Cliente a Facturar",
  "Customer Type": "Tipo de Cliente",
  "Customer Types": "Tipos de Clientes",
  "Customer Types Updated": "Tipos de Clientes Actualizados",
  "Customer updated": "Cliente actualizado",
  "Customers": "Clientes",
  "Customers by Budtender": "Clientes por Budtender",
  "Customers Merged": "Clientes Fusionados",
  "Cycle Days": "Días de Ciclo",
  "Daily Activities": "Actividades Diarias",
  "Daily Snapshot": "Instantánea Diaria",
  "Data Entry Error": "Error de Entrada de Datos",
  "Data failed to update": "No se pudieron actualizar los datos.",
  "Data Field": "Campo de Datos",
  "Date": "Fecha",
  "Date Added": "Fecha Agregada",
  "Date Completed:": "Fecha de Finalización:",
  "Date Created": "Fecha de Creacion",
  "Date Entered": "Fecha Ingresada",
  "Date Exited": "Fecha de Salida",
  "Date of Birth": "Fecha de nacimiento",
  "Date Of Birth": "Fecha de Nacimiento",
  "Date Planted": "Fecha de Plantación",
  "Date Processed": "Fecha de Procesamiento",
  "Date Received": "Fecha de Recepción",
  "Date Started": "Fecha Iniciada",
  "Date:": "Fecha:",
  "days": "dias",
  "Days In Cycle": "Días en Ciclo",
  "Days In Flowering": "Días en Floración",
  "Days in Phase": "Días en Fase",
  "Days In Phase": "Días en Fase",
  "Days In Vegetation": "Días en la Vegetación",
  "Days Supply": "Suministro de Días",
  "DEA Number": "Número DEA",
  "Deactivate": "Desactivar",
  "Dear Valued Client,": "Estimado Cliente,",
  "Debit": "Débito",
  "Debit Sales": "Ventas de Débito",
  "Default label": "Etiqueta predeterminada",
  "Default Label": "Etiqueta Predeterminada",
  "Default Label Print": "Impresión de etiqueta predeterminada",
  "Default label printer": "Impresora de etiquetas predeterminada",
  "Default Location": "Ubicación Predeterminada",
  "Default POS Status": "Estado de PDV Predeterminado",
  "Default Pricing Tier": "Nivel de Precios Predeterminado",
  "Default Receipt Print": "Impresión de recibo predeterminada",
  "Default receipt printer": "Impresora de recibos predeterminada",
  "Default Status": "Estado Predeterminado",
  "Default Unit": "Unidad Predeterminada",
  "Default Unit (g, kg, lbs)": "Unidad predeterminada (g, kg, lbs)",
  "Defect": "Defecto",
  "De-Leaf Wet Weight": "Peso Húmedo de Hojas",
  "Delete": "Eliminar",
  "DELETE": "ELIMINAR",
  "Delete Batch": "Eliminar Lote",
  "Delete item from Receive?": "¿Eliminar elemento de Recibir?",
  "Delete Receive?": "¿Eliminar recibir?",
  "Delete This Receive?": "¿Eliminar esta recepción?",
  "Deleting Batch": "Eliminar Lote",
  "Delivered By": "Entregado Por",
  "Delivered On": "Entregado En",
  "Delivery Options": "Opciones de entrega",
  "Departure": "Salida",
  "Deposit": "Depositar",
  "Deposit Account": "Cuenta de Depósito",
  "Deposited At": "Depositado En",
  "Deposits": "Depósitos",
  "Description": "Descripción",
  "Description is required!": "¡Se requiere descripción!",
  "Destination Batch": "Lote de Destino",
  "Destroy": "Destruir",
  "Destroy Inventory": "Destruir inventario",
  "Destroy Seedling": "Destruir las Plántulas",
  "Destroyed": "Destruido",
  "Destroyed Comment": "Comentario Destruido",
  "Destroyed Date From": "Fecha Destruida Desde",
  "Destroyed Date To": "Fecha Destruida A",
  "Detail": "Detalle",
  "Detail Update": "Actualización Detallada",
  "Details": "Detalles",
  "Difference": "Diferencia",
  "Directions": "Direcciones",
  "Discontinue": "Interrumpir",
  "Discount": "Descuento",
  "Discount Applied To": "Descuento Aplicado a",
  "Discount Configuration": "Configuración de Descuento",
  "Discount Description": "Descripción del Descuento",
  "Discount Group": "Grupo de Descuento",
  "Discount Groups": "Grupos de Descuento",
  "Discount Id": "ID de Descuento",
  "Discount Method": "Método de Descuento",
  "Discount Type": "Tipo de Descuento",
  "Discount updated successfully": "Descuento actualizado con éxito",
  "Discounted": "Con Descuento",
  "Discounted Products": "Productos con Descuento",
  "Discounts": "Descuentos",
  "Display rows per table": "Mostrar filas por tabla",
  "Displays the purchase order section": "Muestra la sección de orden de compra.",
  "Distributor": "Distribuidor",
  "Distributor - Tax Exempt": "Distribuidor - Exento de Impuestos",
  "Do not allow patients to check in with an expired Driver's License.": "No permita que los pacientes se registren con una licencia de conducir vencida.",
  "DOB": "FDN",
  "Doctors": "Doctores",
  "Documentation": "Documentación",
  "Doing Business As": "Haciendo Negocios Como",
  "Don't Move": "No te Muevas",
  "Dosage": "Dosificación",
  "Dosages": "Dosis",
  "Download Bulk Template": "Descargar Plantilla a Granel",
  "Download": "Descargar",
  "DPI": "PPP",
  "Drink": "Bebida",
  "Driver": "Conductor",
  "Drivers": "Conductores",
  "Drivers License": "Licencia de Conducir",
  "Drivers License Expiration": "Caducidad de la Licencia de Conducir",
  "Drivers License Expiration Date": "Fecha de Vencimiento de la Licencia de Conducir",
  "Drivers updated successfully": "Controladores actualizados con éxito",
  "Drop": "Soltar",
  "Drop Cash": "Soltar Efectivo",
  "Dry waste": "Residuos secos",
  "Dry Weights": "Pesos Secos",
  "Due Customer": "Cliente Debido",
  "Duplicate Category": "Categoría Duplicado",
  "Duplicate DEA Number": "Número de DEA duplicado",
  "Duplicate Doctor Name": "Nombre de Doctor Duplicado",
  "Duplicate Doctor State Id Number": "Número de identificación del estado del médico duplicado",
  "Duplicate NPI Number": "Número duplicado de NPI",
  "Duplicate Product Category": "Categoría de Producto Duplicado",
  "Ecom Delivery Notes": "Notas de entrega de Ecom",
  "E-Commerce": "Comercio Electrónico",
  "Ecommerce Styling": "Estilo de Comercio Electrónico",
  "Edible": "Comestible",
  "Edibles": "Comestibles",
  "Edit": "Editar",
  "Edit / Close": "Editar / Cerrar",
  "Edit Address": "Editar Dirección",
  "Edit Cart": "Editar carro",
  "Edit Cart Partial": "Editar carro parcial",
  "Edit Company": "Editar Empresa",
  "Edit Company Information": "Editar Información de la Empresa",
  "Edit Days Supply On Completed Transactions": "Editar Días de Suministro en Transacciones Completadas",
  "Edit Details": "Editar Detalles",
  "Edit Discounts": "Editar Descuentos",
  "Edit Driver": "Editar Controlador",
  "Edit Driver Details": "Editar Detalles del Controlador",
  "Edit Notes": "Editar Notas",
  "Edit Printer": "Editar Impresora",
  "Edit Product": "Editar Producto",
  "Effective Permissions": "Permisos Efectivos",
  "Electronic Payments": "Pagos Electrónicos",
  "E-mail address": "Dirección de correo electrónico",
  "Email Address": "Dirección de Correo Electrónico",
  "Email Order": "Pedido por Correo Electrónico",
  "Email Receipt From": "Recibo de Correo Electrónico de",
  "Email Sent": "Email Enviado",
  "Email Settings": "Ajustes del Correo Electrónico",
  "Employee Time Clocks": "Relojes de Tiempo de Empleado",
  "Empty manifest can not be completed": "El manifiesto vacío no No se pudo completar el pedido.se puede completar",
  "EMPTY WEIGHT": "PESO VACIO",
  "Enable +/- Buttons in Cart": "Habilitar botones +/- en el carrito",
  "Enable +/- Buttons in Search": "Habilitar botones +/- en la búsqueda",
  "Enable Allotment Check": "Habilitar Comprobación de Adjudicación",
  "Enable Customer SMS": "Habilitar SMS de Customer",
  "Enable EcomV2": "Habilitar EcomV2",
  "Enable integration with IDScan.net": "Habilita la integración con ID Scan y la opción ID Check-In en POS",
  "Enable integration with sprout": "Permitir la integración con sprout",
  "Enable METRC Tag Control": "Habilitar control de etiqueta METRC",
  "Enable Nutrient Tracking": "Habilitar el seguimiento de nutrientes",
  "Enable Plant Search": "Habilitar búsqueda de planta",
  "Enable Quickbooks Integration": "Habilitar la integración de Quickbooks",
  "Enable Recreational Use": "Habilitar el uso recreativo",
  "Enable Register Adjustments": "Habilitar ajustes de registro",
  "Enable Spin Debit Integration": "Habilitar la integración de débito de giro",
  "Enable Sprout Integration": "Habilitar la integración de Sprout",
  "Enabled": "Habilitado",
  "Enables the METRC Tag Control for the Tag Manager": "Habilita el control de etiqueta METRC para el administrador de etiquetas",
  "End Date": "Fecha Final",
  "End Time": "Hora de Finalización",
  "Ending Balance": "Balance Final",
  "Ending Cash Balance": "Saldo Final de Efectivo",
  "Enforce POS Login Permission": "Hacer cumplir el permiso de inicio de sesión POS",
  "Enter 3 or 4 of 11": "Entre 3 o 4 de 11",
  "Enter Actual Weight": "Ingrese el peso real",
  "Enter Cash Details": "Ingrese Detalles de Efectivo",
  "Enter Dry Weights": "Ingresar Pesos Secos",
  "Enter Plant Weights": "Ingresar Pesos de Plantas",
  "Enter Wet Weights": "Ingresar Pesos Húmedos",
  "Entity": "Entidad",
  "Entity Count": "Recuento de Entidades",
  "Environment": "Ambiente",
  "EPay Sales": "Ventas Electrónicas",
  "Equiv. External": "Equiv. Externo",
  "Error adding plants.": "Error al agregar plantas.",
  "Error applying nutrients": "Error al aplicar nutrientes",
  "Error Authenticating with Quickbooks": "Error de autenticación con Quickbooks",
  "Error closing reconciliation": "Error al cerrar la reconciliación",
  "Error combining packages": "Error al combinar paquetes",
  "Error communicating with Quickbooks": "Error al comunicarse con Quickbooks",
  "Error connecting Weedmaps location!": "¡Error al conectar la ubicación de Weedmaps!",
  "Error converting clones": "Error al convertir clones",
  "Error converting package": "Error al convertir el paquete",
  "Error creating company": "Error al crear empresa",
  "Error creating manifest": "Error al crear manifiesto",
  "Error creating order": "Error al crear orden",
  "Error creating package": "Error al crear el paquete",
  "Error deleting batch": "Error al eliminar lote",
  "Error deleting item from order": "Error al eliminar el artículo del pedido",
  "Error deleting package": "Error al eliminar el paquete",
  "Error deleting plant reconciliation": "Error al eliminar la reconciliación de la planta",
  "Error deleting user group": "Error al eliminar el grupo de usuarios",
  "Error destroying waste": "Error al destruir desperdicio",
  "Error finishing step.": "Error al finalizar el paso.",
  "Error fulfilling order": "Error al cumplir el pedido",
  "Error generating Package IDs": "Error al generar ID de paquete",
  "Error getting all effective permissions": "Error al obtener todos los permisos efectivos",
  "Error getting customer data": "Error al obtener datos del cliente",
  "Error getting customer type data": "Error al obtener datos de tipo de cliente",
  "Error getting manifest": "Error al obtener manifiesto",
  "Error getting oauth server!": "¡Error al obtener el servidor oauth!",
  "Error getting order info": "Error al obtener la información del pedido",
  "Error getting plant attributes": "Error al obtener los atributos de la planta",
  "Error getting plant group identifier": "Error al obtener el identificador de grupo de planta",
  "Error Getting Receipt Export File": "Error al Obtener el Archivo de Exportación de Recibo",
  "Error Getting Sales Receipt Log": "Error al Obtener el Registro de Recibo de Ventas",
  "Error Getting Settings": "Error al Obtener la Configuración",
  "Error loading available nutrients": "Error al cargar los nutrientes disponibles",
  "Error loading discrepancies": "Error al cargar discrepancias",
  "Error Loading Hypur Settings": "Error al cargar la configuración de Hypur",
  "Error loading METRC Logs": "Error al cargar registros METRC",
  "Error loading METRC Settings": "Error al cargar la configuración METRC",
  "Error loading METRC Statistics": "Error al cargar las estadísticas METRC",
  "Error loading nutrient log": "Error al cargar el registro de nutrientes",
  "Error loading plant reconciliations": "Error al cargar conciliaciones de planta",
  "Error loading preview": "Error al cargar la vista previa",
  "Error loading pricing tier": "Error al cargar el nivel de precios",
  "Error loading Receipt Reconciliation": "Error al cargar la reconciliación de recibo",
  "Error loading reconciliation details": "Error al cargar detalles de conciliación",
  "Error loading Spring Big Settings": "Error al Cargar la Configuración de Spring Big",
  "Error loading waste": "Error al cargar residuos",
  "Error loading Weedmaps Location Data!": "¡Error al cargar los datos de ubicación de Weedmaps!",
  "Error loading Weedmaps settings": "Error al cargar la configuración de Weedmaps",
  "Error merging customers": "Error al fusionar clientes",
  "Error moving packages": "Error al mover paquetes",
  "Error moving plants": "Error al mover plantas",
  "Error parsing lab result url": "Error al analizar la URL del resultado de laboratorio",
  "Error printing label": "Error al imprimir etiqueta",
  "Error printing labels": "Error al imprimir etiquetas",
  "Error printing plant labels": "Error al imprimir etiquetas de planta",
  "Error pulling": "Error al tirar",
  "Error pushing": "Error al presionar",
  "Error reconciling item": "Error al reconciliar elemento",
  "Error remediating package": "Error al corregir el paquete",
  "Error removing field": "Error al eliminar el campo",
  "Error removing items": "Error al eliminar elementos",
  "Error removing product tax category": "Error al eliminar la categoría de impuestos del producto",
  "Error removing tax rate": "Error al eliminar la tasa impositiva",
  "Error reporting": "Error al reportar",
  "Error resetting! Please contact LL support": "Error al reiniciar! Póngase en contacto con el soporte técnico de LL",
  "Error restoring": "Error al restaurar",
  "Error retreiving permission groups": "Error al recuperar grupos de permisos",
  "Error retrieving lab results": "Error al recuperar resultados de laboratorio",
  "Error Saving Hypur Settings": "Error al guardar la configuración de Hypur",
  "Error saving loyalty details": "Error al guardar los detalles de lealtad",
  "Error saving notes": "Error al guardar notas",
  "Error saving plant weights": "Error al guardar los pesos de las plantas",
  "Error saving plany attributes": "Error al guardar atributos de plany",
  "Error Saving QBO Settings": "Error al Guardar la Configuración de QBO",
  "Error Saving QuickBooks Settings": "Error al Guardar la Configuración de QuickBooks",
  "Error saving Spring Big Settings": "Error al Guardar la Configuración de Spring Big",
  "Error saving Weedmaps settings": "Error al guardar la configuración de Weedmaps",
  "Error Sending Invoice to Quickbooks": "Error al Enviar la Factura a Quickbooks",
  "Error sending message(s)": "Error al enviar mensaje(s)",
  "Error Sending Receipts to Quickbooks": "Error al Enviar Recibos a Quickbooks",
  "Error setting address": "Error al configurar la dirección",
  "Error starting step.": "Error al iniciar el paso.",
  "Error sublotting package": "Paquete de sublote de errores",
  "Error synchronizing members": "Error al sincronizar miembros",
  "Error syncing inventory ids": "Error al sincronizar identificadores de inventario",
  "Error syncing with Quickbooks": "Error al sincronizar con Quickbooks",
  "Error syncing!": "Error al sincronizar",
  "Error undoing finish step.": "Error al deshacer el paso final.",
  "Error updating coupon": "Error al actualizar el cupón",
  "Error updating customer": "Error al actualizar cliente",
  "Error updating discount": "Error al actualizar descuento",
  "Error Updating Harvest": "Error al Actualizar Harvest",
  "Error updating label field.": "Error al actualizar el campo de etiqueta.",
  "Error updating manifest item": "Error al actualizar el elemento de manifiesto",
  "Error updating package": "Error al actualizar el paquete",
  "Error updating product": "Error al actualizar producto",
  "Error updating user password": "Error al actualizar la contraseña del usuario",
  "Everything attached to this batch will be moved to another batch with the same strain.": "Todo lo que se adjunte a este lote se moverá a otro lote con la misma cepa.",
  "Example": "Ejemplo",
  "Example: .1 Accrual Rate on a $100 purchase will yield 10 points.": "Ejemplo: .1 La tasa de acumulación en una compra de $ 100 generará 10 puntos.",
  "Existing Amount": "Cantidad Existente",
  "Existing permissions have been migrated to this new systems.  If you're encountering any issues with your access or need help getting set up please let your administrators know and/or send a message to us at support@leaflogix.com.": "Los permisos existentes se han migrado a estos nuevos sistemas. Si tiene algún problema con su acceso o necesita ayuda para configurarlo, informe a sus administradores y / o envíenos un mensaje a support@leaflogix.com.",
  "Existing seeds": "Semillas existentes",
  "ExpDate": "Fecha de Caducidad",
  "Expected": "Esperado",
  "Expected Arrival Date": "Fecha de Llegada Prevista",
  "Expected Quantity": "Cantidad Esperada",
  "Expense Account": "Cuenta de Gastos",
  "Expiration Date": "Fecha de Caducidad",
  "Expire": "Expirar",
  "Export": "Exportar",
  "Export Inventory Reconciliation": "Exportar Reconciliación de Inventario",
  "Export Table as CSV": "Exportar Tabla como CSV",
  "External": "Externo",
  "External Category": "Categoría Externa",
  "External ID": "ID Externo",
  "Extraction Date": "Fecha de Extracción",
  "Fail": "Fallar",
  "Failed to add items": "Error al agregar elementos",
  "Failed to archive customer": "Error al archivar cliente",
  "Failed to complete order": "Error al completar el pedido",
  "Failed to create batch package": "Error al crear paquete por lotes",
  "Failed to create location": "Error al crear la ubicación",
  "Failed to create purchase order": "Error al crear la orden de compra",
  "Failed to delete discount": "Error al eliminar el descuento",
  "Failed to delete room": "Error al eliminar la sala",
  "Failed to destroy seedling count": "Error al destruir el recuento de plántulas",
  "Failed to get plant fields": "Error al obtener campos de plantas",
  "Failed to get plant info": "Error al obtener información de la planta",
  "Failed to get user settings": "Error al obtener la configuración del usuario",
  "Failed to harvest plants": "Error al cosechar plantas",
  "Failed to Load Customers": "Error al Cargar Clientes",
  "Failed to load looker": "Error al cargar el buscador",
  "Failed to load master categories": "Error al cargar categorías maestras",
  "Failed to load orders": "Error al cargar pedidos",
  "Failed to load purchase limits": "Error al cargar los límites de compra",
  "Failed to load Purchase Order": "Error al cargar la Orden de Compra",
  "Failed to load terminals for room": "Error al cargar terminales para la habitación",
  "Failed to Load transactions": "Error al cargar transacciones",
  "Failed to load users": "Error al cargar usuarios",
  "Failed to print Invoice": "No se pudo imprimir la Factura",
  "Failed to print label": "No se pudo imprimir la etiqueta",
  "Failed to print Manifest": "Error al imprimir el Manifiesto",
  "Failed to remove discount": "Error al eliminar el descuento",
  "Failed to Re-Open order": "Error al Volver a Abrir la Orden",
  "Failed to save info": "Error al guardar la información",
  "Failed to save plant info": "Error al guardar la información de la planta",
  "Failed to save purchase limit types": "Error al guardar tipos de límite de compra",
  "Failed to send email": "Error al enviar correo electrónico",
  "Failed to update cars": "Error al actualizar autos",
  "Failed to update categories": "Error al actualizar categorías",
  "Failed to update drivers": "Error al actualizar los controladores",
  "Failed to update inventory status": "Error al actualizar el estado del inventario",
  "Failed to update labels": "Error al actualizar las etiquetas",
  "Failed to update reconciliation": "Error al actualizar la reconciliación",
  "Failed to update rooms": "Error al actualizar las salas",
  "Failed to update size item": "Error al actualizar el elemento de tamaño",
  "Failed to update strains": "Error al actualizar las cepas",
  "Failed to update vendors": "Error al actualizar proveedores",
  "Failure Message": "Mensaje de Falla",
  "Fax Number": "Número de fax",
  "Feature Flags": "Banderas de Funciones",
  "Features saved": "Funciones guardadas",
  "Feburary Release": "Lanzamiento de febrero",
  "Female": "Hembra",
  "Fetch": "Ir a Buscar",
  "Field": "Campo",
  "Field removed": "Campo eliminado",
  "Fields": "Campos",
  "File exceeds maximum size of 2MB, please select a smaller file.": "El archivo supera el tamaño máximo de 2 MB, seleccione un archivo más pequeño.",
  "File Expiration Date (optional)": "Fecha de Vencimiento del Archivo (opcional)",
  "File Type": "Tipo de Archivo",
  "Filter": "Filtrar",
  "Filter Mother Plant": "Filtrar Planta Madre",
  "Filter Plant Stage": "Etapa de Planta de Filtro",
  "Filter Plant Type": "Tipo de Planta de Filtro",
  "Filter Plants": "Plantas de Filtro",
  "Filter Reports": "Filtrar Informes",
  "Filter Strain": "Filtrar Cepa",
  "Filter Table": "Tabla de Filtro",
  "Finish": "Terminar",
  "Finish Harvest": "Terminar Cosecha",
  "Five digits number": "Número de cinco dígitos",
  "Flower": "Flor",
  "Flower Eq.": "Eq. de flor",
  "Flower Equiv": "Equiv Flor",
  "Flower Equivalent": "Equivalente de Flor",
  "Flowering Ended": "Floración Terminada",
  "Flowering Started": "Floración Iniciada",
  "Font Size": "Tamaño de Fuente",
  "Footer Settings": "Configuraciones de Pie de Página",
  "Footer Text": "Texto de Pie de Página",
  "For": "Por",
  "Form Validation": "Validación de Formulario",
  "Forms": "Formularios",
  "Format": "Formato",
  "Forms saved": "Formularios guardados",
  "Four digits number": "Número de cuatro dígitos",
  "Four digits year": "Año de cuatro dígitos",
  "FreeOrders": "PedidosGratis",
  "Fresh Plant": "Planta Fresca",
  "Friday": "Viernes",
  "from": "de",
  "From": "De",
  "FROM": "DESDE",
  "From Loc": "Desde la Ubic",
  "From Location": "Desde la Ubicación",
  "From Lsp": "De Lsp",
  "From Package(s)": "De Paquete (s)",
  "From Room": "De la Habitación",
  "From:": "De:",
  "Fulfill": "Satisfacer",
  "Fulfill Order": "Cumplir Orden",
  "Fulfilled": "Cumplido",
  "Full Container Weight": "Peso de Contenedor Completo",
  "Full Name": "Nombre Completo",
  "FULL WEIGHT": "PESO COMPLETO",
  "Fullfillment Notes": "Notas de Cumplimiento Completo",
  "Gear": "Engranaje",
  "Gen PIN": "PIN Gen",
  "Gender": "Género",
  "General Identifiers": "Identificadores Generales",
  "General Settings": "Configuración General",
  "Generate": "Generar",
  "Generate Unique Id Per Item": "Generar ID único por artículo",
  "Generates a unique id per qty item in inventory": "Genera una identificación única por cantidad de artículos en inventario",
  "Generating PDF…": "Generando PDF ...",
  "Get authorized!": "¡Obtenga autorización!",
  "Gives stores the ability to specify what form fields are reqired / not required": "Da a las tiendas la capacidad de especificar qué campos de formulario se requieren / no se requieren",
  "Go": "Vamos",
  "Go for it!": "¡Ve a por ello!",
  "Gram": "Gramo",
  "Grams / Concentration": "Gramos / Concentración",
  "Grid Layout": "Diseño de Cuadrícula",
  "Gross Margin": "Margen Bruto",
  "Gross Profit": "Beneficio Bruto",
  "Gross Sales": "Ventas Brutas",
  "Gross Weight": "Peso Bruto",
  "Group": "Grupo",
  "Group Description": "Descripción del Grupo",
  "Group Name": "Nombre del Grupo",
  "GroupPlantedDate": "FechaDePlantaciónGrupal",
  "Growth Phase": "Fase de Crecimiento",
  "Growth Phases": "Fases de Crecimiento",
  "Guest Name 2 lines": "Nombre del huésped 2 líneas",
  "Guestlist Status": "Estado de la Lista de Invitados",
  "Guestlist Status Color Coding": "Código de Color del Estado de la Lista de Invitados",
  "Guestlist Status Layout": "Diseño del Estado de la Lista de Invitados",
  "Harvest": "Cosecha",
  "Harvest Date": "Fecha de Cosecha",
  "Harvest Details": "Detalles de Cosecha",
  "Harvest Package": "Paquete de Cosecha",
  "Harvest Plants": "Plantas de Cosecha",
  "Harvest Units": "Unidades de Cosecha",
  "Harvest Updated Successfully": "Cosecha Actualizada con éxito",
  "Harvested": "Cosechado",
  "Harvested Weights": "Pesos Cosechados",
  "Harvesting": "Cosecha",
  "Has ECommerce": "Tiene comercio electrónico",
  "Has Enterprise Reporting": "Tiene informes empresariales",
  "Has Looker Explorer": "Tiene Looker Explorer",
  "Has Purchase Orders": "Tiene órdenes de compra",
  "Having issues? Make sure LL is configured as your POS provider on Weedmaps.com first. After that, use the 'Start Over' button to connect again.": "¿Teniendo problemas? Asegúrese de que LL esté configurado como su proveedor POS en Weedmaps.com primero. Después de eso, use el botón 'Comenzar de Nuevo' para conectarse nuevamente.",
  "Header Settings": "Configuración de Encabezado",
  "Heavy Metal": "Metal Pesado",
  "Height": "Altura",
  "Help": "Ayuda",
  "Hidden?": "¿Oculto?",
  "Hide": "Esconder",
  "Hide From POS": "Ocultar Desde PDV",
  "Hide From POS?": "Ocultar de PDV?",
  "Hide Inactive POS Customers": "Ocultar Clientes de PDV Inactivos",
  "Hide Prices To anonymous users": "Ocultar precios a usuarios anónimos",
  "Highest Priced Items": "Artículos de Mayor Precio",
  "Hire Date": "Fecha de Contratación",
  "Hired": "Contratado",
  "History": "Historia",
  "How to select multiple items in a table": "Cómo seleccionar varios elementos en una tabla",
  "hree digits number": "número de tres dígitos",
  "https://md-eastlive-..... ": "https: // md-eastlive -.....",
  "https://md-live-.....": "https: // md-live -.....",
  "https://md-westlive-.....": "https: // md-westlive -.....",
  "Humulene": "Humuleno",
  "Hybrid": "Híbrido",
  "Hypur Integration": "Integración Hypur",
  "Hypur Settings Saved": "Configuración de Hypur guardada",
  "ID Expiration Date": "Fecha de Vencimiento de ID",
  "ID Number": "Número de ID",
  "Identification Image": "Imagen de Identificación",
  "Identifiers": "Identificadores",
  "If enabled, allows loyalty points earned to be increased based on additional criteria.": "Si está habilitado, permite aumentar los puntos de fidelidad ganados en función de criterios adicionales.",
  "If enabled, any immature plants from this point on will be created as individual plants": "Si está habilitado, las plantas inmaduras a partir de este momento se crearán como plantas individuales.",
  "If enabled, inventory reconciliation functionality updates the inventory in one request instead of one request per product.": "Si está habilitada, la funcionalidad de conciliación de inventario actualiza el inventario en una solicitud en lugar de una solicitud por producto.",
  "If enabled, Product Master will allow different Flower Equivilent to be set for both Med and Rec sales.": "Si está habilitado, Product Master permitirá establecer diferentes Flower Equivilent para las ventas de Med y Rec.",
  "If multiple customer types are enabled on your system (Medical vs Recreational, for example), you will also see a breakdown of sales by customer type.": "Si se habilitan varios tipos de clientes en su sistema (Medical vs Recreational, por ejemplo), también verá un desglose de las ventas por tipo de cliente.",
  "If page does not redirect after logging into weedmaps, click Continue": "Si la página no se redirige después de iniciar sesión en weedmaps, haga clic en Continuar",
  "Image URL": "URL de la Imagen",
  "Images": "Imágenes",
  "In an  effort to lead the cannabis industry with the most robust and reliable software in the market, we have completed the full migration of our seed to sale software to our latest supported technologies. ": "En un esfuerzo por liderar la industria del cannabis con el software más robusto y confiable del mercado, hemos completado la migración completa de nuestro software de semillas a la venta a nuestras últimas tecnologías compatibles.",
  "In Use": "En Uso",
  "In-Active": "Inactivo",
  "Include Detail": "Incluir Detalles",
  "Include Labs - Include THC & CBD from Product Master - only works with milligrams and %": "Incluir laboratorios - Incluir THC y CBD de Product Master - solo funciona con miligramos y%",
  "Include Non-cannabis Items": "Incluir Artículos Sin Cannabis",
  "Income Account": "Cuenta de Ingresos",
  "Income Summary": "Resumen de Ingresos",
  "Incremental": "Incremental",
  "Infused Non-Edibles": "No Comestibles Infundidos",
  "Ingredient Details": "Detalles de Ingredientes",
  "Ingredients": "Ingredientes",
  "Initial Plant Count": "Recuento Inicial de Plantas",
  "Input": "Entrada",
  "Inputs": "Entradas",
  "Instructions": "Instrucciones",
  "Instructions:": "Instrucciones:",
  "Integration Data": "Datos de Integración",
  "Integration Reconciliation": "Integración Reconciliación",
  "Integrations": "Integraciones",
  "Into Room": "En la Habitación",
  "Inv Type": "Tipo de Inv",
  "Invalid Plant Scan. Plant Id is not in system.": "Escaneo de Planta Inválido. El identificador de planta no está en el sistema.",
  "Invalid Scan. Table is not in system.": "Escaneo Inválido. La tabla no está en el sistema.",
  "Inventory": "Inventario",
  "Inventory %": "Inventario %",
  "Inventory Action Wheel Change": "Cambio de rueda de acción de inventario",
  "Inventory Adjustment Reasons": "Razones de Ajuste de Inventario",
  "Inventory Adjustments": "Ajuste de Inventario",
  "Inventory Assets": "Activos de Inventario",
  "Inventory Date": "Fecha de Inventario",
  "Inventory Destroyed": "Inventario Destruido",
  "Inventory Id": "ID de Inventario",
  "Inventory Ids Synced": "ID de inventario sincronizados",
  "Inventory items removed": "Artículos de inventario eliminados",
  "Inventory Lookup": "Búsqueda de Inventario",
  "Inventory Recon": "Recon de Inventario",
  "Inventory Reconciliation": "Reconciliación de Inventario",
  "Inventory Reconciliation Update In Bulk": "Actualización de reconciliación de inventario en masa",
  "Inventory Status": "Estado de Inventario",
  "INVENTORY STATUS": "ESTADO DE INVENTARIO",
  "Inventory status updated successfully": "Estado del inventario actualizado correctamente",
  "Inventory System": "Sistema de inventario",
  "Invoice Accounts": "Cuentas de Factura",
  "Invoice Comments": "Comentarios de Factura",
  "Invoice printed": "Factura impresa",
  "Invoice Successfully Uploaded": "Factura Cargada Exitosamente",
  "Is Available Online": "Está Disponible en Línea",
  "Is Blind?": "¿Es ciego?",
  "Is Cannabis": "Es el Cannabis",
  "Is Cannabis?": "Es el Cannabis?",
  "Is Coupon": "Es Cupón",
  "Is E-Commerce Room?": "¿Es la Sala de Comercio Electrónico?",
  "Is Enabled": "Está Habilitado",
  "Is Enabled?": "¿Está Habilitado?",
  "Is Finished Good": "Está Terminado Bien",
  "Is Finished?": "¿Está terminado?",
  "Is Inventory Room?": "¿Es la Sala de Inventario?",
  "Is Mother": "Es Madre",
  "Is Nutrient": "Es Nutriente",
  "Is Nutrient?": "Es Nutriente?",
  "Is On Sale": "Esta en Venta",
  "Is POS Available": "POS Disponible",
  "Is POS Room?": "¿Es la Sala de PDV?",
  "Is PreOrder Room?": "¿Es la Sala de Pre-Pedido?",
  "Is Quarantine Room?": "¿Es la Sala de Cuarentena?",
  "Is Retired": "Es Retirado",
  "Is Taxable?": "Es Imponible?",
  "Is Waiting Room?": "¿Es la Sala de Espera?",
  "Issued By": "Emitido Por",
  "Item Count": "Recuento de Elementos",
  "Item does not have a Batch Id": "El artículo no tiene un ID de lote",
  "Item not found": "Objeto no encontrado",
  "Item removed": "Artículo eliminado",
  "Item removed from manifest.": "Elemento eliminado del manifiesto.",
  "Item updated": "Artículo actualizado",
  "Items": "Artículos",
  "Items Added": "Artículos Agregados",
  "Items assigned to batch": "Artículos asignados al lote",
  "Items Per Package": "Artículos por Paquete",
  "Items Selected": "Artículos Seleccionados",
  "Items Sold": "Cosas Vendidas",
  "Joint Material Value": "Valor Conjunto del Material",
  "Journal": "Diario",
  "Journal Entries": "Entradas de Diario",
  "Journal entry updated": "Entrada de diario actualizada",
  "June Release #1": "Versión de junio # 1",
  "Keep header/navigation pinned top top of page while scrolling. Great for iframes.": "Mantenga el encabezado / navegación anclado en la parte superior superior de la página mientras se desplaza. Genial para iframes.",
  "Key": "Llave",
  "Keys": "Llaves",
  "Keyword": "Palabra clave",
  "Kief Value": "Valor de Kief",
  "Lab": "Laboratorio",
  "Lab Analysis": "Análisis de Laboratorio",
  "Lab Analysis Updated": "Análisis de Laboratorio Actualizado",
  "Lab Result Details": "Detalles de Resultados de Laboratorio",
  "Lab Result URL (Scan a QR Code)": "URL de Resultados de Laboratorio (Escanear un código QR)",
  "Lab results fetched": "Resultados de laboratorio obtenidos",
  "Lab results parsed": "Resultados de laboratorio analizados",
  "Lab Sample": "Muestra de Laboratorio",
  "Lab Samples": "Muestras de Laboratorio",
  "Label": "Marbete",
  "Label copied successfully!": "Etiqueta copiada con éxito!",
  "Label Copier": "Copiadora de Etiquetas",
  "Label copy failed.": "Copia de etiqueta fallida.",
  "Label printed": "Etiqueta impresa",
  "Label Printer": "Impresora de Etiquetas",
  "Label Printer?": "¿Impresora de Etiquetas?",
  "Label Settings": "Configuraciones de Etiqueta",
  "Label settings updated": "Configuración de etiqueta actualizada",
  "Label To Copy": "Etiqueta Para Copiar",
  "Labels": "Etiquetas",
  "Labels printed": "Etiquetas impresas",
  "Labels updated successfully": "Etiquetas actualizadas con éxito",
  "Landscape270": "Paisaje270",
  "Landscape90": "Paisaje90",
  "Last 30 days": "Últimos 30 días",
  "Last 4": "Últimos 4",
  "Last 7 days": "Los últimos 7 días",
  "Last Sync": "Última Sincronización",
  "Layout": "Diseño",
  "Leaf Logix E-Commerce": "Leaf Logix E-Commerce",
  "Leaf Logix is Beta Testing the New Cultivation system with expected full Release In January 2018.": "Leaf Logix está probando beta el nuevo sistema de cultivo con el lanzamiento completo esperado en enero de 2018.",
  "Leaf Logix is Beta Testing the New Inventory system with expected full release in January 2018.": "Leaf Logix está probando beta el nuevo sistema de inventario con lanzamiento completo esperado en enero de 2018.",
  "Leaf Logix is integrated with numerous third party applications. We have current integrations with CANPAY, HYPUR, LINX, LEAFLY, STICKY GUIDE, WEEDMAPS, SPRINGBIG, SPROUT & RELEAF. If you have questions on setting up an integration, please send a request to support@leaflogix.com": "Leaf Logix está integrado con numerosas aplicaciones de terceros. Tenemos integraciones actuales con CANPAY, HYPUR, LINX, LEAFLY, STICKY GUIDE, WEEDMAPS, SPRINGBIG, SPROUT & RELEAF. Si tiene preguntas sobre cómo configurar una integración, envíe una solicitud a support@leaflogix.com",
  "Leaf Logix is nearly ready with our E-Commerce site! Allow your customers to login, and place orders directly against your available product. Fulfill the placed orders through the POS 3.0 solution.": "¡Leaf Logix está casi listo con nuestro sitio de comercio electrónico! Permita que sus clientes inicien sesión y realice pedidos directamente contra su producto disponible. Cumplir los pedidos realizados a través de la solución POS 3.0.",
  "Leaf Logix is proud to announce our new Manifest system.  Linked to POS 3.0, our customers can now create a manifest for a wholesale order or customer delivery.": "Leaf Logix se enorgullece en anunciar nuestro nuevo sistema Manifiesto. Vinculados a POS 3.0, nuestros clientes ahora pueden crear un manifiesto para un pedido mayorista o entrega al cliente.",
  "Leaf Logix is proud to announce the release of our Wholesale system. Using Leaf Logix E-Commerce or one of our 3rd party partners offering an E-Commerce site which is integrated to Leaf Logix. Allow your customers to place orders against your inventory.": "Leaf Logix se enorgullece en anunciar el lanzamiento de nuestro sistema mayorista. Usando Leaf Logix E-Commerce o uno de nuestros socios externos que ofrece un sitio de comercio electrónico que está integrado a Leaf Logix. Permita que sus clientes realicen pedidos contra su inventario.",
  "Leaf Logix POS 3.0 is officially released and operational.  We will post any updates of POS to POS 3.0 (Beta) so that you can get time to review the changes and train your staff prior to final release.  We will then update to permanent live release. Please note that our Beta version is looking at updating live data.": "Leaf Logix POS 3.0 es oficialmente lanzado y operativo. Publicaremos cualquier actualización de POS a POS 3.0 (Beta) para que pueda tener tiempo de revisar los cambios y capacitar a su personal antes del lanzamiento final. Luego actualizaremos a la versión en vivo permanente. Tenga en cuenta que nuestra versión Beta está buscando actualizar los datos en vivo.",
  "Leaf Logix Reporting": "Informes de Leaf Logix",
  "Leaf Logix Wholesale Solution": "Solución al por mayor de Leaf Logix",
  "Leaf Waste Weight": "Peso Residual de la Hoja",
  "Leafly Category": "Categoría Leafly",
  "Leaves": "Hojas",
  "Lic Id": "Id de Lic",
  "License #": "Licencia #",
  "License No": "Número de Licencia",
  "License Number": "Número de Licencia",
  "License Number (if different)": "Número de licencia (si es diferente)",
  "Limit Uses": "Limitar Usos",
  "Limits can be configured in POS > Maintenance > Purchase Limits.  ": "Los límites se pueden configurar en POS> Mantenimiento> Límites de compra.",
  "Limits saved": "Límites guardados",
  "Limonene": "Limoneno",
  "Linalool": "Linalol",
  "Line Item Discrepancy": "Discrepancia de Elemento de Línea",
  "Line Item Settings": "Configuración de Elemento de Línea",
  "Line Item Total": "Total de Artículos de Línea",
  "Line Items": "Artículos de Línea",
  "Linked Registers": "Registros Vinculados",
  "List Inventory IDs": "Lista de ID de Inventario",
  "Listed Product": "Producto Listado",
  "Lite Signup": "Registro Lite",
  "LL Data": "Datos LL",
  "LL Item Count": "Recuento de Artículos LL",
  "LL Required": "LL Requerido",
  "LL Sale Date": "Fecha de Venta de LL",
  "LL Transaction ID": "ID de Transacción de LL",
  "Loading": "Cargando",
  "loading images": "cargando imágenes",
  "Loading Journal": "Cargando Diario",
  "Loading Journal entries": "Cargando entradas de Diario",
  "Loading label": "Etiqueta de carga",
  "Location": "Ubicación",
  "Location Created": "Lugar Creado",
  "Location Info": "Información de Ubicación",
  "Location info saved": "Información de ubicación guardada",
  "Location Name": "Nombre del Lugar",
  "Location Options": "Opciones de Ubicación",
  "Location Specific Product Details": "Detalles de Ubicación Específicos del Producto",
  "Location Styling": "Estilo de Ubicación",
  "Location Type": "Tipo de Ubicacion",
  "Login Id": "Ingresar Id",
  "Login name already exists in system": "El nombre de inicio de sesión ya existe en el sistema",
  "Logo Image": "Logotipo",
  "LOGOUT": "CERRAR SESIÓN",
  "Logs": "Registros",
  "Looker": "Looker",
  "Lookup": "Buscar",
  "Lot": "Lote",
  "Lot #": "Lote #",
  "Lot Name / Batch ID": "Nombre del Lote / ID de Lote",
  "Lot No": "Num de Lote",
  "Low Inventory Threshold": "Umbral Bajo de Inventario",
  "Lowest Priced Items": "Artículos con el Precio Más Bajo",
  "Loyalty": "Lealtad",
  "Loyalty Configuration": "Configuración de fidelización",
  "Loyalty details saved": "Detalles de fidelidad guardados",
  "Loyalty Discount": "Descuento de Fidelidad",
  "Loyalty Earned": "Lealtad Ganada",
  "Loyalty Point Dollar Value": "Punto de Fidelidad Valor en Dólares",
  "Loyalty Points": "Puntos de Lealtad",
  "Loyalty Used": "Lealtad Utilizada",
  "LoyaltyReturned": "Lealtad Devuelta",
  "Lsp Address": "Dirección Lsp",
  "Lsp info saved": "Información de Lsp guardada",
  "Main Background Color": "Color de fondo principal",
  "Main Background Image": "Imagen de fondo principal",
  "Maintain Items": "Mantener Artículos",
  "Maintain Rooms": "Mantener Habitaciones",
  "Maintain Strains": "Mantener Cepas",
  "Maintenance": "Mantenimiento",
  "Make": "Hacer",
  "Make sure you are logged into YOUR DISPENSARIES weedmaps account before continuing!": "¡Asegúrese de haber iniciado sesión en la cuenta de weedmaps de SUS DISPENSARIOS antes de continuar!",
  "Male": "Masculino",
  "Manage Customer Types": "Gestionar Tipos de Clientes",
  "Manage Pin For Deposit": "Administrar PIN para depósito",
  "Management": "Administración",
  "Manager Password": "Contraseña del Administrador",
  "Manager Search Flag": "Indicador de búsqueda de gerente",
  "Manifest": "Manifiesto",
  "Manifest could not be removed": "No se pudo eliminar el manifiesto",
  "Manifest Created": "Manifiesto Creado",
  "Manifest failed to generate": "El manifiesto no pudo generar",
  "Manifest failed to save": "El manifiesto no pudo guardar",
  "Manifest generated": "Manifiesto generado",
  "Manifest printed": "Manifiesto impreso",
  "Manifest Removed": "Manifiesto eliminado",
  "Manifest System": "Sistema manifiesto",
  "Manifest Types": "Tipos de Manifiesto",
  "Manifest updated": "Manifiesto actualizado",
  "Manifest updated.": "Manifiesto actualizado.",
  "Manifests": "Manifiestos",
  "Manual": "Manual",
  "Manufacturer": "Fabricante",
  "Manufacturing Module": "Módulo de fabricación",
  "March Release #1": "Lanzamiento de marzo # 1",
  "Massachusetts Adult Use": "Uso de adultos en Massachusetts",
  "Master Category": "Categoría Maestra",
  "Max Purchasable Per Transaction": "Máximo de Compra Por Transacción",
  "Max Purchasble Per Transaction": "Máximo de compras por transacción",
  "Max use": "Uso Máximo",
  "MD Label No": "Etiqueta MD No",
  "Med Flower Equiv": "Equiv de Flor Méd",
  "Med Per Gram": "Med Por Gramo",
  "Med Total": "Med Total",
  "Medical": "Médico",
  "Medical - Tax Exempt": "Médico - Exento de Impuestos",
  "Medical Only": "Solo Médico",
  "Medical Patients Only": "Solo Pacientes Médicos",
  "Medical Price": "Precio Médico",
  "Menu Preview": "Vista Previa del Menú",
  "Merchant Id": "Id del Comerciante",
  "Merge Customers": "Fusionar Clientes",
  "Message": "Mensaje",
  "Message(s) sent": "Mensaje (s) enviado(s)",
  "Method": "Método",
  "Method:": "Método:",
  "Metrc Customer ID": "ID de Cliente de Metrc",
  "METRC Integration": "Integración METRC",
  "Metrc Item Count": "Recuento de Elementos METRC",
  "Metrc Label": "Etiqueta Metrc",
  "Metrc Qty": "Cantidad Metrc",
  "Metrc Receipt ID": "ID de Recibo de Metrc",
  "Metrc Sale Date": "Fecha de Venta de Metrc",
  "METRC Tag": "Etiqueta METRC",
  "Metrc Tags": "Etiquetas Metrc",
  "METRC tags are required to be 24 characters in length": "Las etiquetas METRC deben tener 24 caracteres de longitud",
  "METRC Tools": "Herramientas METRC",
  "Metrc Unit": "Unidad Metrc",
  "Micro Business": "Microempresa",
  "Microbiology": "Microbiología",
  "Minimum Purchase Required to Earn Loyalty": "Compra Mínima Requerida Para Ganar Lealtad",
  "Minor": "Menor",
  "Mix and Match - All Products": "Mix and Match - Todos los Productos",
  "Mix and Match - Single Product": "Mix and Match - Producto único",
  "MJ Expiration Date": "Fecha de Vencimiento de MJ",
  "MN Medicine Id": "ID de Medicina de MN",
  "Mobile Phone": "Teléfono Móvil",
  "Mobile Phone Number": "Número de teléfono móvil",
  "Model": "Modelo",
  "Moisture Gain": "Ganancia de Humedad",
  "Moisture Loss": "Pérdida de Humedad",
  "Monday": "Lunes",
  "Money Adjustment": "Ajuste de Dinero",
  "Monthly Snapshot": "Instantánea Mensual",
  "Most Sold Products": "Productos Más Vendidos",
  "Mother": "Madre",
  "Mother Plant": "Planta Madre",
  "Mother?": "¿Madre?",
  "Move": "Mover",
  "Move & Delete": "Mover y Borrar",
  "Move Date": "Fecha de Mudanza",
  "Move Plant Location": "Mover Ubicación de la Planta",
  "Move Plants": "Mover Plantas",
  "Move Table into Rooms": "Mover Tabla a las Habitaciones",
  "Moved": "Movido",
  "Moved On": "Movido En",
  "Moved to": "Movido a",
  "Must supply valid manager's password to return an item.": "Debe proporcionar una contraseña de administrador válida para devolver un artículo.",
  "Mycotoxin": "Micotoxinas",
  "Name": "Nombre",
  "Name is required!": "¡Se requiere el nombre!",
  "Nav Background Image": "Imagen de fondo de navegación",
  "Nav Hover Background": "Fondo de desplazamiento del navegador",
  "Nav Hover Text": "Nav Hover Text",
  "Navigation Background Color": "Color de fondo de navegación",
  "Navigation Menu Text": "Texto del menú de navegación",
  "Navigation Selected Background": "Fondo seleccionado de navegación",
  "Navigation Selected Boxshadow": "Navegación seleccionada Boxshadow",
  "Navigation Selected Text": "Texto seleccionado de navegación",
  "Needed": "Necesario",
  "Net Bud Weight": "Peso Neto del Brote",
  "NET MMJ": "MMJ NETO",
  "Net Product Weight": "Peso Neto del Producto",
  "Net Sales": "Las Ventas Netas",
  "Net Unit Weight": "Peso Neto de la Unidad",
  "Net Weight": "Peso Neto",
  "New": "Nuevo",
  "New Amount": "Nueva Cantidad",
  "New Balance": "Nuevo Equilibrio",
  "New Category": "Nueva Categoría",
  "New Closing Report": "Nuevo informe de cierre",
  "New Coupon": "Nuevo Cupón",
  "New Customer": "Nuevo Cliente",
  "New Customers": "Nuevos Clientes",
  "New Dosage": "Nueva Dosis",
  "New Size": "Nuevo Tamaño",
  "New Driver": "Nuevo Conductor",
  "New Journal Entry": "Nueva Entrada de Diario",
  "New Mother": "Nueva Madre",
  "New PDF Printed Manifests (needs dev intervention)": "Nuevos manifiestos impresos en PDF (necesita intervención del desarrollador)",
  "New Preorder": "Nuevo Preorden",
  "New Product": "Nuevo Producto",
  "New Register Close Out UI": "Nuevo registro Cerrar IU",
  "New Room": "Nueva Habitación",
  "New Strain": "Nueva Cepa",
  "New table layout saved": "Nuevo diseño de tabla guardado",
  "New Transaction": "Nueva transacción",
  "New User": "Nuevo Usuario",
  "New Vendor": "Nuevo Vendedor",
  "Next Batch Number": "Número de Lote Siguiente",
  "NEXT MONTH": "PRÓXIMO MES",
  "Nightly Manager Email": "Correo Electrónico del Gerente Nocturno",
  "no": "no",
  "No": "No",
  "No data": "Sin datos",
  "No discrepancies": "Sin discrepancias",
  "No Double Pre Order": "No hay doble pedido anticipado",
  "No image selected": "Ninguna imagen seleccionada",
  "No Inventory": "Sin inventario",
  "No Journal Entries": "No hay Entradas en el Diario",
  "No label selected": "Ninguna etiqueta seleccionada",
  "No printer selected": "Ninguna impresora seleccionada",
  "No printer selected.": "No se seleccionó ninguna impresora.",
  "No products to receive": "No hay productos para recibir",
  "No results": "No hay resultados",
  "No transaction details found": "No se encontraron detalles de la transacción",
  "Non Cannabis": "No cannabis",
  "Non Cannabis Sales": "Ventas No Cannábicas",
  "Non-cannabis": "Sin-cannabis",
  "Non-Cannabis": "Sin Cannabis",
  "Non-Cannabis Products": "Productos Sin Cannabis",
  "Non-Cannabis Sales": "Ventas no Cannábicas",
  "None": "Ninguno",
  "Non-Massachusetts clients are unaffected by this change and Massachusetts clients will only be affected once they enable their METRC integration.": "Los clientes que no son de Massachusetts no se ven afectados por este cambio y los clientes de Massachusetts solo se verán afectados una vez que permitan su integración METRC.",
  "not hooked up": "no conectado",
  "Not Stacking": "No Apilando",
  "Not Synced": "No Sincronizado",
  "Note": "Nota",
  "Notes": "Notas",
  "Notes loaded…": "Notas cargadas…",
  "Notication Alert": "Alerta de notificación",
  "Notification Error": "Error de notificación",
  "Notification Success": "Notificación de éxito",
  "Notify Button (for Preorders)": "Botón Notificar (para Pedidos Anticipados)",
  "Now synchronizing members, this will take some time and complete in the background. Feel free to leave this page.": "Ahora sincronizando miembros, esto llevará un tiempo y se completará en segundo plano. Siéntase libre de salir de esta página.",
  "NPI Number": "Número NPI",
  "Number of Labels:": "Número de Etiquetas:",
  "Number of Plants": "Numero de Plantas",
  "Number to Create": "Número Para Crear",
  "Nutrient": "Nutritiva",
  "Nutrient Log": "Registro de Nutrientes",
  "Nutrients": "Nutrientes",
  "OK": "Okay",
  "On Behalf of Vendor": "En Nombre del Vendedor",
  "On Sale": "En Venta",
  "On Sale Ribbon": "En venta cinta",
  "On Sale Text": "Texto a la venta",
  "One digit day": "Día de un dígito",
  "One digit month": "Mes de un dígito",
  "Online Available?": "Disponible en Línea?",
  "Online Description": "Descripción en Linea",
  "Online Product Details": "Detalles del Producto en Línea",
  "Online Title": "Título en Línea",
  "Only apply price breaks at specified weights": "Solo aplique descuentos de precio a pesos especificados",
  "Only open manifests with zero items can be deleted": "Solo se pueden eliminar los manifiestos abiertos con cero elementos",
  "Only require email. pwd, first/last, drivers license, phone for ecom signup": "Solo requiere correo electrónico. pwd, primero / último, licencia de conducir, teléfono para registrarse en ecom",
  "Open": "Abierto",
  "Open Batches": "Lotes Abiertos",
  "Operations": "Operaciones",
  "Optional - the background image of Ecom": "Opcional: la imagen de fondo de Ecom",
  "Optional - the background image of the Navigation Menu": "Opcional: la imagen de fondo del menú de navegación",
  "Options saved": "Opciones salvadas",
  "Or Select Table": "O Seleccionar Tabla",
  "Order": "Orden",
  "Order - these are for transfers outside of your LSP": "Pedido: estos son para transferencias fuera de su LSP",
  "Order completed": "Pedido completado",
  "Order could not be Rejected": "El pedido no pudo ser Rechazado",
  "Order created": "Pedido creado",
  "Order Date": "Fecha de Orden",
  "Order Deleted": "Orden Eliminada",
  "Order does not contain any items.": "El pedido no contiene ningún artículo.",
  "Order Id": "Solicitar ID",
  "Order is missing information required to receive. Please fill out a room and a package id for all items.": "En el pedido falta información necesaria para recibir. Complete una habitación y una identificación del paquete para todos los artículos.",
  "Order No": "Número de Orden",
  "Order Ready Text Message": "Pedido de Mensaje de Texto Listo",
  "Order Rejected": "Orden Rechazada",
  "Order Saved": "Orden Guardada",
  "Order Title": "Título del Pedido",
  "Order Total": "Total del Pedido",
  "OrderNumber": "Número de Orden",
  "Orders": "Pedidos",
  "Orders By Product": "Pedidos por Producto",
  "Orientation": "Orientación",
  "Original Receipt #": "Recibo Original #",
  "Other": "Otro",
  "Other Flower": "Otra Flor",
  "Other minor updates:": "Otras actualizaciones menores:",
  "Our February update has been released.  Highlights include:": "Nuestra actualización de febrero ha sido lanzada. Los puntos destacados incluyen:",
  "Our first update for March has been released. Highlights include:": "Nuestra primera actualización para marzo ha sido lanzada. Los puntos destacados incluyen:",
  "output": "salida",
  "Output": "Salida",
  "Outputs": "Salidas",
  "Over/Short": "Sobre/Corto",
  "Owner": "Propietario",
  "Package": "Paquete",
  "Package #": "Paquete #",
  "Package already scanned": "Paquete ya escaneado",
  "Package Count": "Paquete Cuenta",
  "Package Date": "Fecha del Paquete",
  "Package deleted": "Paquete eliminado",
  "Package Detail": "Detalle del Paquete",
  "Package has no parents": "El paquete no tiene padres",
  "Package History": "Historia del Paquete",
  "Package Id": "ID del Paquete",
  "Package ID": "ID del Paquete",
  "Package Label": "Etiqueta del Paquete",
  "Package No": "Num de Paquete",
  "PACKAGE NO": "PAQUETE NO",
  "Package not found": "Paquete no encontrado",
  "Package Status": "Estado del Paquete",
  "Package(s):": "Paquete(s):",
  "packaged into": "empaquetado en",
  "PackageId": "ID del paquete",
  "packages": "paquetes",
  "Packages": "Paquetes",
  "Packages combined": "Paquetes combinados",
  "Packages created successfully": "Paquetes creados exitosamente",
  "Packages moved": "Paquetes movidos",
  "Packages remediated successfully": "Paquetes remediados con éxito",
  "Packages sublotted": "Paquetes subloteados",
  "Packages To Create": "Paquetes Para Crear",
  "Packaging": "Embalaje",
  "Packaging Date": "Fecha de Embalaje",
  "PACKAGING DATE": "FECHA DE EMBALAJE",
  "Packaging Error": "Error de Embalaje",
  "Page": "Página",
  "Page Header (leave blank for no header)": "Encabezado de página (dejar en blanco sin encabezado)",
  "Paid in Cash": "Pagado en Efectivo",
  "Paid in Check": "Pagado en Cheque",
  "Paid in Debit": "Pagado en Débito",
  "Paid in Loyalty": "Pagado en Lealtad",
  "Pass": "Pasar",
  "Password": "Contraseña",
  "Password updated": "Contraseña actualiza",
  "Password:": "Contraseña:",
  "Patient": "Paciente",
  "Patient DOB": "FDN del Paciente",
  "PatientName": "Nombre del Paciente",
  "Payment Method": "Método de Pago",
  "Payment Summary": "Resumen de Pago",
  "PaymentMethod": "Método de Pago",
  "Payments": "Pagos",
  "Pending Transfers": "Transferencias Pendientes",
  "Per Day": "Por Día",
  "Per Gram": "Por Gramo",
  "Per Month": "Por Mes",
  "Per Plant:": "Por Planta:",
  "Per Week": "Por Semana",
  "Per Year": "Por Año",
  "Percent Amount": "Cantidad Porcentual",
  "Percent Off Price": "Porcentaje de Descuento del Precio",
  "Performed By": "Interpretado Por",
  "Permission group created": "Grupo de permisos creado",
  "Permission Groups": "Grupos de Permisos",
  "Permission Groups for": "Grupos de Permisos para",
  "Permission groups loaded": "Grupos de permisos cargados",
  "Permission list loaded": "Lista de permisos cargada",
  "Permission Updates": "Actualizaciones de permisos",
  "permissions": "permisos",
  "Permissions failed to update": "No se pudieron actualizar los permisos",
  "Permissions for": "Permiso para",
  "Permissions updated": "Permisos actualizados",
  "Pesticides": "Pesticidas",
  "Phone": "Teléfono",
  "Phone No": "Telefono No",
  "Phone Number": "Número de Teléfono",
  "PIN Code": "Código PIN",
  "Pin required on check out": "Pin requerido en el check out",
  "Plant": "Planta",
  "Plant already scanned": "Planta ya escaneada",
  "Plant count": "Recuento de plantas",
  "Plant Count": "Recuento de Plantas",
  "Plant Details": "Detalles de la Planta",
  "Plant Group": "Grupo de Plantas",
  "Plant group created successfully": "Grupo de plantas creado con éxito",
  "Plant Group Details": "Detalles del Grupo de Plantas",
  "Plant Group updated successfully.": "Grupo de Plantas actualizado con éxito.",
  "Plant info saved": "Información de la planta guardada",
  "Plant Journal": "Diario de Planta",
  "Plant labels printed": "Etiquetas de plantas impresas",
  "Plant Manager": "Gerente de la Planta",
  "Plant Mother": "Madre de la Planta",
  "Plant mother status updated successfully.": "El estado de la madre de la planta se actualizó correctamente.",
  "Plant mother updated successfully.": "Planta madre actualizada con éxito.",
  "Plant not found": "Planta no encontrada",
  "Plant Reconciliation": "Reconciliación Vegetal",
  "Plant Serial No": "Número de Serie de la Planta",
  "Plant Stage": "Etapa de Planta",
  "Plant stage updated successfully.": "Etapa de la planta actualizada con éxito.",
  "Plant Weight": "Peso de la Planta",
  "Plant Weight Reporting Batch Size": "Tamaño del lote de informes de peso de la planta",
  "Plant weights": "Peso de las plantas",
  "PlantLabel": "EtiquetaDeLaPlanta",
  "plants": "plantas",
  "Plants": "Plantas",
  "Plants added successfully.": "Plantas añadidas con éxito.",
  "Plants could not be added": "No se pudieron agregar plantas",
  "Plants could not be retired": "Las plantas no pudieron ser retiradas",
  "Plants could not be un-retired": "Las plantas no pueden ser retiradas",
  "Plants do not load by default and must be searched for": "Las plantas no se cargan por defecto y deben buscarse",
  "Plants Moved": "Plantas Movidas",
  "plants moved.": "plantas movidas.",
  "Plants retired successfully.": "Las plantas se retiraron con éxito.",
  "Plants Selected": "Plantas Seleccionadas",
  "Plants un-retired successfully.": "Plantas no retiradas con éxito.",
  "Please confirm inventory update and enter a reason": "Confirme la actualización del inventario e ingrese un motivo",
  "Please contact support@leaflogix.com for more information or to confirm the website url's you are using.": "Póngase en contacto con support@leaflogix.com para obtener más información o para confirmar las URL del sitio web que está utilizando.",
  "Please select a batch": "Por favor seleccione un lote",
  "Please select a location": "Por favor seleccione una ubicación",
  "Please select inventory that is the same product as setup in Product Master": "Seleccione el inventario que es el mismo producto que la configuración en el Maestro de Productos",
  "Please select lot # and date before you scan.": "Seleccione el número de lote y la fecha antes de escanear.",
  "Please select room before you scan.": "Seleccione habitación antes de escanear.",
  "Please select the same unit type for all packages": "Seleccione el mismo tipo de unidad para todos los paquetes.",
  "Please select the Weedmaps Listing you would like to sync with this LeafLogix Location": "Seleccione el listado de Weedmaps que desea sincronizar con esta ubicación de LeafLogix",
  "Please upload an image smaller than 4 MB": "Cargue una imagen de menos de 4 MB.",
  "Point of Contact": "Punto de Contacto",
  "Point Value": "Valor del Punto",
  "Portrait": "Retrato",
  "Portrait180": "Retrato180",
  "POS": "PDV",
  "POS 3.0 is complete": "POS 3.0 está completo",
  "POS Available?": "POS Disponible?",
  "POS Daily Password": "Contraseña diaria de PDV",
  "POS Dashboard": "Panel de PDV",
  "POS Edit Cart Require Manager Password": "POS Editar carro Requerir contraseña de administrador",
  "POS Grams Oz": "PDV Gramos Oz",
  "POS Make Payment": "POS Realizar pago",
  "POS Note Under 21": "Nota POS bajo 21",
  "POS Scan Error": "Error de Escaneo POS",
  "POS Show Rooms": "POS Show Rooms",
  "POS Status": "Estado POS",
  "Post All Invoices": "Contabilizar Todas las Facturas",
  "Post Invoices In Real Time": "Contabilizar facturas en tiempo real",
  "Post Trim Wet Weight": "Peso Húmedo Posterior al Recorte",
  "Postal Code": "Código Postal",
  "Precision Weight": "Peso de Precisión",
  "PreOrder Received Text Message": "Mensaje de Texto Recibido Previo al pedido",
  "PreRoll": "Pre Anuncio",
  "Prescription": "Prescripción",
  "Prescription Number": "Numero de Receta",
  "Present": "Presente",
  "Press and hold the CTRL key.": "Mantenga presionada la tecla CTRL.",
  "Pre-Tax Amount": "Monto Antes de Impuestos",
  "PREV MONTH": "MES ANTERIOR",
  "Prevent Overselling": "Prevenir la Sobreventa",
  "Preview": "Avance",
  "Previous calendar month": "Mes calendario anterior",
  "Previous calendar week": "Semana calendario anterior",
  "Price": "Precio",
  "Price Per Unit": "Precio Por Unidad",
  "Price Tier Added": "Nivel de Precio Agregado",
  "Price To Amount": "Precio al Importe",
  "Price To Cost Plus Percent": "Precio al Costo más Porcentaje",
  "Prices ($ per Gram)": "Precios ($ por Gramo)",
  "Pricing": "Precios",
  "Pricing Details refreshed": "Detalles de Precios Actualizados",
  "Pricing removed": "Precio eliminado",
  "Pricing Tier": "Nivel de Precios",
  "Only group items of the same SKU for price breaks": "Solo agrupe artículos del mismo SKU para descuentos de precios",
  "Pricing Tier Updated": "Nivel de Precios Actualizado",
  "Pricing Tiers": "Niveles de Precios",
  "Pricing updated successfully": "Precios actualizados exitosamente",
  "Primary Caregiver": "Cuidador Primario",
  "Print": "Impresión",
  "Print an Extra Copy": "Imprima una Copia Extra",
  "Print Invoice": "Imprimir Factura",
  "Print Label": "Etiqueta de Impresión",
  "Print Labels": "Imprimir Etiquetas",
  "Print Manifest": "Imprimir Manifiesto",
  "Print Node Details": "Detalles del Nodo de Impresión",
  "Print Non Cannabis Labels": "Imprimir etiquetas sin cannabis",
  "Print Plant Labels": "Imprimir Etiquetas de Plantas",
  "Print Preview": "Preestreno Impresión",
  "Print Table": "Imprimir Tabla",
  "Printer": "Impresor",
  "Printer ID": "ID de Impresora",
  "Printers": "Impresoras",
  "Processor": "Procesador",
  "Processor - Tax Exempt": "Procesador - Exento de Impuestos",
  "Producer/License": "Productor/Licencia",
  "Product": "Producto",
  "Product BI Dashboard": "Panel de Control de Producto BI",
  "Product Categories": "Categorías de Producto",
  "Product Categories will also need to be assigned to limit categories in order for them to count against a specific limit.  This is configurable in Maintenance > Product Categories.": "Las categorías de productos también deberán asignarse a categorías límite para que puedan contar en un límite específico. Esto es configurable en Mantenimiento> Categorías de productos.",

  "Product Category": "Categoria de Producto",
  "Product Combination": "Combinación de Productos",
  "Product Conversion": "Conversión de producto",
  "Product Details": "Detalles del producto",
  "Product Grams": "Gramos de Producto",
  "Product Group": "Grupo de Productos",
  "Product Group Saved!": "Grupo de productos guardado!",
  "Product Groups": "Grupos de Productos",
  "Product History": "Historia del Producto",
  "Product Images": "Imágenes del Producto",
  "Product Journal": "Diario de Producto",
  "Product Master": "Maestro de Producto",
  "Product Name": "Nombre del Producto",
  "Product Size": "Tamaño del Producto",
  "Product Tax Categories": "Categorías de Impuestos de Productos",
  "Product updated.": "Producto actualizado.",
  "Product Values": "Valores del Producto",
  "Product/Service (Cannabis Items)": "Producto/Servicio (Artículos de Cannabis)",
  "Product/Service (NON-Cannabis Items)": "Producto/Servicio (Artículos Sin Cannabis)",
  "production": "producción",
  "Production": "Producción",
  "Production Batch": "Lote de Producción",
  "Products": "Productos",
  "Products by Category": "Productos por Categoria",
  "Products sold by Hour": "Productos Vendidos por Hora",
  "Profit": "Lucro",
  "Propagation Type": "Tipo de Propagación",
  "Public API": "API Pública",
  "Public API Setup": "Configuración de API Pública",
  "Pull": "Jale",
  "Pulled": "Tirado",
  "Purchase Limit (g)": "Límite de Compra (g)",
  "Purchase Limit Category": "Categoría de Límite de Compra",
  "Purchase Limit Type": "Tipo de Límite de Compra",
  "Purchase Limits": "Límites de Compra",
  "Purchase limits are now configurable per customer per limit category (Flower, concentrate, etc).": "Los límites de compra ahora son configurables por cliente por categoría de límite (Flor, concentrado, etc.).",
  "Purchase Order Completed": "Orden de Compra Completada",
  "Purchase Order Id": "ID de Orden de Compra",
  "Purchase Order Re-Opened": "Orden de Compra Reabierta",
  "Purchase Order Submitted": "Orden de Compra Enviada",
  "Purchase Orders": "Ordenes de Compra",
  "Push": "Empujar",
  "Pushed": "Empujado",
  "QBO Accounts Updated": "Cuentas QBO Actualizadas",
  "QBO Settings Saved.": "Configuración de QBO Guardada.",
  "qty": "cantidad",
  "Qty": "Cantidad",
  "Qty Sold": "Cantidad Vendida",
  "Quantity": "Cantidad",
  "Quantity Added": "Cantidad Agregada",
  "Quantity Available": "Cantidad Disponible",
  "Quantity Received": "Cantidad Recibida",
  "Quantity Requested": "Cantidad Solicitada",
  "Quantity to Print": "Cantidad a Imprimir",
  "Quantity to Sublot": "Cantidad a Sublote",
  "Quickbooks Integration": "Integración de Quickbooks",
  "QuickBooks Inventory Settings": "Configuración de Inventario de QuickBooks",
  "QuickBooks Online Settings": "Configuración en Línea de QuickBooks",
  "QuickBooks Settings Saved": "Configuración de QuickBooks Guardada",
  "Rate Amount": "Cantidad de Tarifa",
  "Rate Limit (ms)": "Límite de Velocidad (ms)",
  "Rate Name": "Nombre de Tarifa",
  "Rate Type": "Tipo de Cambio",
  "Rate Unit": "Unidad de Tarifa",
  "Raw Flower Eq.": "Eq. de flor cruda",
  "Reactivate": "Reactivar",
  "Ready to integrate with WeedMaps? Click the button below to get started!": "¿Listo para integrarse con WeedMaps? ¡Presiona el botón de abajo para iniciar!",
  "Reason": "Razón",
  "Reason Code": "Código de Razón",
  "Reason Codes": "Códigos de Razón",
  "Reason Note": "Nota de Razón",
  "Rec Flower Equiv": "Equiv de Flores Rec",
  "Rec Per Gram": "Rec Por Gramo",
  "Rec Total": "Rec Total",
  "Receipt #": "Recibo #",
  "Receipt Id": "ID de Recibo",
  "Receipt No": "Recibo No",
  "Receipt Printer?": "Impresora de Recibos?",
  "Receipt Reconciliation": "Reconciliación de Recibos",
  "Receipt Reconciliation Details": "Detalles de Conciliación de Recibos",
  "ReceiptNo": "Recibo No",
  "Receipts": "Ingresos",
  "Receive": "Recibir",
  "Receive Details": "Recibir Detalles",
  "Receive History": "Recibir Historia",
  "Receive Notes": "Recibir Notas",
  "Receive Transaction": "Recibir Transacción",
  "Received": "Recibido",
  "Received By": "Recibido Por",
  "Recent Errors": "Errores Recientes",
  "Recipe": "Receta",
  "Recipe Batches": "Lotes de Recetas",
  "Recipe Batches in Progress": "Lotes de Recetas en Progreso",
  "Recipe Name": "Nombre de Receta",
  "Recipes": "Recetas",
  "Recipient": "Recipiente",
  "Recon Zero Balance": "Conciliar el Equilibrio Cero",
  "Reconcile Plant": "Conciliar Planta",
  "Reconcile Remaining Plant": "Conciliar Planta Restante",
  "Reconciled": "Reconciliado",
  "Reconciliation": "Reconciliación",
  "Reconciliation closed": "Reconciliación cerrada",
  "Reconciliation closed successfully": "La reconciliación se cerró exitosamente",
  "Reconciliation could not be closed": "La reconciliación no pudo cerrarse",
  "Reconciliation could not be deleted": "La reconciliación no se pudo eliminar",
  "Reconciliation created successfully": "Reconciliación creada exitosamente",
  "Reconciliation Date": "Fecha de Reconciliación",
  "Reconciliation deleted": "Reconciliación eliminada",
  "Reconciliation deleted successfully": "Reconciliación eliminada exitosamente",
  "Reconnect": "Volver a Conectar",
  "Recorded By": "Grabado Por",
  "Recreational": "Recreativo",
  "Recreational - Tax Exempt": "Recreativo - Exento de Impuestos",
  "Recreational Price": "Precio Recreativo",
  "Recreational Tax Rate": "Tasa de Impuesto Recreativo",
  "Reference #": "Referencia #",
  "Reference No": "Numero de Referencia",
  "Referred By": "Referido por",
  "Refresh": "Actualizar",
  "Re-fresh notes": "Actualizar notas",
  "Refunds": "Reembolsos",
  "Register": "Registro",
  "Register Actions": "Registrar Acciones",
  "Register By Phone Number": "Registrarse por número de teléfono",
  "Register Name": "Nombre de Registro",
  "Register Transactions": "Registrar Transacciones",
  "Registers": "Registros",
  "Registers at": "Se registra en",
  "Regulatory Category": "Categoría Reglamentaria",
  "Reject Order?": "Rechazar Orden?",
  "Rejected": "Rechazado",
  "Release Notes": "Notas de Lanzamiento",
  "Remediate": "Remediar",
  "Remove": "Eliminar",
  "Remove all items from receive?": "¿Eliminar todos los artículos de recibir?",
  "Remove Image": "Quita la Imagen",
  "Remove pre orders from Ecom availble Inventory": "Eliminar pedidos anticipados del inventario disponible de Ecom",
  "Remove this item from the receive?": "¿Eliminar este artículo de la recepción?",
  "Remove?": "¿Eliminar?",
  "Removed Quantity": "Cantidad Eliminada",
  "Removed Reason": "Motivo Eliminado",
  "Reopen": "Reabrir",
  "Re-Open Order": "Orden de Reapertura",
  "Report": "Reporte",
  "Report Type": "Tipo de Informe",
  "Report Updates": "Informar actualizaciones",
  "reported successfully": "informado con éxito",
  "Reports": "Informes",
  "Request": "Solicitud",
  "Request Date": "Fecha de Solicitud",
  "Require Doctor For Check In": "Requerir médico para el check in",
  "Require Individual User API Keys": "Requerir llaves de API de usuario individual",
  "Require Manager Approval": "Requerir Aprobación del Gerente",
  "Require Manager Password For Allotment Adjust": "Requerir contraseña de administrador para ajuste de asignación",
  "Require Manager Password for Loyalty Adjustment": "Requerir contraseña de administrador para ajuste de lealtad",
  "Require Manager Password for Returns": "Requerir contraseña de administrador para devoluciones",
  "Require Multiple Items?": "¿Requiere Varios Artículos?",
  "Require Policy Signature": "Requerir firma de política",
  "Require Signature to Checkout": "Requerir firma para pagar",
  "Require Valid Driver's License": "Requerir licencia de conducir válida",
  "Require valid state system credentials to use POS": "Requerir credenciales de sistema de estado válidas para usar POS",
  "Required Fields": "Campos Requeridos",
  "Requires a signature to checkout in the POS": "Requiere una firma para pagar en el POS",
  "Requires Remediation": "Requiere Remediación",
  "Reset": "Reiniciar",
  "Reset Credentials": "Restablecer Credenciales",
  "Response": "Respuesta",
  "Restore": "Restaurar",
  "Restore Removed Items to Inventory": "Restaurar Elementos Eliminados al Inventario",
  "restored": "restaurado",
  "Restrict Package Products to the Harvest Strain": "Restrinja los productos del paquete a la cepa de cosecha",
  "Restrict To Location": "Restringir a la Ubicación",
  "Restrict voids": "Restringir vacíos",
  "Restricts voids to same day / before closeout": "Restringe los vacíos al mismo día / antes del cierre",
  "Resync Inventory Ids": "Resincronizar ID de Inventario",
  "Retail": "Al por Menor",
  "Retailer": "Detallista",
  "Retailer - Tax Exempt": "Minorista - Exención de Impuestos",
  "Retire": "Retirar",
  "Retire Plant": "Planta de Retiro",
  "Retired": "Retirado",
  "Return By": "Volver Por",
  "Return Reason": "Vuelve la Razon",
  "Return Reasons": "Razones de Retorno",
  "Return Receipt #": "Recibo de Devolución #",
  "Returned": "Devuelto",
  "Returns": "Devoluciones",
  "Room": "Habitación",
  "Room Change": "Cambio de habitación",
  "Room History": "Historia de la Habitación",
  "Room Name": "Nombre de la Habitación",
  "Room/Table": "Habitación/Tabla",
  "Rooms": "Habitaciones",
  "Rooms re-loaded": "Habitaciones recargadas",
  "Rooms updated successfully": "Habitaciones actualizadas con éxito",
  "Rotation": "Rotación",
  "Round all transactions to the nearest dollar": "Redondee todas las transacciones al dólar más cercano",
  "Round Cash Change to Nearest Nickel.": "Redondear el cambio de efectivo al níquel más cercano.",
  "Round Percent Discounts": "Descuentos porcentuales redondos",
  "Round percent discounts to nearest dollar when calculated discount amount": "Redondee los descuentos porcentuales al dólar más cercano cuando se calcule el monto del descuento",
  "Round to the nearest dollar": "Redondear al dolar mas cercano",
  "Round Transactions Down to Next Dollar": "Redondee las transacciones al siguiente dólar",
  "Round Transactions Down to Next Quarter": "Redondear transacciones al siguiente trimestre",
  "Rounding": "Redondeo",
  "Run": "Ejecutar",
  "Run Auto Discount": "Ejecutar descuento automático",
  "Run Auto Price": "Ejecutar precio automático",
  "RX No": "Num de Receta",
  "Sale Date": "Fecha de Venta",
  "Sales": "Ventas",
  "Sales Account": "Cuenta de Ventas",
  "Sales by Budtender": "Ventas de Presupuesto",
  "Sales Date": "Fecha de Venta",
  "Sales floor": "Piso de ventas",
  "Sales Receipts": "Recibos de Ventas",
  "Sales Receipts Defaults": "Valores Predeterminados de Recibos de Ventas",
  "Sales Receipts Log (QBO)": "Registro de Recibos de Ventas (QBO)",
  "Sales Receipts Successfully Uploaded": "Recibos de Ventas Cargados Exitosamente",
  "Sales Since Last Drop": "Ventas Desde la última Gota",
  "Sales Tax Code Category (Cannabis Items)": "Categoría del Código del Impuesto Sobre las Ventas (Artículos de Cannabis)",
  "Sales Tax Code Category (NON-Cannabis Items)": "Categoría del Código del Impuesto Sobre las Ventas (Artículos sin Cannabis)",
  "Sample created successfully": "Muestra creada exitosamente",
  "Sample Date": "Fecha de la Muestra",
  "Sample Weight": "Peso de la Muestra",
  "Sampled Package ID": "ID del Paquete Muestreado",
  "sandbox": "salvadera",
  "Sativa": "Sativo",
  "Saturday": "Sábado",
  "Save": "Salvar",
  "SAVE": "SALVAR",
  "Save & Report": "Salvar & Reportar",
  "Save Customer": "Guardar Cliente",
  "Save Details": "Guardar Detalles",
  "Save Note": "Guardar Nota",
  "Save Transaction": "Guardar Transacción",
  "Save Vendor": "Guardar Vendedor",
  "Saved Orders": "Órdenes Guardadas",
  "Saved!": "¡Salvado!",
  "Saving": "Ahorro",
  "Saving group failed!": "¡El grupo de ahorro ha fallado!",
  "Scan Into": "Escanear en",
  "Scan Items": "Escanear Elementos",
  "Scan Packages": "Escanear Paquetes",
  "Scan Plant Barcode": "Escanear Código de Barras de Planta",
  "Scan Plants": "Escanear Plantas",
  "Scan Plants into Harvest": "Escanear Plantas en la Cosecha",
  "Scan Plants into Locations": "Escanear Plantas en Ubicaciones",
  "Scan Plants into Retirement": "Escanear Plantas Hacia la Retirar",
  "Scan Table Barcode": "Escanear Código de Barras de la Tabla",
  "Scan Table into Room": "Escanear Tabla en la Habitación",
  "Scanned Plants": "Plantas Escaneadas",
  "scans": "escaneos",
  "Schedule IIF Sales Receipts Export": "Anexo IIF Exportación de recibos de ventas",
  "Schedule QBO Sales Receipts": "Programar recibos de ventas de QBO",
  "Search": "Buscar",
  "Search by Inventory Id": "Buscar por ID de Inventario",
  "Search by Plant Group": "Búsqueda por Grupo de Plantas",
  "Search by Plant Status": "Búsqueda por Estado de la Planta",
  "Search by Room": "Buscar por Habitación",
  "Search by Text": "Buscar por Texto",
  "Search Customers": "Buscar Clientes",
  "Search for product": "Buscar producto",
  "Search Full Serial No": "Buscar número de serie completo",
  "Search notes": "Buscar notas",
  "Search Plants": "Buscar Plantas",
  "Searching manifests has been upgraded to include searching for full or partial matches on the package IDs, product names, product SKUs, customer name, manifest title and manifest order id.": "La búsqueda de manifiestos se ha actualizado para incluir la búsqueda de coincidencias completas o parciales en los ID de paquete, nombres de productos, SKU de productos, nombre de cliente, título de manifiesto e ID de orden de manifiesto.",
  "Second Driver": "Segundo Conductor",
  "Secret": "Secreto",
  "See the full release notes sent via email for more details": "Consulte las notas de la versión completa enviadas por correo electrónico para obtener más detalles.",
  "See the full release notes sent via email for more details.": "Vea las notas de la versión completa enviadas por correo electrónico para obtener más detalles.",
  "Seed": "Semilla",
  "Seed(s) To Destroy": "Semilla (s) para destruir",
  "Seedling count successfully destroyed.": "Recuento de plántulas destruido con éxito.",
  "Seedlings": "Plántulas",
  "Select": "Seleccionar",
  "Select a batch to continue.": "Seleccione un lote para continuar.",
  "Select a Company": "Selecciona una Empresa",
  "Select a plant to continue.": "Seleccione plantas para continuar.",
  "Select a recipe to continue.": "Selecciona una receta para continuar.",
  "Select a single item to continue.": "Seleccione un solo elemento para continuar.",
  "Select a single seedling to continue.": "Seleccione una sola plántula para continuar.",
  "Select a summary before you print": "Seleccione un resumen antes de imprimir",
  "Select Action": "Seleccione la Acción",
  "Select Address": "Seleccionar Dirección",
  "Select All": "Seleccionar Todo",
  "Select another nutrient": "Seleccione otro nutriente",
  "Select Batch": "Seleccionar Lote",
  "Select batch to continue.": "Seleccione lote para continuar.",
  "Select Batches": "Seleccione Lotes",
  "Select Car": "Seleccionar Auto",
  "Select Category": "Selecciona una Categoría",
  "Select Date": "Seleccione Fecha",
  "Select discount to continue": "Seleccionar descuento para continuar",
  "Select Discount Type": "Seleccionar Tipo de Descuento",
  "Select Driver": "Seleccionar Conductor",
  "Select Existing": "Seleccione Existente",
  "Select item(s) to continue.": "Seleccione los elementos para continuar.",
  "Select Location": "Seleccionar Ubicación",
  "Select Lot": "Seleccione Lote",
  "Select Master Category": "Seleccionar Categoría Maestra",
  "Select Mother": "Seleccione Madre",
  "Select Mother Plant": "Seleccionar Planta Madre",
  "Select None": "No Seleccionar Ninguno",
  "Select one plant to continue.": "Seleccione una planta para continuar.",
  "Select Option": "Seleccionar Opción",
  "Select Package": "Seleccione Paquete",
  "Select package to look up": "Seleccione el paquete para buscar",
  "Select Page": "Seleccionar Página",
  "Select Plant Group": "Seleccionar Grupo de Plantas",
  "Select plant stage": "Seleccionar etapa de planta",
  "Select plant to continue.": "Seleccione planta para continuar.",
  "Select Plant Type": "Seleccionar Tipo de Planta",
  "Select plants to continue.": "Seleccione plantas para continuar.",
  "Select Qty": "Seleccionar cantidad",
  "Select Quantity": "Selecciona la Cantidad",
  "Select Register": "Seleccione Registrarse",
  "Select Report": "Seleccionar Informe",
  "select report and datespan to view or export": "seleccione informe y fecha para ver o exportar",
  "Select Report Type": "Seleccionar Tipo de Informe",
  "Select Room": "Seleccionar Habitación",
  "Select Status": "Seleccionar Estado",
  "Select Strain": "Seleccionar Cepa",
  "Select Table": "Seleccionar Tabla",
  "Select the rows one by one by marking the checkbox": "Seleccione las filas una por una marcando la casilla de verificación",
  "Select timespan": "Seleccionar intervalo de tiempo",
  "Select Type": "Seleccione Tipo",
  "Select user continue.": "Seleccionar usuario continuar.",
  "Selected": "Seleccionada",
  "Selected Day Totals": "Totales de Días Seleccionados",
  "Selected Navigation Items": "Elementos de navegación seleccionados",
  "Selected Packages": "Paquetes Seleccionados",
  "Selected plants": "Plantas seleccionadas",
  "Selected Rows": "Filas Seleccionadas",
  "Send": "Enviar",
  "Send a note to the customer": "Enviar una nota al cliente",
  "Send after visit SMS": "Enviar después de la visita SMS",
  "Send Email?": "¿Enviar correo electrónico?",
  "Send PO Email": "Enviar Correo Electrónico de Pedido",
  "Send Sales Receipt Summary": "Enviar Resumen de Recibo de Ventas",
  "Send SMS Message": "Enviar Mensaje SMS",
  "Send Test Invoice": "Enviar Factura de Prueba",
  "Serial #": "# De Serie",
  "Serial No": "Número de Serie",
  "Serial Number": "Número de Serie",
  "SerialNumber": "Número de Serie",
  "SerialNumberStrain": "NúmeroDeSerieCepa",
  "Set": "Ajustar",
  "Set Displayed Language": "Establecer Idioma Visualizado",
  "Set Harvest Stage": "Establecer Escenario de Cosecha",
  "Set Password": "Configurar la Clave",
  "Set Total Weight": "Establecer Peso Total",
  "Settings": "Configuraciones",
  "Settings updated": "Ajustes actualizan",
  "Shift key": "Tecla Shift",
  "Shipping Information": "Información de Envío",
  "Show": "Mostrar",
  "Show Allotment": "Mostrar asignación",
  "Show Allotment Information": "Mostrar Información de Asignación",
  "Show Allotment Valid Dates": "Mostrar fechas válidas de asignación",
  "Show Batch Ingredients": "Mostrar Ingredientes por Lotes",
  "Show Batch Names": "Mostrar Nombres de Lote",
  "Show Cashier ID": "Mostrar ID de Cajero",
  "Show Cashier Name": "Mostrar Nombre del Cajero",
  "Show Cultivation Integration Reconcilation": "Mostrar Reconciliación de Integración de Cultivo",
  "Show Cultivation Tax": "Mostrar impuesto de cultivo",
  "Show Customer Address": "Mostrar Dirección del Cliente",
  "Show Customer Date Of Birth": "Mostrar Fecha de Nacimiento del Cliente",
  "Show Customer ID": "Mostrar ID de Cliente",
  "Show Customer Name": "Mostrar Nombre del Cliente",
  "Show Days Supply Calculator": "Mostrar días Calculadora de suministros",
  "Show Delivery Information": "Mostrar Información de Entrega",
  "Show Doctor Designator Field": "Mostrar campo de designador médico",
  "Show EcomV2 Features in backend (Multiple images in Product Master, Ecom Styling, Product Groups)": "Mostrar características de EcomV2 en el backend (varias imágenes en Product Master, Ecom Styling, Grupos de productos)",
  "Show expired discount groups": "Mostrar grupos de descuento caducados",
  "Show expired discounts": "Mostrar descuentos caducados",
  "Show Header": "Mostrar Encabezado",
  "Show Hidden Printers": "Mostrar Impresoras Ocultas",
  "Show Icon On Guest  List": "Mostrar icono en la lista de invitados",
  "Show in POS": "Mostrar en POS",
  "Show Ingredients": "Mostrar ingredientes",
  "Show Lab Data": "Mostrar datos de laboratorio",
  "Show lab data on ViewProduct page. Requires configuration from Doug.": "Mostrar datos de laboratorio en la página ViewProduct. Requiere configuración de Doug.",
  "Show Logo": "Mostrar logotipo",
  "Show Loyalty": "Mostrar lealtad",
  "Show Loyalty Points": "Mostrar Puntos de Fidelidad",
  "Show Manifest": "Mostrar manifiesto",
  "Show notes when entering POS": "Mostrar notas al ingresar a PDV",
  "Show Notes?": "¿Mostrar notas?",
  "Show On Sale Page": "Mostrar en la página de venta",
  "Show on sale page on navigation menu": "Mostrar en la página de venta en el menú de navegación",
  "Show Only Low Inventory": "Mostrar Solo Bajo Inventario",
  "Show only products with a difference": "Mostrar solo productos con una diferencia",
  "Show Option to Bypass State System(METRC, BioTrack, etc).": "Mostrar opción para omitir el sistema de estado (METRC, BioTrack, etc.).",
  "Show Orders by Product in Inventory": "Mostrar pedidos por producto en inventario",
  "Show Price Online": "Mostrar precio en línea",
  "Show Product Images": "Mostrar imágenes del producto",
  "Show Product Instructions": "Mostrar instrucciones del producto",
  "Show Product SKU": "Mostrar SKU del Producto",
  "Show Quantity Online": "Mostrar cantidad en línea",
  "Show Register ID": "Mostrar ID de Registro",
  "Show Registration Page": "Mostrar página de registro",
  "Show registration page when not logged into the storefront": "Mostrar página de registro cuando no ha iniciado sesión en el escaparate",
  "Show Retired Products": "Mostrar Productos Retirados",
  "Show Search Bar": "Mostrar barra de búsqueda",
  "Show Signature Line": "Mostrar Línea de Firma",
  "Show Source Package ID": "Mostrar ID de paquete de origen",
  "Show Stats on Store Page": "Mostrar estadísticas en la página de la tienda",
  "Show the Doctor Designator field on the POS profile": "Mostrar el campo Doctor Designator en el perfil POS",
  "Show the logo row in the Storefront. Disable if you primarily use the storefront as an iFrame": "Mostrar la fila del logotipo en el escaparate. Deshabilite si usa principalmente el escaparate como un iFrame",
  "Show the notify button in POS so that stores can notify 3rd party menu providers that orders have been fulfilled": "Muestre el botón de notificación en el punto de venta para que las tiendas puedan notificar a los proveedores de menú de terceros que se han completado los pedidos.",
  "Show the waste management dialog when retiring plants": "Mostrar el diálogo de gestión de residuos al retirar plantas",
  "Show Total Grams Purchased": "Mostrar el Total de Gramos Comprados",
  "Show Transactions Tab in POS": "Mostrar pestaña de transacciones en POS",
  "Show Unit Count to Add": "Mostrar recuento de unidades para agregar",
  "Show Vendor Online": "Mostrar vendedor en línea",
  "Show Zero Inventory": "Mostrar inventario cero",
  "Show/Hide PIN": "Mostrar/Ocultar PIN",
  "Showing rows": "Mostrando filas",
  "Size": "Tamaño",
  "Size Item updated successfully": "Tamaño del artículo actualizado correctamente",
  "Sizes": "Tamaños",
  "SKU": "SKU",
  "Software Support Notice": "Aviso de soporte de software",
  "Sold": "Vendido",
  "Sold by": "Vendido por",
  "Sold By": "Vendido Por",
  "Sold On": "Vendido En",
  "Sold To": "Vendido a",
  "Solvent Residue": "Residuo Solvente",
  "Something went wrong": "Algo salió mal",
  "Sort By Last Name": "Ordenar por apellido",
  "Source": "Fuente",
  "Source Batch": "Lote de Origen",
  "Source Package": "Paquete Fuente",
  "Source Package ID": "ID del Paquete de Origen",
  "Spring Big settings saved": "Configuraciones Spring Big Guardadas",
  "Springbig Integration": "Integración Springbig",
  "Sprout Configuration": "Configuración de Sprout",
  "Stacking Order": "Orden de Apilamiento",
  "Stacks On Other Rates": "Pilas en Otras Tarifas",
  "Stage": "Escenario",
  "Stage Change Date": "Fecha de Cambio de Etapa",
  "Start": "Comienzo",
  "Start Balance": "Saldo Inicial",
  "Start Date": "Fecha de Inicio",
  "Start Over": "Comenzar de Nuevo",
  "Start Time": "Hora de Inicio",
  "Started By": "Empezado Por",
  "Started On": "Comenzó En",
  "Starting Balance": "Saldo Inicial",
  "Starting Cash Balance": "Saldo de Caja Inicial",
  "Starting Tag": "Etiqueta de Inicio",
  "State": "Estatal",
  "State Id": "ID del Estado",
  "State ID #": "ID de Estado #",
  "State Id Expiration": "Caducidad de ID del Estado",
  "State Id No": "ID del Estado No",
  "Static Reports": "Reportes Estáticos",
  "Statistics": "Estadísticas",
  "Statistics - API - Last 7 Days": "Estadísticas - API - Últimos 7 días",
  "Statistics - Cache": "Estadísticas - Caché",
  "Status": "Estado",
  "Status Code": "Código de Estado",
  "Status List updated": "Lista de Estado Actualizada",
  "Status Removed": "Estado Eliminado",
  "Status Settings": "Configuraciones de Estado",
  "Stem Waste Weight": "Peso Residual del Tallo",
  "Step Cost": "Costo de Paso",
  "Step Name": "Nombre del Paso",
  "Steps": "Pasos",
  "Steps:": "Pasos:",
  "Sticky Header/Nav": "Encabezado / Nav pegajoso",
  "Stop # on Route": "Parada # en Ruta",
  "Stop Scanning": "Dejar de Escanear",
  "Store Front Show Qty": "Tienda Front Show Qty",
  "Storefront Account": "Cuenta de Escaparate",
  "strain": "cepa",
  "Strain": "Cepa",
  "Strains": "Cepas",
  "Strains re-loaded": "Cepas recargadas",
  "Strain(s) updated successfully": "Cepa(s) actualizada(s) con éxito",
  "Street": "Calle",
  "Street Address": "Dirección",
  "Sub Total": "Total Parcial",
  "Subject": "Tema",
  "Sublot": "Sublote",
  "Sublot Package": "Paquete de Sublote",
  "Sublotted Package Id": "ID del Paquete Subloteado",
  "Submit": "Enviar",
  "Submit Date": "Fecha de Envío",
  "Submit Order": "Orden de Envio",
  "Submitted": "Presentado",
  "Subtotal": "Total parcial",
  "SubTotal": "Total Parcial",
  "Successfully added": "Agregado exitosamente",
  "Successfully added to manifest": "Agregado correctamente al manifiesto",
  "Sugar Leaf Value": "Valor de la Hoja de Azúcar",
  "Summary": "Resumen",
  "Sunday": "Domingo",
  "Superuser": "Superusuario",
  "Superuser Tools": "Herramientas de Superusuario",
  "Supplier": "Proveedor",
  "Supplier License Number": "Número de Licencia del Proveedor",
  "Support for adult use sales in Massachusetts is now complete with a POS update to allow the LeafLogix MassCIP integration to work in conjunction with METRC.": "El soporte para ventas de uso para adultos en Massachusetts ahora se completa con una actualización de POS para permitir que la integración de LeafLogix MassCIP funcione en conjunto con METRC.",
  "Surviving Entity": "Entidad Sobreviviente",
  "Sync every 15 minutes": "Sincronizar cada 15 minutos",
  "Sync Everything": "Sincronizar Todo",
  "Sync Inventory": "Inventario de Sincronización",
  "Sync Members": "Sincronizar Miembros",
  "Sync Printers": "Impresoras de Sincronización",
  "Sync to METRC Item?": "¿Sincronizar con el artículo METRC?",
  "Synced All": "Sincronizado Todo",
  "Synced Customer": "Cliente Sincronizado",
  "Synced Inventory": "Inventario Sincronizado",
  "Synced Transaction": "Transacción Sincronizada",
  "Synced Vendor": "Vendedor Sincronizado",
  "Synchronization": "Sincronización",
  "Synchronize data": "Sincronizar datos",
  "Synchronizing members": "Sincronizar miembros",
  "Systems running our beta business intelligence and reporting features will notice that the link names have been updated by replacing the word \"Looker\" with \"BI.\"": "Los sistemas que ejecutan nuestras funciones beta de inteligencia empresarial e informes notarán que los nombres de los enlaces se han actualizado al reemplazar la palabra \"Looker \" con \"BI\".",
  "Table": "Tabla",
  "Table Name": "Nombre de la Tabla",
  "Table Settings": "Configuraciones de Tabla",
  "Tables": "Tablas",
  "Tag": "Etiqueta",
  "Tag Type": "Tipo de Etiqueta",
  "Tags": "Etiquetas",
  "Take Inventory Snapshots": "Tomar Instantáneas de Inventario",
  "Tare Weight": "Peso de Tara",
  "Target Batch": "Lote Objetivo",
  "Tax": "Impuesto",
  "Tax Categories": "Categorías de Impuestos",
  "Tax Coupons": "Cupones de Impuestos",
  "Tax Per Unit": "Impuesto Por Unidad",
  "Tax Rate": "Tasa de Impuesto",
  "Tax Rate NonCannibis": "Tasa de Impuestos no Cannibis",
  "Tax Rates": "Las Tasas de Impuestos",
  "Taxable": "Imponible",
  "Taxable?": "¿Imponible?",
  "Taxes": "Impuestos",
  "Terpenes": "Terpenos",
  "Test": "Prueba",
  "Test Product": "Producto de Prueba",
  "Tested Date": "Fecha de Prueba",
  "Testing Status": "Estado de Prueba",
  "Testing Status Text": "Texto de Estado de Prueba",
  "Text Color": "Color de texto",
  "Thanks for using Leaflogix - this is a test.  Let's get some real updates up on here!": "Gracias por usar Leaflogix, esta es una prueba. ¡Obtengamos algunas actualizaciones reales aquí!",
  "THC Content": "Contenido de THC",
  "The background color of the Alert notification": "El color de fondo de la notificación de alerta",
  "The background color of the Error notification": "El color de fondo de la notificación de error",
  "The background color of the Success notification": "El color de fondo de la notificación de éxito",
  "The black action wheel next to the lab results beaker icon has been removed and all actions have been moved to the gray wheel.": "La rueda de acción negra al lado del icono del vaso de resultados de laboratorio se ha eliminado y todas las acciones se han movido a la rueda gris.",
  "The color of navigation item text on hover": "El color del texto del elemento de navegación al pasar el mouse por encima",
  "The color of navigation items on hover": "El color de los elementos de navegación al pasar el mouse",
  "The color of text in the navigation bar": "El color del texto en la barra de navegación.",
  "The color of text in the 'On Sale' ribbon": "El color del texto en la cinta 'En venta'",
  "The color of the navigation bar": "El color de la barra de navegación.",
  "The color of the 'On Sale' ribbon": "El color de la cinta 'En venta'",
  "The following url's running on microsoft silverlight with the login screen as shown below will no longer be supported or active as of Sept 15.": "La siguiente URL que se ejecuta en Microsoft Silverlight con la pantalla de inicio de sesión como se muestra a continuación ya no será compatible o activa a partir del 15 de septiembre.",
  "The logo for the Ecom header": "El logo del encabezado Ecom",
  "The new closing report now includes a cannabis/non-cannabis breakdown in the transaction summary section.": "El nuevo informe de cierre ahora incluye un desglose de cannabis / no cannabis en la sección de resumen de transacciones.",
  "The new UI features cleaner look as well as an option to \"Drop Cash\", removing cash from the register as a deposit without actually closing the register out.": "La nueva interfaz de usuario presenta un aspecto más limpio, así como una opción para \"Soltar efectivo \", eliminando efectivo del registro como depósito sin cerrar realmente el registro.",
  "The password field has been removed from user details in User Maintenance.  Instead, select an existing user and click \"Set Password\" in the action wheel and you'll get a pop up to set the user's password.": "El campo de contraseña se ha eliminado de los detalles del usuario en Mantenimiento del usuario. En su lugar, seleccione un usuario existente y haga clic en \"Establecer contraseña\" en la rueda de acción y obtendrá una ventana emergente para configurar la contraseña del usuario.",
  "The primary background color of the Ecom page": "El color de fondo primario de la página de Ecom",
  "The primary color of text within Ecom": "El color primario del texto dentro de Ecom",
  "The transaction total is multiplied by this number.": "El total de la transacción se multiplica por este número.",
  "Theft": "Robo",
  "There are existing plant in this room, would you like to move them?": "Hay plantas existentes en esta sala, ¿le gustaría moverlas?",
  "There are existing plant in this room. Would you like to move them?": "Hay plantas existentes en esta sala. ¿Te gustaría moverlos?",
  "There was an issue saving your Accounts": "Hubo un problema al guardar sus cuentas",
  "This action does not transmit to METRC.": "Esta acción no se transmite a METRC.",
  "This action will release your credentials with weedmaps, and require you to connect again.": "Esta acción liberará sus credenciales con weedmaps y requerirá que se vuelva a conectar.",
  "This allows you to select all, clear the selection or specify an amount of rows to select, starting from the first row.": "Esto le permite seleccionar todo, borrar la selección o especificar una cantidad de filas para seleccionar, comenzando desde la primera fila.",
  "This customer information is used for transfer manifests to location": "Esta información del cliente se usa para transferir manifiestos a la ubicación",
  "This group has no images, the default will be displayed. Upload a custom one!": "Este grupo no tiene imágenes, se mostrará el valor predeterminado. ¡Cargue uno personalizado!",
  "This harvest had been marked as finished, this will unfinish the harvest.": "Esta cosecha había sido marcada como terminada, esto finalizará la cosecha.",
  "This option hides the option to bypass the state system, whichever that may be i.e. METRC, BioTrack, etc.": "Esta opción oculta la opción de omitir el sistema de estado, cualquiera que sea, es decir, METRC, BioTrack, etc.",
  "This product has no images so the default will be displayed. You can upload a custom image from the Upload Image button. If you have uploaded an image recently through the data import process, please note it can take a few hours for that image to display on this screen.": "Este producto no tiene imágenes, por lo que se mostrará el valor predeterminado. Puede cargar una imagen personalizada desde el botón Cargar imagen. Si cargó una imagen recientemente a través del proceso de importación de datos, tenga en cuenta que esa imagen puede tardar algunas horas en mostrarse en esta pantalla.",
  "This shows/hides a Transaction Date field when doing a closeout to allow the user to submit the closeout in the past.": "Esto muestra / oculta un campo Fecha de transacción cuando se realiza un cierre para permitir al usuario enviar el cierre en el pasado.",
  "This will allow the users to post to METRC again.  METRC does not support updating harvest weights so this will create a discrepancy.": "Esto permitirá a los usuarios publicar en METRC nuevamente. METRC no admite la actualización de los pesos de cosecha, por lo que esto creará una discrepancia.",
  "This will not allow coupons to be added to the cart if the cart price total is less than the value of the coupon": "Esto no permitirá que se agreguen cupones al carrito si el precio total del carrito es menor que el valor del cupón",
  "Thursday": "Jueves",
  "Tier Name": "Nombre de Nivel",
  "Time Clock": "Reloj de Tiempo",
  "Time clock updated successfully!": "Reloj de tiempo actualizado con éxito!",
  "Time Diff": "Diferencia de Tiempo",
  "Time Offset": "Desplazamiento de Tiempo",
  "Time Range": "Intervalo de Tiempo",
  "Time Zone": "Zona Horaria",
  "Tincture": "Tintura",
  "Tip Cut": "Corte de la Punta",
  "Title": "Título",
  "to": "a",
  "To": "A",
  "TO": "A",
  "To Loc": "A la Ubic",
  "To Location": "A la Ubicación",
  "To Lsp": "A Lsp",
  "To Package": "Empacar",
  "To Product": "Producir",
  "To Register": "Para Registrarse",
  "To Room": "A la Habitación",
  "To:": "A:",
  "Topicals": "Tópicos",
  "Total": "Total",
  "Total # of stops on Route": "Número total de paradas en la Ruta",
  "Total Cost": "Coste Total",
  "Total Credit ($)": "Crédito Total ($)",
  "Total Customers": "Clientes Totales",
  "Total Discount": "Descuento Total",
  "Total Discrepancies": "Discrepancias Totales",
  "Total Errors": "Errores Totales",
  "Total Income": "Ingresos Totales",
  "Total Invoice": "Total de la Factura",
  "Total is greater than Wet Weight": "El total es mayor que el peso húmedo",
  "Total Items": "Articulos Totales",
  "Total Items Sold": "Total de Artículos Vendidos",
  "Total Items Sold (Cannabis)": "Total de Artículos Vendidos (Canabis)",
  "Total Items Sold (Non Cannabis)": "Total de Artículos Vendidos (No Canabis)",
  "Total Payments": "Pagos Totales",
  "Total Plant Weight": "Peso Total de la Planta",
  "Total plants": "Plantas Totales",
  "Total Price": "Precio Total",
  "Total Products": "Productos Totales",
  "Total Quantity Package": "Paquete de Cantidad Total",
  "Total Quantity to Package": "Cantidad Total a Empaquetar",
  "Total Requests": "Solicitudes Totales",
  "Total Sales": "Ventas Totales",
  "Total Steps": "Pasos Totales",
  "Total Tax": "Total Impuestos",
  "Total Transactions": "Transacciones Totales",
  "Total Voids": "Vacíos Totales",
  "Total Weight": "Peso Total",
  "Total Wet Weight": "Peso Húmedo Total",
  "Total wet weight is greater than harvest weight": "El peso húmedo total es mayor que el peso de cosecha",
  "Total:": "Total:",
  "Total: $": "Total (PS):",
  "TotalReturned": "Total Devuelto",
  "Track by Inventory IDs": "Seguimiento por ID de inventario",
  "Track Immature Plants Individually": "Rastree plantas inmaduras individualmente",
  "Transaction": "Transacción",
  "Transaction Controls": "Controles de Transacciones",
  "Transaction Date": "Fecha de Transacción",
  "Transaction Details": "Detalles de la Transacción",
  "Transaction Discrepancies": "Discrepancias de Transacciones",
  "Transaction History": "Historial de Transacciones",
  "Transaction ID": "ID de Transacción",
  "Transaction Notes": "Notas de Transacciones",
  "Transaction Summary": "Resumen de Transacciones",
  "TransactionBy": "TransacciónPor",
  "TransactionDate": "Fecha de Transacción",
  "Transactions": "Actas",
  "Transactions processed while this is off will need to have returns and voids processed manually in METRC.": "Las transacciones procesadas mientras está desactivado deberán tener devoluciones y anulaciones procesadas manualmente en METRC.",
  "Transfer": "Transferir",
  "Transfer - these are for transfers within your LSP": "Transferencia: son para transferencias dentro de su LSP",
  "Transfer Between Registers": "Transferencia Entre Registros",
  "Transfer Orders": "Órdenes de Transferencia",
  "Transferred": "Transferido",
  "Treat Moisture Loss As Harvest Waste": "Tratar la pérdida de humedad como residuos de cosecha",
  "Trim Weight": "Recortar Peso",
  "Tuesday": "Martes",
  "Turns on the new SKU generator": "Enciende el nuevo generador de SKU",
  "Tutorials": "Tutoriales",
  "Two digits day": "Día de dos dígitos",
  "Two digits month": "Dos dígitos mes",
  "Two digits year": "Año de dos dígitos",
  "Txn Cnt": "Rcnt Trans",
  "Type": "Tipo",
  "TYPE": "TIPO",
  "Type (weight/qty)": "Tipo (peso / cantidad)",
  "Under Cut": "Debajo Cortar",
  "Undo": "Deshacer",
  "Unit": "Unidad",
  "Unit Cost": "Costo Unitario",
  "Unit Discount": "Descuento Unitario",
  "Unit Price": "Precio Unitario",
  "Unit Weight": "Unidad de peso",
  "Units": "Unidades",
  "Unlimited": "Ilimitado",
  "Unreconciled": "No reconciliado",
  "Unretire": "No Retirar",
  "Un-Retire Plant": "No Jubilado Planta",
  "Update": "Actualizar",
  "UPDATE": "ACTUALIZAR",
  "Update Defaults": "Actualizar Valores Predeterminados",
  "Update Logo": "Actualizar Logotipo",
  "Update Manifest Item": "Actualizar Elemento Manifiesto",
  "Update Package": "Paquete de Actualización",
  "Update to Receive History search function": "Actualizar para recibir la función de búsqueda del historial",
  "Update User Password": "Actualizar Contraseña de Usuario",
  "Updated package": "Paquete actualizado",
  "Updated Quantity": "Cantidad Actualizada",
  "Updated Status To": "Estado Actualizado A",
  "Upgraded Manifest Search": "Búsqueda de manifiesto actualizada",
  "Upload": "Subir",
  "Upload Customer File": "Subir Archivo de Cliente",
  "Upload Image": "Cargar Imagen",
  "Upload Inventory CSV": "Subir Inventario CSV",
  "Upload Lab Results": "Subir Resultados de Laboratorio",
  "Uploading": "Cargando",
  "URL's starting with :": "URL que comienzan con:",
  "Use BioTrack": "Usar BioTrack",
  "Use both Med and Rec Flower Equiv": "Utilice Med y Rec Flower Equiv",
  "Use Cart Display": "Usar visualización de carro",
  "Use Cycle Date": "Usar Fecha de Ciclo",
  "Use Detailed Wet Weights": "Utilice pesos húmedos detallados",
  "Use Enterprise": "Usar Empresa",
  "Use External Id": "Usar ID Externo",
  "Use IDScan.net integration": "Utilice la integración de IDScan.net",
  "Use Leaf": "Usar Leaf",
  "Use Leafly": "Usar Leafly",
  "Use MassCIP": "Usar MassCIP",
  "Use METRC": "Usar METRC",
  "Use MN": "Usar MN",
  "Use Multi Package Creator": "Utilice Multi Package Creator",
  "Use negative amount to remove inventory": "Use una cantidad negativa para eliminar el inventario",
  "Use New Barcode Scanner": "Utilice el nuevo escáner de código de barras",
  "Use new order fulfillment": "Use el nuevo cumplimiento del pedido",
  "Use New Tax Structures": "Use nuevas estructuras fiscales",
  "Use Opt-In Workflow at Checkout": "Utilice el Flujo de Trabajo Opcional al Finalizar la Compra",
  "Use Package Based Recipe Batches": "Use lotes de recetas basadas en paquetes",
  "Use Print Node": "Usar Nodo de Impresión",
  "Use Report Builder": "Usar el Generador de Informes",
  "Use SKU as Package ID?": "¿Usar SKU como ID de paquete?",
  "Use Spin Debit Integration": "Utilice la integración de débito de giro",
  "Use Spring Big": "Usar Spring Big",
  "Use the new Sku generator": "Usa el nuevo generador de Sku",
  "Use the new Weedmaps onboarding oauth flow": "Utilice el nuevo flujo de incorporación de Weedmaps oauth flow",
  "Use Transfer Templates": "Usar Plantillas de Transferencia",
  "Use Weed Maps": "Usar Weed Maps",
  "User": "Usuario",
  "User account created successfully!": "¡Cuenta de usuario creada con éxito!",
  "User account updated successfully!": "Cuenta de usuario actualizada con éxito!",
  "User group deleted": "Grupo de usuarios eliminado",
  "User Key": "Llave de Usuario",
  "User Keys": "Llaves de Usuario",
  "User Metrc Key": "Clave de Metrc de Usuario",
  "User Name": "Nombre de Usuario",
  "User Permission Groups": "Grupos de Permisos de Usuario",
  "User Pin Length": "Longitud del Nip del Usuario",
  "Username": "Nombre de usuario",
  "UserName": "Nombre de Usuario",
  "Username Must end with @leaflogix.com": "El nombre de usuario debe terminar con @ leaflogix.com",
  "users": "los usuarios",
  "Users": "Los Usuarios",
  "Using our POS 3.0, process the order and produce the manifest for transport.": "Con nuestro POS 3.0, procese el pedido y produzca el manifiesto para el transporte.",
  "Valid From": "Válida Desde",
  "Valid To": "Válido Hasta",
  "Valid tokens": "Fichas válidas",
  "Validate Key": "Validar Llave",
  "Validate Metrc Key": "Validar Llave Metrc",
  "Validated Forms": "Formularios Validados",
  "Value": "Valor",
  "Variance": "Diferencia",
  "Vault Register": "Registro de Bóveda",
  "Vegetation Ended": "Vegetación Terminada",
  "Vegetation Started": "La Vegetación Comenzó",
  "Vendor": "Vendedor",
  "Vendor Code": "Código de Proveedor",
  "Vendor Email": "Correo Electrónico del Vendedor",
  "Vendor ID": "ID del Vendedor",
  "Vendor License #": "Licencia de Vendedor #",
  "Vendor Name": "Nombre del Vendedor",
  "Vendor Type": "Tipo de Vendedor",
  "Vendors": "Vendedores",
  "Vendors updated successfully": "Proveedores actualizados con éxito",
  "View Details": "Ver Detalles",
  "View Inventory": "Ver Inventario",
  "View Nutrient Log": "Ver Registro de Nutrientes",
  "View Permissions": "Ver Permisos",
  "View Users": "Ver Usuarios",
  "VIN": "NIV",
  "Visible?": "¿Visible?",
  "Void": "Vacío",
  "Void Date": "Fecha Nula",
  "Voids": "Vacíos",
  "Volume": "Volumen",
  "Waiting for weedmaps": "Esperando Weedmaps",
  "Warning": "Advertencia",
  "WARNING": "ADVERTENCIA",
  "Waste Date": "Fecha de Desperdicio",
  "Waste details failed to update": "No se pudieron actualizar los detalles de los desechos",
  "Waste details updated successfully": "Detalles de residuos actualizados con éxito",
  "Waste Manager": "Gerente de Residuos",
  "Waste Package Details": "Detalles del Paquete de Residuos",
  "Waste Type": "Tipo de Residuos",
  "Waste Weight": "Peso Residual",
  "Waste/Disposal": "Residuos/Disposición",
  "Water Leaf Value": "Valor de la Hoja de Agua",
  "Wax": "Cera",
  "We have moved all our customers over to the new platform which began in early 2018 and have staffed our technology department with experts to support and continued development of our product offering in this newest technology.": "Hemos trasladado a todos nuestros clientes a la nueva plataforma que comenzó a principios de 2018 y hemos contratado a nuestro departamento de tecnología con expertos para apoyar y continuar el desarrollo de nuestra oferta de productos en esta tecnología más nueva.",
  "We have updated the search function on the Receive History so that clients can search for partial or complete package IDs. You can also search your Receiving History for title, license number, product name, and transaction ID.": "Hemos actualizado la función de búsqueda en el Historial de recepción para que los clientes puedan buscar ID de paquetes parciales o completos. También puede buscar en su Historial de recepción el título, el número de licencia, el nombre del producto y el ID de la transacción.",
  "Wednesday": "Miércoles",
  "Weedmaps 2": "Weedmaps 2",
  "WeedMaps Category": "Categoría WeedMaps",
  "WeedMaps Integration (NEW)": "Integración de WeedMaps (NUEVO)",
  "Weedmaps Orderable - Allow users to order products online via WeedMaps. Dispensary will recieve orders according to settings configured at WeedMaps.com, order fulfillment can be done via LeafLogix Backend/POS. Per-product Weedmaps Orderable setting available on Product Master. Product Master setting overrides this setting.": "Weedmaps Orderable: permite a los usuarios ordenar productos en línea a través de WeedMaps. Dispensary recibirá pedidos de acuerdo con las configuraciones configuradas en WeedMaps.com, el cumplimiento del pedido se puede hacer a través de LeafLogix Backend / POS. Configuración ordenable de Weedmaps por producto disponible en Product Master. La configuración maestra del producto anula esta configuración.",
  "Weedmaps settings saved": "Configuración de Weedmaps guardada",
  "WeedMaps Sync Started!": "¡Se inició la sincronización de WeedMaps!",
  "Week Planted": "Semana Plantada",
  "Weekly Recurrence": "Recurrencia Semanal",
  "Weight": "Peso",
  "Weight (g)": "Peso (gramos)",
  "Wet Bud Weight": "Peso de Brote Húmedo",
  "Wet Trim Weight": "Peso de Ajuste Húmedo",
  "Wet waste": "Residuos húmedos",
  "Wet Waste": "Residuos Húmedos",
  "Wet Weight": "Peso Mojado",
  "Wet Weights": "Pesos Mojados",
  "Wet weights exceed total wet weight": "Los pesos húmedos exceden el peso húmedo total",
  "When browsing products, show Price, Weight, THC and CBD content when available. If disabled, just show price": "Al buscar productos, muestre el contenido de Precio, Peso, THC y CBD cuando esté disponible. Si está deshabilitado, solo muestre el precio",
  "When creating a new user, you will set up their account first then set their password using the above steps.": "Al crear un nuevo usuario, primero configurará su cuenta y luego establecerá su contraseña utilizando los pasos anteriores.",
  "When the CTRL key is held down you can notice that the mouse pointer changes to a crosshair. This will allow you to select multiple rows by using your mouse. Simply click and drag to select/un-select the rows you want.": "Cuando se mantiene presionada la tecla CTRL, puede observar que el puntero del mouse cambia a una cruz. Esto le permitirá seleccionar varias filas con el mouse. Simplemente haga clic y arrastre para seleccionar / anular la selección de las filas que desee.",
  "Which Unit Price": "Cual Precio Unitario",
  "Wholesale": "Venta al por Mayor",
  "Width": "Anchura",
  "With that said, the prior version of our software (version 2.0)  will no longer be supported as of Sept 15, 2018 and access to the older version of the software will be removed.": "Dicho esto, la versión anterior de nuestro software (versión 2.0) ya no será compatible a partir del 15 de septiembre de 2018 y se eliminará el acceso a la versión anterior del software.",
  "Would you like to start over?": "¿Te gustaría comenzar de nuevo?",
  "X Position": "Posición X",
  "Y Position": "Posición Y",
  "Year": "Año",
  "yes": "si",
  "Yes": "Si",
  "Yes - Remove": "Si - Eliminar",
  "You are currently clocked in": "Actualmente estás registrado en",
  "You are integrating with weedmaps!": "¡Te estás integrando con weedmaps!",
  "You are not clocked in": "No estás registrado",
  "You do not have permission to create a plant group": "No tienes permiso para crear un grupo de plantas",
  "You do not have permission to view or edit lab samples": "No tiene permiso para ver o editar muestras de laboratorio",
  "You need to be a manager to access this feature!": "¡Necesitas ser un administrador para acceder a esta función!",
  "You should not set or modify this value unless instructed to by LeafLogix staff.": "No debe establecer o modificar este valor a menos que se lo indique el personal de LeafLogix.",
  "You successfully clocked in": "Has registrado correctamente",
  "You successfully clocked out": "Has salido con éxito",
  "Your Quickbooks Settings have not been set up": "La configuración de Quickbooks no se ha configurado",
  "You've already added this product to the PO. Please edit it instead of adding another line": "Ya ha agregado este producto a la orden de compra. Edítelo en lugar de agregar otra línea",
  "Zero Value": "Valor Cero",
  "This user is not authorized to view this page": "Este usuario no está autorizado para ver esta página",
  "Home": "Inicio",
  "Marketing": "Márketing",
  "Delivery": "Entregas",
  "Manufacturing": "Fabricación",
  "Traceability": "Trazabilidad",
  "Catalog": "Catálogos",
  "Brands": "Marcas",
  "Configure": "Configuraciones",
  "Overview": "Informacion General",
  "Point of Sale": "Punto de Venta",
  "Data Audit Tools": "Herramientas para Auditoría de Datos",
  "Data Validation": "Verificacion de Datos",
  "Data Troubleshooting": "Resolución de Problemas de Datos",
  "Ecommerce": "Ventas por Internet",
  "End of Day": "Fin del Día",
  "Financial": "Area Financiera",
  "State Reporting": "Informes de Auditoria",
  "Customer Groups": "Grupos de Clientes",
  "Batches": "Lotes",
  "Harvests": "Cosechas",
  "Waste": "Residuos",
  "Infusions": "Infusiones",
  "Integration Audit": "Auditoria de Terceros",
  "Receipt Audit": "Auditoría de Recibos",
  "Cultivation Audit": "Auditoría de Cultivo",
  "Cultivation Integration Audit": " Auditoria de Terceros - Cultivos",
  "Account": "Cuenta",
  "Devices": "Dispositivos",
  "Taxes & Compliance": "Impuestos y Regulaciones",
  "Fees & Donations": "Cargos y Donaciones"
}
