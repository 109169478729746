import { atom } from 'recoil';

export const localIAAtom = atom({
  key: 'localIA',
  default: false,
  effects_UNSTABLE: [useLocalStorageEffect],
});

function useLocalStorageEffect({ setSelf, onSet }) {
  const key = 'use-new-ia';
  const savedValue = localStorage.getItem(key);
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet((newValue, _, isReset) => {
    if (isReset) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(newValue));
    }
  });
}
