const keyMirror = require('keymirror');

module.exports = {
  endPoints: {},

  actions: keyMirror({
    ADD_NOTIFICATION: null,
    REMOVE_NOTIFICATION: null,
  }),

  events: keyMirror({
    CHANGE_EVENT: 'changeEvent',
  }),
};
