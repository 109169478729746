import { atom } from 'recoil';
import UserStore from '../../app_deprecated/stores/UserStore';

// TODO-AIRBENDER: type userDispensaries

export const userDispensariesAtom = atom({
  key: 'userDispensaries',
  default: {
    locations: [],
    selectedLocation: {
      LocId: '',
      HasPermission: false,
      Logo: '',
      IsLive: null,
      State: '',
    },
    companies: [],
    selectedCompany: {
      LspId: '',
      Logo: '',
      oldLogo: '',
    },
    locProfile: {
      AcceptCanPay: false,
      AcceptHypur: false,
      AcceptLinx: false,
      AllowFulfillmentModel: false,
      APIActionsQueued: false,
      AuditCustomerInfoAccess: false,
      BulkRegisterTransactions: false,
      CanUseMassDPHIntegration: false,
      CanadaBarcodeScanning: false,
      CheckOutCheck: false,
      CheckOutCredit: false,
      CheckOutDebit: false,
      CustomerVisitReason: false,
      EcomV2: false,
      EnableAGCOReport: false,
      EnableDelivery: false,
      EnablePlantSearch: true,
      FormValidation: false,
      HasBioTrack: false,
      EnableUntrackedInventoryTransfers: false,
      HasJory: false,
      HasLooker: false,
      HasLookerExplorer: false,
      HasMETRC: false,
      HasPurchasOrders: false,
      IsLeaflyEnabled: false,
      IsLicensed: false,
      IsWeedMapsEnabled: false,
      Manufacturing: false,
      MetrcBatchMode: false,
      NewIAManufacturingModule: false,
      NoDoublePreOrder: false,
      NYSPMP: false,
      OrdersbyProduct: false,
      PlantReconciliation: false,
      POSStatus: false,
      ShowCultivationIntegrationReconcilation: false,
      ShowLeafLogixLoyalty: false,
      ShowExternalLoyalty: false,
      UseAdilas: false,
      UseAlpineIQ: false,
      UseBioMauris: false,
      UseBioTrack: false,
      UseCCRS: false,
      UseDistillationMethod: false,
      UseFeesDonations: false,
      UseFyllo: false,
      UseTrym: false,
      UseIDScan: false,
      UseInfusions: false,
      UseLeaf: false,
      UseLeafLink: false,
      UseManufacturers: false,
      UseMETRC: false,
      UseMMUR: false,
      UseMn: false,
      UseMultiStopManifests: false,
      UseNewPOS: false,
      UseOnFleet: false,
      UseOrderFulfill: false,
      UsePMP: false,
      UseQuickBooks: false,
      UseSkylightDebitIntegration: false,
      UseSpinDebitIntegration: false,
      UseSpringBig: false,
      UseSprout: false,
      UseTookan: false,
      UseTranCloudDebitIntegration: false,
      UseWorkWave: false,
      WeedmapsV2: false,
      WholesaleCarsAndDrivers: false,
      ShowSourcePackage: false,
      HasPlanetPayments: false,
      UseSSO: false,
      FixedTablesBeta: false,
      HasDutchiePay: false,
      HasPaynetworx: false,
    },
    lspSettings: {},
    integrations: false,
    data: {
      SessionId: '',
    },
    tableSettings: [],
  },
  effects_UNSTABLE: [useSyncStateEffect],
});

function useSyncStateEffect({ setSelf, trigger }) {
  function changeListenerForUserState() {
    const {
      locations,
      selectedLocation,
      companies,
      selectedCompany,
      locProfile,
      lspSettings,
      data,
      integrations,
      tableSettings,
    } = UserStore.getState();
    setSelf({
      locations,
      selectedLocation,
      companies,
      selectedCompany,
      locProfile,
      lspSettings,
      data,
      integrations,
      tableSettings,
    });
  }

  if (trigger === 'get') {
    changeListenerForUserState(); // initial call
  }

  // Subscribe to remote storage changes and update the atom value
  UserStore.addChangeListener(changeListenerForUserState);

  // Cleanup remote storage subscription
  return () => UserStore.removeChangeListener(changeListenerForUserState);
}
