import i18next from 'i18next';
import AjaxPromises from './AjaxPromises.jsx';
import NotificationActions from '../actions/NotificationActions';
import UserStore from '../stores/UserStore';

export type PostConfig = {
  failureMessage?: string;
  successMessage?: string;
  booleanData?: boolean;
  allowOverrideApiData?: boolean;
};

export default {
  post2: async (url: string, data?: {}, config?: PostConfig) => {
    try {
      const formData = config.allowOverrideApiData ? UserStore.mergeApiData(data) : UserStore.getApiData(data);
      const resp = await AjaxPromises.POST(url, formData);

      if (!config) {
        config = {};
      }

      if (!resp.Result) {
        throw resp;
      }
      if (config.successMessage) {
        config.successMessage = i18next.t(config.successMessage);
        NotificationActions.success(config.successMessage);
      }

      return config.booleanData ? resp.Data : resp.Data || true;
    } catch (ex) {
      console.error(ex);
      let message = config.failureMessage || 'An error occurred';
      message = i18next.t(message);
      if (ex.Message) {
        message += `:\n${ex.Message}`;
      }
      if (ex.IntegrationMessage) {
        message += `:\n${ex.IntegrationMessage}`;
      }

      NotificationActions.error(message);
      return false;
    }
  },
  post: async (
    url: string,
    data?: {},
    failureMessage?: string,
    successMessage?: string,
    booleanData?: boolean,
    successCallback?: () => void
  ) => {
    try {
      const formData = UserStore.getApiData(data);
      const resp = await AjaxPromises.POST(url, formData);

      if (!resp.Result) {
        throw resp;
      }
      if (successMessage) {
        successMessage = i18next.t(successMessage);
        NotificationActions.success(successMessage);
      }

      if (successCallback) {
        return successCallback();
      }

      return booleanData ? resp.Data : resp.Data || true;
    } catch (ex) {
      console.error(ex);
      let message = failureMessage || 'An error occurred';
      message = i18next.t(message);
      if (ex.Message) {
        message += `:\n${ex.Message}`;
      }
      if (ex.IntegrationMessage) {
        message += `:\n${ex.IntegrationMessage}`;
      }

      NotificationActions.error(message);
      return false;
    }
  },

  // Allow the caller to handle the response
  postWithCustomResult: async (url: string, data?: {}) => {
    const formData = UserStore.getApiData(data);
    const resp = await AjaxPromises.POST(url, formData);
    return resp;
  },
};
