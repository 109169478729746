const keyMirror = require('keymirror');

module.exports = {
  endPoints: {
    LOAD_LIST_OF_REPORTS: 'api/posv3/reports/list-reports',
    RUN_REPORT: 'api/posv3/reports/run-report',

    LOAD_REPORT_LIST: 'api/posv3/reports/list-reports',
    RUN_CLOSING_REPORT: 'api/posv3/reports/closing-report',
  },

  actions: keyMirror({
    LIST_OF_REPORTS_LOADED: null,
    SET_REPORT_DATA: null,
    CLEAR_SELECTED_REPORT: null,
    REPORT_DATA_LOADED: null,
    REPORT_FAILED: null,
  }),

  events: keyMirror({
    CHANGE_EVENT: 'changeEvent',
  }),
};
