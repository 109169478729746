{
  "name": "launchdarkly-react-client-sdk",
  "version": "2.24.0",
  "description": "LaunchDarkly SDK for React",
  "author": "LaunchDarkly <team@launchdarkly.com>",
  "license": "Apache-2.0",
  "keywords": [
    "launchdarkly",
    "launch",
    "darkly",
    "react",
    "sdk",
    "bindings"
  ],
  "main": "lib/index.js",
  "types": "lib/index.d.ts",
  "files": [
    "lib",
    "src",
    "!**/*.test.*",
    "!**/__snapshots__"
  ],
  "scripts": {
    "test": "jest",
    "test:junit": "jest --ci --reporters=default --reporters=jest-junit",
    "build": "rimraf lib/* && tsc && mv lib/src/* lib && rimraf lib/package.json lib/src lib/*.test.*",
    "lint": "tslint -p tsconfig.json 'src/**/*.ts*'",
    "lint:all": "npm run lint",
    "check-typescript": "tsc",
    "prepublishOnly": "npm run build",
    "prettier": "prettier --write 'src/*.@(js|ts|tsx|json|css)'",
    "link-dev": "./link-dev.sh"
  },
  "repository": {
    "type": "git",
    "url": "git://github.com/launchdarkly/react-client-sdk.git"
  },
  "homepage": "https://github.com/launchdarkly/react-client-sdk",
  "devDependencies": {
    "@testing-library/jest-dom": "^4.1.0",
    "@testing-library/react": "^11.2.6",
    "@types/enzyme": "^3.10.3",
    "@types/hoist-non-react-statics": "^3.3.1",
    "@types/jest": "^25.2.3",
    "@types/lodash.camelcase": "^4.3.6",
    "@types/prop-types": "^15.7.2",
    "@types/react": "^17.0.3",
    "@types/react-dom": "^17.0.3",
    "@types/react-test-renderer": "^17.0.1",
    "@types/uuid": "^3.4.5",
    "enzyme": "^3.10.0",
    "enzyme-adapter-react-16": "^1.14.0",
    "jest": "^26.0.1",
    "jest-junit": "^6.3.0",
    "prettier": "^1.18.2",
    "prop-types": "^15.7.2",
    "react": "^17.0.0",
    "react-dom": "^17.0.0",
    "react-test-renderer": "^17.0.2",
    "rimraf": "^3.0.0",
    "ts-jest": "^26.0.0",
    "tslint": "^5.19.0",
    "tslint-config-prettier": "^1.18.0",
    "tslint-plugin-prettier": "^2.0.1",
    "typescript": "^3.8.3"
  },
  "dependencies": {
    "hoist-non-react-statics": "^3.3.2",
    "launchdarkly-js-client-sdk": "2.19.2",
    "lodash.camelcase": "^4.3.0",
    "uuid": "^3.3.2"
  },
  "peerDependencies": {
    "react": "^16.6.3 || ^17.0.0",
    "react-dom": "^16.8.4 || ^17.0.0"
  }
}
