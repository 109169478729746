import { EventEmitter } from 'events';
import AppDispatcher from '../utils/AppDispatcher';

import {
  actions as CultivationActions,
  events as CultivationEvents,
  endPoints as CultivationEndpoints,
} from '../constants/CultivationConstants';

import { actions as AppEvents } from '../constants/AppConstants';

import UserStore from './UserStore';
import AjaxPromises from '../utils/AjaxPromises.jsx';
import HttpClient from '../utils/HttpClient';

const _state = {
  mothers: [],
  plants: false,
  batches: [],
  batchesLite: [],
  plantGroups: [],
  batchFlag: 'All', // open|closed|all
  benches: false,
  benchesList: [],
  labels: false,
  seedlings: false,
  reasonCodes: false,
  wasteTypes: false,
  loadedWasteTypes: false,
  selectedBatch: null,
  wasteMethods: [],
  loadedWasteMethods: false,
  wasteReasons: [],
  loadedWasteReasons: false,
  plantTransactionTypes: [],
  loadedPlantTransactionTypes: false,
  projectedBatches: [],
};

class CultivationStoreClass extends EventEmitter {
  getState() {
    return _state;
  }

  emitChange(callback) {
    this.emit(CultivationEvents.CHANGE_EVENT, callback);
  }

  addChangeListener(callback) {
    this.on(CultivationEvents.CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CultivationEvents.CHANGE_EVENT, callback);
  }

  get wasteTypes() {
    // prevent multiple calls
    if (_state.loadedWasteTypes === false) {
      _state.loadedWasteTypes = true;
      this.refreshWasteTypes();
    }

    return _state.wasteTypes;
  }

  get benchesList() {
    if (!_state.benches) {
      _state.benches = true; // prevent multiple calls
      this.refreshBenches();
    }
    return _state.benchesList;
  }

  get wasteMethods() {
    // prevent multiple calls
    if (_state.loadedWasteMethods === false) {
      _state.loadedWasteMethods = true;
      this.refreshPlantWasteMethods();
    }

    return _state.wasteMethods;
  }

  get wasteReasons() {
    // prevent multiple calls
    if (_state.loadedWasteReasons === false) {
      _state.loadedWasteReasons = true;
      this.refreshPlantWasteReasons();
    }

    return _state.wasteReasons;
  }

  get plantTransactionTypes() {
    if (_state.loadedPlantTransactionTypes === false) {
      _state.loadedPlantTransactionTypes = true;
      this.refreshPlantTransactionTypes();
    }

    return _state.plantTransactionTypes;
  }

  async refreshWasteTypes() {
    try {
      const resp = await HttpClient.post(CultivationEndpoints.GET_WASTE_TYPES);
      if (resp) {
        _state.wasteTypes = resp;
      }
    } catch (ex) {
      console.error('Waste Types failed to load');
      _state.benches = false;
      return;
    }
    this.emitChange('waste-type');
  }

  async refreshBenches() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(CultivationEndpoints.GET_MAINTENANCE_BENCHES, data);
      if (resp.Result) {
        _state.benches = true;
        _state.benchesList = resp.Data;
      }
    } catch (ex) {
      console.error('Benches failed to load');
      _state.benches = false;
      return;
    }
    this.emitChange('bench');
  }

  async refreshPlantWasteMethods() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(CultivationEndpoints.GET_WASTE_METHODS, data);
      if (resp.Result) {
        _state.wasteMethods = resp.Data;
      }
    } catch (ex) {
      console.error('Plant waste methods failed to load', ex);
      return;
    }
    this.emitChange('wate-method');
  }

  async refreshPlantWasteReasons() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(CultivationEndpoints.GET_WASTE_REASONS, data);
      if (resp.Result) {
        _state.wasteReasons = resp.Data;
      }
    } catch (ex) {
      console.error('Plant waste reasons failed to load', ex);
      return;
    }
    this.emitChange('wate-reason');
  }

  async refreshPlantTransactionTypes() {
    const resp = await HttpClient.post(
      CultivationEndpoints.GET_PLANT_TRANSACTION_TYPES,
      null,
      'Plant transaction types failed to load'
    );
    if (resp) {
      _state.plantTransactionTypes = resp;
    }
    this.emitChange('plant-transaction-types');
  }
}

const CultivationStore = new CultivationStoreClass();

CultivationStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppEvents.LOCATION_LOADED:
      _state.selectedBatch = null;
      CultivationStore.emitChange();
      break;

    case CultivationActions.PLANTS_LOADED:
      _state.plants = action.data;
      CultivationStore.emitChange();
      break;

    case CultivationActions.MOTHERS_LOADED:
      _state.mothers = action.data;
      CultivationStore.emitChange();
      break;

    case CultivationActions.BATCHES_LOADED:
      _state.batches = action.data;
      CultivationStore.emitChange();
      break;

    case CultivationActions.BATCHES_LITE_LOADED:
      _state.batchesLite = action.data;
      CultivationStore.emitChange();
      break;

    case CultivationActions.BENCHES_LOADED:
      _state.benches = action.data;
      CultivationStore.emitChange();
      break;

    case CultivationActions.PLANT_LABELS_LOADED:
      _state.labels = action.data;
      CultivationStore.emitChange();
      break;

    case CultivationActions.SEEDLINGS_LOADED:
      _state.seedlings = action.data;
      CultivationStore.emitChange();
      break;

    case CultivationActions.REASON_CODES_LOADED:
      _state.reasonCodes = action.data;
      CultivationStore.emitChange();
      break;

    case CultivationActions.SELECTED_BATCH_LOADED:
      _state.selectedBatch = action.data;
      CultivationStore.emitChange();
      break;

    case CultivationActions.PLANT_GROUPS_LOADED:
      _state.plantGroups = action.data;
      CultivationStore.emitChange();
      break;

    case CultivationActions.PROJECTED_BATCHES_LOADED:
      _state.projectedBatches = action.data;
      CultivationStore.emitChange();
      break;
  }
});

export default CultivationStore;
