import { EventEmitter } from 'events';
import Cookies from 'universal-cookie';
import _ from 'lodash';
import LogRocket from 'logrocket';
import AppDispatcher from '../utils/AppDispatcher';
import { events as UserEvents, actions as UserActions } from '../constants/UserConstants';
import { actions as AppEvents } from '../constants/AppConstants';
import { endPoints as EndPoints, actions as AuthActions } from '../constants/AuthConstants';
import { getLDClient } from '../../app/utils/launch-darkly';

import AjaxPromises from '../utils/AjaxPromises.jsx';
import ConfigStore from './ConfigStore';

const _state = {
  data: false as any,

  companies: false as any,
  selectedCompany: false as any,

  locations: false as any,
  selectedLocation: false as any,

  locProfile: false as any,

  tableSettings: [] as any,
  settings: [] as any,

  features: [] as any,
  validatedForms: [] as any,
  integrations: false as any,
  lspSettings: false as any,

  customerTypes: [] as any,

  isSSOLoginEnabled: false as any,
};

enum FieldOption {
  Show = 1,
  Required = 2,
  Hide = 3,
  RequiredIfCannabis = 4,
}

class UserStoreClass extends EventEmitter {
  getState() {
    return _state;
  }

  mergeApiData(formData?: any) {
    if (!formData) {
      formData = {};
    }

    formData.SessionId = _state.data ? _state.data.SessionId.toString() : '';

    if (!formData.hasOwnProperty('LspId')) {
      formData.LspId = _state.selectedCompany ? _state.selectedCompany.LspId : '';
    }

    if (!formData.hasOwnProperty('LocId')) {
      formData.LocId = _state.selectedLocation ? _state.selectedLocation.LocId : '';
    }

    if (!formData.hasOwnProperty('UserId')) {
      formData.UserId = _state.data ? _state.data.Id : '';
    }

    return formData;
  }

  getApiData(formData?: any) {
    if (!formData) {
      formData = {};
    }

    formData.SessionId = _state.data ? _state.data.SessionId.toString() : '';
    formData.LspId = _state.selectedCompany ? _state.selectedCompany.LspId : '';
    formData.LocId = _state.selectedLocation ? _state.selectedLocation.LocId : '';

    if (!formData.hasOwnProperty('UserId')) {
      formData.UserId = _state.data ? _state.data.Id : '';
    }

    return formData;
  }

  logout() {
    sessionStorage.removeItem('llxBackOfficeBannerIsOpen');
    _state.data = false;
    _state.companies = false;
    _state.selectedCompany = false;
    _state.locations = false;
    _state.selectedLocation = false;
    AjaxPromises.POST(EndPoints.LOGOUT, {}).then((res) => {
      const cookies = new Cookies();
      cookies.remove('LeafLogixSessionInfo', { path: '/' });
      cookies.remove('LLSession', { path: '/' });
      if (res && res.Result && res.Data) {
        window.location.href = res.Data;
      }
    }).finally(() => {
      this.emitChange({ lspChange: true, locChange: true, logout: true });
    });
  }

  getTableSettings(table) {
    if (!table) {
      return false;
    }

    return _state.tableSettings.filter((x) => x.table === table)[0];
  }

  addLocation(location) {
    _state.locations.push(location);
  }

  emitChange(callback) {
    this.emit(UserEvents.CHANGE_EVENT, callback);
  }

  addChangeListener(callback) {
    this.on(UserEvents.CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(UserEvents.CHANGE_EVENT, callback);
  }

  getFeatures() {
    return _state.features;
  }

  getValidatedForms() {
    if (
      _state.locProfile.UseBioTrack &&
      _state.locProfile.IntegratedState &&
      _state.locProfile.IntegratedState.toLowerCase() === 'ny'
    ) {
      return _state.validatedForms;
    }

    return _state.validatedForms.filter(
      (validatedForm) => validatedForm.ReactName.toLowerCase() !== 'potencyindicator'
    );
  }

  isFieldRequired(FormName, FieldName, isCannabisProduct = false) {
    const field = _state.validatedForms.filter(
      (x) => x.FormName.toUpperCase() == FormName.toUpperCase() && x.ReactName.toUpperCase() == FieldName.toUpperCase()
    );

    if (field[0]) {
      return (
        field[0].CustomerOption == FieldOption.Required ||
        (field[0].CustomerOption == FieldOption.RequiredIfCannabis && isCannabisProduct) ||
        field[0].SystemRequired
      );
    }
    return false;
  }

  isFieldHidden(FormName, FieldName) {
    const field = _state.validatedForms.filter(
      (x) => x.FormName.toUpperCase() == FormName.toUpperCase() && x.ReactName.toUpperCase() == FieldName.toUpperCase()
    );

    if (field[0]) {
      return field[0].CustomerOption == FieldOption.Hide;
    }
    return false;
  }

  isFormValid(FormName, Form, allowZeroFieldNames = [], fieldsWithUnits = {}) {
    const isCannabisProduct = Form.IsCannabisProduct ? Form.IsCannabisProduct.toLowerCase() == 'yes' : false;

    for (const Field in Form) {
      const value = Form[Field];
      const unitField = fieldsWithUnits[Field];

      if (this.isFieldRequired(FormName, Field, isCannabisProduct)) {
        if (!value || value == 0) {
          if (value != 0 || allowZeroFieldNames.indexOf(Field) === -1) {
            return false;
          }
        }

        if (typeof value === 'string' && !value.trim()) {
          return false;
        }

        if (!!value && !!unitField && !Form[unitField]) {
          return false;
        }
      }
    }
    return true;
  }

  // Returns an array of invalid form fields
  isFormValidDetailed(FormName, Form, allowZeroFieldNames = [], fieldsWithUnits = {}) {
    const invalidFields = [];
    const isCannabisProduct = Form.IsCannabisProduct ? Form.IsCannabisProduct.toLowerCase() == 'yes' : false;

    for (const Field in Form) {
      const value = Form[Field];
      const unitField = fieldsWithUnits[Field];

      if (this.isFieldRequired(FormName, Field, isCannabisProduct)) {
        if (!value || value == 0) {
          if (value != 0 || allowZeroFieldNames.indexOf(Field) === -1) {
            if (invalidFields.indexOf(Field) === -1) {
              invalidFields.push(Field);
            }
          }
        }

        if (typeof value === 'string' && !value.trim()) {
          if (invalidFields.indexOf(Field) === -1) {
            invalidFields.push(Field);
          }
        }

        if (!!value && !!unitField && !Form[unitField]) {
          if (invalidFields.indexOf(Field) === -1) {
            invalidFields.push(Field);
          }
        }
      }
    }

    return invalidFields;
  }

  getIntegrations() {
    return _state.integrations;
  }

  getLspSettings() {
    return _state.lspSettings;
  }

  isLocationInState(stateAbbreviation) {
    const locationState = (_state.selectedLocation.State || '').toLowerCase();
    return stateAbbreviation.toLowerCase() === locationState;
  }

  inventoryFunctionsWorkOnWholePackages() {
    // When individual items within packages are uniquely identified (NJ), cannot allow inventory
    // functions to work on partial quantities because there's no way to specify which item ids
    // are affected.
    const ldClient = getLDClient();
    return (
      _state.locProfile.PackageItemIds &&
      ldClient.variation('pos.rollout.nj-inventory-manipulate-whole-packages', false)
    );
  }

  dispatchToken;
}

const UserStore = new UserStoreClass();

UserStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppEvents.APP_BOOT:
      _state.locations = action.data.locations;
      _state.selectedLocation = action.data.selectedLocation;
      _state.companies = action.data.companies;
      _state.selectedCompany = action.data.selectedCompany;
      _state.data = action.data.user;
      _state.locProfile = action.data.locProfile;
      _state.tableSettings = action.data.tableSettings;
      _state.features = action.data.features;
      _state.validatedForms = action.data.validatedForms;
      _state.integrations = action.data.integrations;
      _state.lspSettings = action.data.lspSettings;
      _state.customerTypes = action.data.customerTypes;

      try {
        // eslint-disable-next-line lodash/prefer-constant
        const FullStory = (window as any).FS || { identify: () => null };
        FullStory.identify(action.data.user.Id, {
          ...action.data.user,
          displayName: action.data.user.FullName,
          email: action.data.user.UserName,
        });
      } catch (ex) {
        console.warn(ex);
      }

      try {
        LogRocket.identify(action.data.user.Id, {
          ...action.data.user,
          displayName: action.data.user.FullName,
          email: action.data.user.UserName,
        });
      } catch (ex) {
        console.warn(ex);
      }

      try {
        const pendo = (window as any).pendo ?? {};
        pendo.initialize({
          visitor: {
            id: action.data.user.Id,
            email: action.data.user.UserName,
            full_name: action.data.user.FullName,
            LocId: action.data.user.LocId,
            LspId: action.data.user.LspId,
            LocName: action.data.selectedLocation?.Name,
            LspName: action.data.selectedCompany?.LspName,
            Deployment: ConfigStore.getRegion(),
            location_id_and_region: `${Number(action.data.user.LocId)}${ConfigStore.getRegion() as unknown as string}`,
            State: action.data.selectedLocation.State,
          },
          account: {
            id: action.data.user.LocId,
          },
        });
      } catch (error) {
        console.warn(error);
      }

      try {
        (window as any).appInsights?.setAuthenticatedUserContext(action.data.user.Id);
      } catch (ex) {
        console.warn(ex);
      }

      setTimeout(() => {
        AppDispatcher.dispatch({
          ...action,
          actionType: AppEvents.APP_BOOTED,
        });
      }, 0);

      UserStore.emitChange({ lspChange: true, locChange: true });
      break;

    case UserActions.SELECT_COMPANY:
      _state.locations = action.data.locations;
      _state.selectedCompany = action.data.currentCompany;
      _state.selectedLocation = action.data.currentLocation;
      _state.data.LspId = action.data.currentCompany.LspId;
      _state.data.LocId = action.data.currentLocation.LocId;

      try {
        const pendo = (window as any).pendo ?? {};
        pendo.updateOptions({
          visitor: {
            id: _state.data.Id,
            email: _state.data.UserName,
            full_name: _state.data.FullName,
            LocId: _state.data.LocId,
            LspId: _state.data.LspId,
            LocName: action.data.currentLocation?.Name,
            LspName: action.data.currentCompany?.LspName,
            Deployment: ConfigStore.getRegion(),
            location_id_and_region: `${Number(action.data.user.LocId)}${ConfigStore.getRegion() as unknown as string}`,
            State: action.data.currentLocation.State,
          },
          account: {
            id: _state.data.LocId,
          },
        });
      } catch (error) {
        console.warn(error);
      }

      UserStore.emitChange({ lspChange: true, locChange: true });
      break;

    case UserActions.SELECT_LOCATION:
      _state.selectedLocation = action.data;
      _state.data.LocId = action.data.LocId;
      _state.locProfile = action.locProfile;
      _state.features = action.features;
      _state.validatedForms = action.validatedForms;
      _state.integrations = action.integrations;

      _state.lspSettings = action.lspSettings;
      _state.customerTypes = action.customerTypes;

      setTimeout(() => {
        AppDispatcher.dispatch({
          actionType: AppEvents.LOCATION_LOADED,
        });
      }, 0);

      try {
        const pendo = (window as any).pendo ?? {};
        pendo.updateOptions({
          visitor: {
            id: _state.data.Id,
            email: _state.data.UserName,
            full_name: _state.data.FullName,
            LocId: _state.data.LocId,
            LspId: _state.data.LspId,
            LocName: action.data.Name,
            LspName: _state.selectedCompany?.LspName,
            Deployment: ConfigStore.getRegion(),
            location_id_and_region: `${Number(action.data.user.LocId)}${ConfigStore.getRegion() as unknown as string}`,
            State: action.data.State,
          },
          account: {
            id: _state.data.LocId,
          },
        });
      } catch (error) {
        console.warn(error);
      }

      UserStore.emitChange({ lspChange: false, locChange: true, featuresChange: true });
      break;

    case UserActions.SET_TABLE_SETTINGS:
      var table = action.data.table || '';
      var layout = action.data.layout || {};
      var match = _.find(_state.tableSettings, (s: any) => s.table === table);

      if (match) {
        // For reasons unknown, `match.layout = layout` does not work.
        match = { ...match, layout };
        _state.tableSettings = [..._state.tableSettings.filter((setting) => setting.table !== table), match];
      } else {
        _state.tableSettings.push({ table, layout });
      }

      UserStore.emitChange({ tableSettingsChange: true });
      break;

    case UserActions.SET_RESTRICTED_HOURS: {
      const newLocProfile = _.cloneDeep(_state.locProfile);
      newLocProfile.RestrictWindowStart = action.data.RestrictWindowStart;
      newLocProfile.RestrictWindowEnd = action.data.RestrictWindowEnd;
      _state.locProfile = newLocProfile;

      UserStore.emitChange({});
      break;
    }

    case UserActions.SET_DELIVERY_HOURS: {
      const newLocProfile = _.cloneDeep(_state.locProfile);
      newLocProfile.DefaultDeliveryWindowStart = action.data.DeliveryWindowStart;
      newLocProfile.DefaultDeliveryWindowEnd = action.data.DeliveryWindowEnd;
      _state.locProfile = newLocProfile;

      UserStore.emitChange({});
      break;
    }

    case UserActions.SET_FEATURES: {
      _state.features = action.data.features;
      _state.integrations = action.data.integrations;
      _state.lspSettings = action.data.lspSettings;

      const newLocProfile = _.cloneDeep(_state.locProfile);

      action.data.features.forEach((feature) => {
        newLocProfile[feature.FeatureName] = feature.IsEnabled;
      });

      const locProfileWithIntegrations = Object.assign(newLocProfile, action.data.integrations);
      _state.locProfile = locProfileWithIntegrations;

      UserStore.emitChange({ featuresChange: true });

      break;
    }
    case UserActions.SET_VALIDATED_FORMS:
      _state.validatedForms = action.data;
      break;

    case AuthActions.SSO_LOGIN_ENABLED:
      _state.isSSOLoginEnabled = action.data;
      UserStore.emitChange({ ssoLoginEnabled: true });
      break;

    case AuthActions.SSO_LOGIN:
      if (!action.data) {
        UserStore.emitChange({ ssoLogin: true, error: true });
      }
      break;
  }
});

export default UserStore;
