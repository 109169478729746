import { EventEmitter } from 'events';
import AppDispatcher from '../utils/AppDispatcher';

import { actions as NotificationActions, events as NotificationEvents } from '../constants/NotificationConstants';

// var dev     = process.env.NODE_ENV !== 'production' ? true : false;

const _state = {
  notifications: [],
};

class NotificationStoreClass extends EventEmitter {
  getState() {
    return _state;
  }

  emitChange(callback?) {
    this.emit(NotificationEvents.CHANGE_EVENT, callback);
  }

  addChangeListener(callback) {
    this.on(NotificationEvents.CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(NotificationEvents.CHANGE_EVENT, callback);
  }
  dispatchToken;
}

const NotificationStore = new NotificationStoreClass();

NotificationStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case NotificationActions.ADD_NOTIFICATION:
      _state.notifications.push(action.data);
      NotificationStore.emitChange();
      break;

    case NotificationActions.REMOVE_NOTIFICATION:
      for (let i = 0; i < _state.notifications.length; i++) {
        if (action.data === _state.notifications[i].uuid) {
          _state.notifications[i].show = false;
        }
      }

      NotificationStore.emitChange();
      break;
  }
});

export default NotificationStore;
