const keyMirror = require('keymirror');

module.exports = {
  endPoints: {
    RUN_REPORT_DASHBOARD: 'api/posv3/reports/run-report-dashboard',
    RUN_REPORT: 'api/posv3/reports/run-report',
    GET_DAILY_SALES_SUMMARY: 'api/bi/v2/DailyTotals',
  },

  actions: keyMirror({
    SET_DATE: null,
    SET_MONTH: null,
    // REPORT_LOADED 			: null,
    // DAILY_SALES_LOADED      : null,
  }),

  events: keyMirror({
    CHANGE_EVENT: 'changeEvent',
  }),
};
