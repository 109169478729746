import AjaxPromises from '../utils/AjaxPromises';
import { SimpleCachedDataStore } from './SimpleCachedDataStore';
import UserStore from './UserStore';
import NotificationActions from '../actions/NotificationActions';

class CustomerTypeStore extends SimpleCachedDataStore {
  sourceUrl = 'api/posv3/maintenance/get-customer-types';
  entity = 'Customer Types';
  get types() {
    return this.data;
  }

  alltypes: any[];
  async getAllCustomerTypes() {
    const req = UserStore.getApiData();
    try {
      if (!this.alltypes) {
        const resp = await AjaxPromises.POST('api/posv3/maintenance/get-all-customer-types', req);
        if (resp.Result) {
          this.alltypes = resp.Data;
          return this.alltypes;
        }
      } else {
        return this.alltypes;
      }
    } catch {
      NotificationActions.error('Failed to load Customer Types');
    }
  }
}
export default new CustomerTypeStore();
