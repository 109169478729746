import React, { lazy, Suspense, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot, useRecoilState, useRecoilValue } from 'recoil';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { BrowserRouter as RouterOld } from 'react-router-dom';
import { BrowserRouter as RouterV6 } from 'react-router-dom-6';
import { I18nextProvider } from 'react-i18next';
import { datadogRum } from '@datadog/browser-rum';
import { useFlags, useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { getLDClient, registerLDClient } from './app/utils/launch-darkly';

import i18n from './app_deprecated/utils/lang/i18n';
import { analyticsAtom } from './app/state/analytics';
import { localIAAtom } from './app/state/local-ia-flag';
import { userAtom, userIsLoggedInSelector } from './app/state/user';
import { userDispensariesAtom } from './app/state/user-dispensaries';
import { flagIsEnabledForLocation } from './app/utils/evalutate-location-feature-flag';
import { localNudgedRedirectFlagAtom } from 'src/app/state/local-nudged-redirect-flag';
import { localOptionalRedirectFlagAtom } from 'src/app/state/local-optional-redirect-flag';
import LogRocket from 'logrocket';

import './assets/css/main.scss';

const App = lazy(() => import('./app/app'));
const AppDeprecated = lazy(() => import('./app_deprecated/components/app/App.react'));

// Import all the stores so they register for any App_BOOT events
// Do not remove these or the app won't work correctly
/* eslint-disable */
import AccountingStore from './app_deprecated/stores/AccountingStore';
import AppStore from './app_deprecated/stores/AppStore';
import ConfigStore from './app_deprecated/stores/ConfigStore';
import CultivationStore from './app_deprecated/stores/CultivationStore';
import CustomerTypeStore from './app_deprecated/stores/CustomerTypeStore';
import DeliveryStore from './app_deprecated/stores/DeliveryStore';
import ExternalCategoriesStore from './app_deprecated/stores/ExternalCategoriesStore';
import MMURFormsStore from './app_deprecated/stores/MMURFormsStore';
import GeneralStore from './app_deprecated/stores/GeneralStore';
import InventoryStore from './app_deprecated/stores/InventoryStore';
import LabelStore from './app_deprecated/stores/LabelStore';
import NotificationStore from './app_deprecated/stores/NotificationStore';
import PosStore from './app_deprecated/stores/PosStore';
import PricingStore from './app_deprecated/stores/PricingStore';
import PrinterStore from './app_deprecated/stores/PrinterStore';
import ProductTaxCategoryStore from './app_deprecated/stores/ProductTaxCategoryStore';
import PurchaseLimitCategoryStore from './app_deprecated/stores/PurchaseLimitCategoryStore';
import RecipeBatchStore from './app_deprecated/stores/RecipeBatchStore';
import ReportDashboardStore from './app_deprecated/stores/ReportDashboardStore';
import ReportingStore from './app_deprecated/stores/ReportingStore';
import SavedOrderStore from './app_deprecated/stores/SavedOrderStore';
import TaxRateStore from './app_deprecated/stores/TaxRateStore';
import UserStore from './app_deprecated/stores/UserStore';
import AjaxPromises from './app_deprecated/utils/AjaxPromises';
import { endPoints } from './app_deprecated/constants/AppConstants';
import { getDutchiePOSURL } from './app/utils/get-dutchie-pos-url';
import { redirectToBackoffice } from './app/utils/redirect-to-backoffice';
/* eslint-enable */

if (process.env.NODE_ENV !== 'production') {
  console.log('IN DEV MODE');
} else {
  LogRocket.init('zg2tcu/leaflogix-boh');
  // eslint-disable-next-line
  require('./app_deprecated/fullstory');
  try {
    const appInsights = new ApplicationInsights({
      config: { instrumentationKey: '4bbbd6ac-26f4-40aa-b186-0f63a12e4590' },
    });
    appInsights.loadAppInsights();
  } catch (ex) {
    console.warn('Error loading AppInsights:', ex);
  }
}

if (process.env.NODE_ENV !== 'development') {
  datadogRum.init({
    applicationId: '10903b6c-cda7-441e-9dcd-1c47f257d33b',
    clientToken: 'puba89f34af88ced4c9af6c1fa4a4d389f6',
    site: 'datadoghq.com',
    service: 'back-office',
    version: process.env.VERSION_NUMBER || '',
    sampleRate: 100,
    replaySampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [/https:\/\/.*\.leaflogix\.net/],
  });
  datadogRum.startSessionReplayRecording();
}

/* eslint-disable */
// pendo init
(function (apiKey) {
  (function (p, e, n, d, o) {
    let v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = o._q || [];
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
    for (w = 0, x = v.length; w < x; ++w) {
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
          };
      })(v[w]);
    }
    y = e.createElement(n);
    y.async = !0;
    y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, 'script', 'pendo');
})('28a64b1a-322b-48a4-6319-f6c7ad86b901');
/* eslint-enable */

function Root() {
  const nudgedRedirectEnabledLocally = useRecoilValue(localNudgedRedirectFlagAtom);
  const optionalRedirectEnabledLocally = useRecoilValue(localOptionalRedirectFlagAtom);

  const flags = useFlags();
  const ldClient = useLDClient();
  const [analytics, setAnalytics] = useRecoilState(analyticsAtom);
  const useNewIAUserToggle = useRecoilValue(localIAAtom);
  const userIsLoggedIn = useRecoilValue(userIsLoggedInSelector);
  const userDispensaries = useRecoilValue(userDispensariesAtom);
  const user = useRecoilValue(userAtom);
  const selectedCompany = userDispensaries.selectedCompany;
  const selectedLocation = userDispensaries.selectedLocation;
  const forceNewIA = flagIsEnabledForLocation('boh-force-new-ia', flags, selectedLocation.LocId);
  const useNewIA = forceNewIA || useNewIAUserToggle;
  // decided not to add an atom for this because it'll be completely removed soon
  const region = ConfigStore.getRegion();

  useEffect(() => {
    const loadAnalytics = async () => {
      const [response] = await AnalyticsBrowser.load({ writeKey: process.env.SEGMENT_KEY });
      setAnalytics(response);
    };
    void loadAnalytics();
  }, []);

  useEffect(() => {
    async function identifyUserAndConditionallyRedirectToBackoffice() {
      const targetedFlags = await ldClient.identify({
        key: user.UserName,
        email: user.UserName,
        custom: {
          locId: selectedLocation.LocId,
          lspId: selectedCompany.LspId,
          region: region,
          host: window.location.hostname,
        },
      });

      const forcedRedirectFlagEnabled = targetedFlags['pos.register.dutchie-pos-redirect.rollout'];
      const nudgedRedirectWithOptOutFlagEnabled = targetedFlags['pos.register.redirect-unless-opted-out.rollout'];
      const optionalRedirectWithOptOutFlagEnabled = targetedFlags['boh-show-dutchie-pos-toggle'];

      // forced redirect
      if (forcedRedirectFlagEnabled) {
        redirectToBackoffice();
        return;
      }

      // nudged redirect with opt out
      if (nudgedRedirectWithOptOutFlagEnabled) {
        // default true
        if (nudgedRedirectEnabledLocally) {
          redirectToBackoffice();
        }
        return;
      }

      // optional redirect with opt out (fallback to previous behavior)
      if (optionalRedirectWithOptOutFlagEnabled) {
        // default false
        if (optionalRedirectEnabledLocally) {
          redirectToBackoffice();
        }
        return;
      }
    }

    if (ldClient && selectedCompany && selectedLocation && region) {
      identifyUserAndConditionallyRedirectToBackoffice();
    }
  }, [selectedCompany, selectedLocation, region, user]);

  useEffect(() => {
    if (analytics && userIsLoggedIn && selectedCompany && selectedLocation) {
      void analytics.identify(user.Id, {
        company_id: selectedCompany.LspId,
        company_name: selectedCompany.LspName,
        location_id: selectedLocation.LocId,
        location_name: selectedLocation.Name,
        region,
        user_email: user.UserName,
        user_full_name: user.FullName,
        user_is_in_new_ia: useNewIA,
      });
    }
  }, [analytics, useNewIA, userIsLoggedIn, region, user, selectedCompany, selectedLocation]);

  return (
    <Suspense fallback={<div />}>
      {useNewIA ? (
        <RouterV6>
          <App />
        </RouterV6>
      ) : (
        <RouterOld>
          <AppDeprecated />
        </RouterOld>
      )}
    </Suspense>
  );
}

(async () => {
  let ldKey = '';
  let region = '';
  try {
    const envDetails = await AjaxPromises.GET(endPoints.GET_ENVIRONMENT_DETAILS);
    if (envDetails.Result) {
      ldKey = envDetails.Data.LaunchDarklyClientKey;
      region = envDetails.Data.Region;
    }
  } catch (e) {
    console.warn(`Unable to load LaunchDarkly client key: ${e}`);
  }

  await registerLDClient(ldKey || process.env.LD_PRODUCTION_KEY || '', region || '');
  const ldClient = getLDClient();

  const RootWithLD = withLDProvider({
    ldClient: ldClient,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  })(Root);

  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <RecoilRoot>
        <RootWithLD />
      </RecoilRoot>
    </I18nextProvider>,
    document.getElementById('template')
  );
})();
