import { EventEmitter } from 'events';
import AppDispatcher from '../utils/AppDispatcher';
import { events as AppEvents } from '../constants/AppConstants';

const _state = {
  region: false,
};

class ConfigStoreClass extends EventEmitter {
  getRegion() {
    return _state.region;
  }

  dispatchToken;
}

const ConfigStore = new ConfigStoreClass();

ConfigStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppEvents.REGION:
      _state.region = action.data;
      break;
  }
});

export default ConfigStore;
