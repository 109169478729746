import AjaxPromises from '../utils/AjaxPromises.jsx';
import UserStore from '../stores/UserStore';
import { endPoints as EndPoints } from '../constants/ReportConstants';

export default {
  loadReportsForModule: () => {
    const userData = UserStore.getState();
    const user = userData.data;
    return AjaxPromises.POST(EndPoints.LOAD_REPORT_LIST, { ReportModule: 'MDSales', SessionId: user.SessionId });
  },

  runReport: (reportId, startDate, endDate) => {
    const userData = UserStore.getState();
    const user = userData.data;

    const data = {
      SessionId: user.SessionId,
      UserLoginId: user.Id,
      ReportModule: 'Billing',
      ReportId: reportId,
      FromDate: startDate,
      ToDate: endDate,
      LspId: userData.currentCompany.LspId,
      LocId: userData.currentLocation.LocId,
    };
    return AjaxPromises.POST(EndPoints.RUN_REPORT, data).then((resp) => {
      if (!resp.Result) {
        return [];
      }
      return resp.Data;
    });
  },
};
