import { SimpleCachedDataStore } from './SimpleCachedDataStore';

class TaxRateStore extends SimpleCachedDataStore {
  constructor() {
    super();
  }

  sourceUrl = 'api/tax/get-all';
  entity = 'Tax Rates';

  get taxRates() {
    return this.data;
  }
}

export default new TaxRateStore();
