const keyMirror = require('keymirror');

export const endPoints = {
  GET_UNITS: 'api/v2/inventory/get-units',

  INVENTORY_TRANSACTIONS: 'api/v2/inventory/Get-Inventory-Patient-Transactions',

  GET_ADJUSTMENT_REASONS: 'api/v2/inventory/get-adjustment-reasons',
  GET_DESTRUCTION_REASONS: 'api/v2/inventory/get-destruction-reasons',
  GET_INTEGRATION_DISCREPANCIES: 'api/v2/inventory/get-integration-discrepancies',
  REFERESH_METRC_PACKAGE: 'api/metrc/refresh-packages',

  ADD_PRODUCT_IMAGE: 'api/storefront/add-product-image',
  REMOVE_PRODUCT_IMAGE: 'api/storefront/remove-product-image',
  GET_PRODUCT_EXTRA_INFO: 'api/v2/inventory/get-product-extra-info',
  RECEIVE_INVENTORY: 'api/v2/inventory/receive',
  SAVE_RECEIVE_ORDER: 'api/v2/inventory/save-receive',
  PREVIEW_URL_RECEIVE: 'api/v2/inventory/preview-receive-url',
  PREVIEW_JSON_RECEIVE: 'api/v2/inventory/preview-receive-json',
  PREVIEW_XML_RECEIVE: 'api/v2/inventory/preview-receive-xml',
  PREVIEW_CSV_RECEIVE: 'api/v2/inventory/preview-receive-csv',
  PREVIEW_CSV_RECEIVE_NY: 'api/v2/inventory/preview-receive-csv-ny',

  UPDATE_INVENTORY_STATUS: 'api/v2/inventory/update-inventory-status',
  GET_PRODUCT_GROUPS: 'api/v2/inventory/get-product-groups',
  SET_PRODUCT_GROUP: 'api/v2/inventory/set-product-group',
  REMOVE_PRODUCT_GROUP: 'api/v2/inventory/remove-product-group',

  GET_INVENTORY_JOURNAL: 'api/v2/inventory/get-package-journal',
  GET_JOURNAL_DETAILS: 'api/v2/inventory/get-action-details',

  ASSIGN_TO_BATCH: 'api/v2/inventory/InventoryUpdateBatch',
  ASSIGN_TO_STATUS: 'api/v2/inventory/InventoryUpdateStatus',
  ASSIGN_TO_VENDOR: 'api/v2/inventory/InventoryUpdateVendor',

  GET_SAMPLE_RECIPIENTS: 'api/v2/inventory/get-sample-recipients',

  DESTROY_INVENTORY: 'api/v2/inventory/destroy',
  SET_PACKAGE_PRODUCT: 'api/v2/inventory/package/change-product',

  UPLOAD_LAB_RESULT: 'api/v2/lab/upload',
  BULK_UPLOAD_LAB_RESULT_CDX: 'api/v2/lab/upload-bulk-cdx',
  GET_CSV_HEADERS: 'api/v2/lab/get-csv-headers',
  BULK_UPLOAD_RECEIVE: 'api/v2/inventory/upload-bulk-receive',
  GET_RECEIVE_CSV_HEADERS: 'api/v2/inventory/get-csv-headers',

  GET_INVENTORY_HISTORY_BY_INV_ID: 'api/v2/inventory/get-inventory-id-history',
  GET_INVENTORY_ID_BY_PACKAGE_ID: 'api/v2/inventory/get-inventory-ids',
  GET_INVENTORY_ID_BY_PACKAGE_ID_LIST: 'api/v2/inventory/get-inventory-ids-for-packages',
  SCAN_PACKAGE_ITEM: 'api/v2/inventory/scan-package-item',
  GET_RECEIVE_HISTORY_TAX_CATEGORIES: 'api/v2/inventory/get-receive-history-tax-categories',
  RESYNC_INVENTORY_IDS: 'api/v2/inventory/resync-inventory-ids',
  REMOVE_INV_BY_ID: 'api/v2/inventory/remove-inventory-by-id',

  GET_INVENTORY_STATUS: 'api/v2/inventory/get-statuses',
  DELETE_INVENTORY_STATUS: 'api/v2/inventory/delete-status',

  BATCH_HISTORY_DETAIL: 'api/v2/inventory/batch-history-detail',

  GET_INVENTORY: 'api/v2/inventory/get-inventory',
  GET_ZERO_INVENTORY: 'api/v2/inventory/get-zero-inventory-packages',
  GET_PACKAGE_ALLOCATION: 'api/v2/inventory/get-package-allocation',
  REMOVE_PACKAGE_ALLOCATION: 'api/v2/inventory/remove-package-allocation',
  GET_PACKAGE_DETAILS: 'api/v2/inventory/get-package-details',
  UPDATE_PACKAGE: 'api/v2/inventory/update-package',

  GET_ROOMS: 'api/v2/room/get-rooms',
  UPDATE_ROOM: 'api/v2/room/update-room',
  DELETE_ROOM: 'api/posv3/maintenance/update-delete-room',

  GET_QUALIFYING_CONDITIONS: 'api/posv3/maintenance/get-qualifying-conditions',
  UPDATE_QUALIFYING_CONDITION: 'api/posv3/maintenance/update-qualifying-condition',

  GET_AUDIT_MOVER_PACKAGES: 'api/v2/inventory/get-audit-mover-packages',
  GET_AUDIT_ADD_PACKAGE_INVENTORY: 'api/v2/inventory/get-audit-add-package-inventory',

  // Actions
  MOVE_PACKAGE: 'api/v2/inventory/move',
  CREATE_PACKAGE: 'api/v2/inventory/create-package',
  GET_BATCHES: 'api/v2/inventory/get-batches',
  CONVERT_PACKAGE: 'api/v2/inventory/convert',
  SUBLOT_PACKAGE: 'api/v2/inventory/sublot',
  TRANSFER_PACKAGE_TO_CULTIVATION: 'api/v2/inventory/transfer-to-cultivation',
  UPLOAD_INVENTORY: 'api/v2/inventory/upload-inventory',
  CURE_FROM_INVENTORY: 'api/v2/inventory/cure-from-inventory',
  GET_TRANSFER_TO_CULTIVATION_BATCHES: 'api/v2/inventory/get-transfer-to-cultivation-batches',
  GET_DISCONTINUE_STATUS: 'api/v2/inventory/get-discontinue-status',
  DISCONTINUE_PACKAGE: 'api/v2/inventory/discontinue',
  FINISH_UNFINISH_PACKAGE: 'api/metrc/finish-unfinish',

  ADJUST_INVENTORY: 'api/v2/inventory/adjust',
  ADJUST_INVENTORY_PREVIEW: 'api/v2/inventory/preview-adjustment',
  RESTORE_INVENTORY: 'api/v2/inventory/restore',
  GET_RECEIVE_HISTORY: 'api/v2/inventory/get-receive-history',

  GET_INTEGRATION_RECON_EMAIL_CONFIG: 'api/integrations/get-integration-recon-email-config',
  SET_INTEGRATION_RECON_EMAIL_CONFIG: 'api/integrations/set-integration-recon-email-config',
  SEND_INTEGRATION_RECON_EMAIL: 'api/integrations/send-integration-recon-email',

  GET_SAVED_ORDER_DETAIL: 'api/v2/inventory/get-saved-order-detail',

  GET_GUEST_ADDRESS_LIST: 'api/posv3/backendpos/GetCustomerAddresses',
  SET_GUEST_ADDRESS: 'api/posv3/backendpos/UpdateCustomerAddresses',

  PRINT_PRODUCT_LABEL: 'api/posv3/print/print-product-barcodes',

  SET_PRODUCT_IMAGE: 'api/storefront/set-product-image',
  DELETE_PRODUCT_IMAGE: 'api/storefront/delete-product-image',

  COMBINE_INVENTORY: 'api/v2/inventory/combine',

  GENERATE_PACKAGE_ID: 'api/posv3/maintenance/GeneratePackageId',
  GENERATE_MULTI_PACKAGE_IDS: 'api/posv3/maintenance/GenerateMultiplePackageIds',
  GENERATE_IDS: 'api/v2/inventory/generate-ids',
  GENERATE_BATCH_ID: 'api/posv3/maintenance/GenerateBatchNumber',

  GET_PENDING_TRANSFERS: 'api/v2/inventory/get-pending-transfers',
  GET_PENDING_TRANSFER_ITEMS: 'api/v2/inventory/get-pending-transfer-items',
  SEARCH_ORDERS: 'api/v2/inventory/receive/search-orders',

  SAVE_RECEIVE_HEADER: 'api/v2/inventory/save-receive-header',
  SAVE_RECEIVE_NOTE: 'api/v2/inventory/save-receive-note',

  IS_RECEIVE_DELETABLE: 'api/v2/inventory/is-receive-deletable',
  DELETE_RECEIVE: 'api/v2/inventory/delete-receive',
  DELETE_SAVED_ORDER: 'api/v2/inventory/delete-saved-order',
  DELETE_METRC_PENDING_TRANSFER: 'api/v2/inventory/delete-metrc-pending-transfer',
  REOPEN_RECEIVE: 'api/v2/inventory/reopen-receive',
  GET_REOPENED_RECEIVES: 'api/v2/inventory/get-reopened-receives',

  UPDATE_RECEIVE_HISTORY_PRODUCT: 'api/v2/inventory/update-receive-history-product',

  GET_EXTERNAL_CATEGORIES: 'api/v2/inventory/get-external-categories',
  GET_MMUR_FORMS: 'api/mmur/get-forms',
  GET_MMUR_DEVICES: 'api/mmur/get-devices',
  GET_LOCATION_TYPES: 'api/v2/inventory/get-room-types',
  GET_MMUR_DEVICE_TYPE_FILTERS: 'api/mmur/get-order-type-form-filters-for-devices',

  GET_SAVED_ORDERS: 'api/v2/inventory/get-saved-orders',

  BATCH_MAP: 'api/v1.0/inventory/batch-map',

  UPDATE_INVENTORY_TAX_CATEGORIES: 'api/v2/inventory/update-inventory-tax-categories',

  // Brands
  GET_BRANDS: 'api/brand/get-brands',
  GET_INACTIVE_BRANDS: 'api/brand/get-inactive-brands',
  UPDATE_BRAND: 'api/brand/update-brand',
  DELETE_BRAND: 'api/brand/update-brand-deleted',

  // Lineages
  GET_LINEAGES: 'api/lineage/get-lineages',
  GET_INACTIVE_LINEAGES: 'api/lineage/get-inactive-lineages',
  UPDATE_LINEAGE: 'api/lineage/update-lineage',
  DELETE_LINEAGE: 'api/lineage/update-lineage-deleted',

  // Distillations
  GET_DISTILLATIONS: 'api/distillation/get-distillations',
  GET_INACTIVE_DISTILLATIONS: 'api/distillation/get-inactive-distillations',
  UPDATE_DISTILLATION: 'api/distillation/update-distillation',
  DELETE_DISTILLATION: 'api/distillation/update-distillation-deleted',

  // Vendors
  GET_VENDORS: 'api/vendor/get-vendors',
  GET_INACTIVE_VENDORS: 'api/vendor/get-inactive-vendors',
  UPDATE_VENDOR: 'api/vendor/update-vendor',
  GET_VENDOR_TYPE: 'api/vendor/get-vendor-types',

  // Strains
  UPDATE_STRAIN: 'api/strain/update-strain',
  GET_STRAINS: 'api/strain/get-strains',
  DELETE_STRAIN: 'api/strain/update-strains-deleted',

  // Dosage
  GET_DOSAGE: 'api/product-master/get-dosages',
  UPDATE_DOSAGE: 'api/product-master/update-dosage',
  DELETE_DOSAGE: 'api/product-master/update-dosage-deleted',

  // Sizes
  GET_SIZELIST: 'api/product-master/get-sizelist',
  UPDATE_SIZELISTITEM: 'api/product-master/update-sizelistitem',
  DELETE_SIZELISTITEM: 'api/product-master/update-sizelistitem-deleted',

  // Product Master
  GET_TRANSACTION_HISTORY: 'api/product-master/Transaction_History',
  UPDATE_PRODUCT: 'api/product-master/update-product',
  UPDATE_PRODUCT_CATEGORY: 'api/product-master/update-product-category',
  UPDATE_PRODUCT_CATEGORY_V2: 'api/product-master/v2/update-product-category',
  UPDATE_PRODUCT_CATEGORY_FLOWEREQ: 'api/product-master/update-product-category-flowereq',
  COPYDOWN_PRODUCT_CATEGORY: 'api/product-master/copydown-product-category',
  DELETE_PRODUCT_CATEGORY: 'api/product-master/update-product-category-deleted',
  UPDATE_BULK_PRODUCTS: 'api/product-master/update-products-multiple',
  UPDATE_BULK_PRODUCTS_LOCATION_SPECIFIC: 'api/product-master/update-products-location-specific-details-multiple',
  GET_PRODUCTS: 'api/product-master/get-product-master',
  GET_PRODUCT_DETAILS: 'api/product-master/get-product-details',
  GET_RETIRED_PRODUCTS: 'api/product-master/get-product-master-retired',
  GET_PRODUCT_CATEGORIES: 'api/product-master/get-product-categories',
  GET_REGULATORY_CATEGORIES: 'api/product-master/get-regulatory-categories',
  GET_PRODUCT_TAX_CATEGORY_RATES: 'api/product-master/get-product-tax-category-rates',
  GENERATE_SKU: 'api/product-master/generate-sku',
  VALIDATE_SKU: 'api/product-master/validate-sku',
  GET_COUPON_DETAILS: 'api/product-master/get-coupon-detail/',
  SET_COUPON_DETAILS: 'api/product-master/update-coupon',
  RETIRE_PRODUCT: 'api/product-master/retire-product',
  UNRETIRE_PRODUCT: 'api/product-master/unretire-product',

  // Multi-Stop Manifest specific endpoints: One manifest -> N Shipments
  UPDATE_MANIFEST_DRIVERS: 'api/v2/manifest/UpdateManifestDrivers',
  REOPEN_MULTI_STOP_MANIFEST: 'api/v2/manifest/reopen-multi-stop-manifest',
  COMPLETE_ORDER_MULTI_STOP: 'api/v2/manifest/complete-multi-stop',
  UPDATE_MANIFEST_STOPS: 'api/v2/manifest/UpdateManifestStops',
  CREATE_MULTI_MANIFEST: 'api/v2/manifest/create-multi',
  DEL_MULTI_MANIFEST: 'api/v2/manifest/DeleteMultiManifest',

  // Manifest
  UPDATE_MANIFEST: 'api/v2/manifest/UpdateManifest',
  GET_MANIFEST: 'api/v2/manifest/GetManifest',

  GET_DIRECTIONS: 'api/maps/get-route',
  UPDATE_MANIFEST_NOTES: 'api/v2/manifest/UpdateManifestNotes',
  UPDATE_MANIFEST_STATUS: 'api/v2/manifest/UpdateManifestStatus',
  UPDATE_MANIFEST_ITEM: 'api/v2/manifest/update-item',
  CREATE_MANIFEST: 'api/v2/manifest/create',
  GET_TRANFER_TEMPLATE_TYPES: 'api/metrc/get-transfer-template-types',
  COMPLETE_ORDER: 'api/v2/manifest/complete',
  ADD_TO_ORDER: 'api/v2/manifest/add-to-order',
  AUTO_FULFILL: 'api/v2/manifest/autofulfill',
  WCIA_EXPORT: 'api/v2/manifest/wcia-export',

  GET_MANIFESTS: 'api/v2/manifest/get-manifests',
  GET_RETAIL_MANIFESTS: 'api/v2/manifest/get-retail-manifests',
  GET_METRC_ONBOARDING_MANIFESTS: 'api/v2/manifest/get-metrc-onboarding-manifests',

  GET_MANIFEST_DETAILS: 'api/v2/manifest/get-detail',
  GET_RETAIL_MANIFEST_DETAILS: 'api/v2/manifest/get-retail-detail',
  REOPEN_MANIFEST: 'api/v2/manifest/reopen-manifest',
  DEL_MANIFEST: 'api/v2/manifest/DeleteManifest',
  DEL_RETAIL_MANIFEST: 'api/v2/manifest/DeleteRetailManifest',

  GET_PRODUCT_LOC_INFO: 'api/v2/inventory/get-product-loc-info',

  LEAF_MANIFEST_MARK_IN_TRANSIT: 'api/leaf/mark-manifest-in-transit',
  LEAF_VOID_MANIFEST: 'api/leaf/void-manifest',

  GET_ORDERS: 'api/v2/manifest/get-orders',
  GET_ORDER_DETAIL: 'api/v2/manifest/get-order-detail',
  REJECT_ORDER: 'api/v2/manifest/reject-order',
  DELETE_ORDER_DETAIL: 'api/v2/manifest/delete-order-item',
  FULFILL_ORDER: 'api/v2/manifest/fulfill-order',

  GET_ORDERS_BY_PRODUCT: 'api/v2/manifest/get-orders-by-product',

  SEARCH_MANIFEST: 'api/v2/manifest/search',

  REMOVE_ITEM_FROM_MANIFEST: 'api/v2/manifest/remove-from-order',

  // Purchase Orders
  GET_PURCHASE_ORDERS: 'api/purchasing/get-purchase-orders',
  GET_PENDING_PURCHASE_ORDERS: 'api/purchasing/get-pending-purchase-orders',
  GET_PURCHASE_ORDER_RECEIVE_ITEMS: 'api/purchasing/get-purchase-order-receive-items',
  GET_PURCHASE_ORDER_RECEIVE: 'api/purchasing/get-purchase-order-receive',
  GET_PURCHASE_ORDER_DETAILS: 'api/purchasing/get-purchase-order-details',
  UPDATE_PURCHASE_ORDER_ITEM: 'api/purchasing/update-purchase-order-item',
  UPLOAD_VENDOR_PO_MENU: 'api/purchasing/upload-vendor-po-menu',
  UPLOAD_PO_ITEMS: 'api/purchasing/upload-po-items',
  UPDATE_SUBMITTED_PURCHASE_ORDER_ITEM: 'api/purchasing/update-submitted-purchase-order-item',
  UPDATE_PURCHASE_ORDER: 'api/purchasing/update-purchase-order',
  DELETE_PURCHASE_ORDER_ITEM: 'api/purchasing/delete-purchase-order-item',
  DELETE_PURCHASE_ORDER: 'api/purchasing/delete-purchase-order',
  COMPLETE_PURCHASE_ORDER: 'api/purchasing/complete-purchase-order',
  SUBMIT_PURCHASE_ORDER: 'api/purchasing/submit-purchase-order',
  REOPEN_PURCHASE_ORDER: 'api/purchasing/reopen-purchase-order',

  EMAIL_PURCHASE_ORDER: 'api/purchasing/email-purchase-order',
  APPROVE_PURCHASE_ORDERS: 'api/purchasing/approve-purchase-orders',
  REJECT_PURCHASE_ORDERS: 'api/purchasing/reject-purchase-orders',

  // Tags
  GET_TAGS: 'api/posv3/maintenance/get-tags',
  SET_TAGS: 'api/posv3/maintenance/set-tags',
  DELETE_TAGS: 'api/posv3/maintenance/delete-tags',
  GET_PRODUCT_TAGS: 'api/product-master/get-product-tags',
};

export const actions = keyMirror({
  PRODUCT_UPDATED: null,
  INVENTORY_LOADED: null,
  PRODUCTS_LOADED: null,
  PRODUCTS_REFRESHED: null,
  UNITS_LOADED: null,
  VENDORS_LOADED: null,
  INVENTORY_LOAD_FAILURE: null,
  PRODUCTS_LOAD_FAILURE: null,
  VENDORS_LOAD_FAILURE: null,
});

export const events = keyMirror({
  CHANGE_EVENT: 'changeEvent',
});

export const eventTypes = keyMirror({
  ORDERS: 'orders',
  MANIFEST: 'manifest',
  RETAIL_MANIFEST: 'retail_manifest',
  METRC_ONBOARDING_MANIFEST: 'metrc_onboarding_manifest',
  LOADING_MANIFEST: 'loading_manifest',
  LOADING_RETAIL_MANIFEST: 'loading_retail_manifest',
  LOADING_METRC_RETAIL_MANIFEST: 'loading_metrc_onboarding_manifest',
});

export const costType = {
  PRODUCT_MASTER: 'Product',
  CALCULATED: 'Calculated',
  OTHER: 'Other',
};

const formatHeaders = (headers) => {
  var formattedHeaders = {};
  Object.keys(headers).forEach((x) => {
    formattedHeaders[x] = { field: { value: headers[x], title: headers[x], name: headers[x] } };
  });

  return formattedHeaders;
};

const albertaReceivesHeaderMapping = {
  'Product Name': 'Product Name',
  SKU: 'SKU',
  'Shipped Each Qty': 'Quantity',
  'Price Per Unit': 'Cost',
  Strain: 'Strain',
  'Each GTIN': 'GTIN',
  'Case GTIN': '',
  'Lot Code': 'Batch/Lot Number',
  'SKU Description': '',
  'Brand Name': '',
  'THC UOM': 'THCUnit',
  'THC Pre-Fix': 'THCPreFix',
  'THC Min': 'THCMin',
  'THC Max': 'THCMax',
  'CBD Pre-Fix': 'CBDPreFix',
  'CBD Min': 'CBDMin',
  'CBD Max': 'CBDMax',
  'CBD UOM': 'CBDUnit',
  'Dominant Terpene': '',
  'Master Case Each Qty': '',
  'Shipped Case Qty': '',
  'Order Number': '',
};

const bioTrackNewYorkReceivesHeaderMapping = {
  Location: '',
  Date: '',
  Product: 'Product Name',
  Strain: 'Strain',
  'Inventory ID': 'Package ID',
  Quantity: 'Quantity',
  Direction: '',
  Vendor: 'Vendor',
  Cost: 'Cost',
  'Batch #': 'Batch/Lot Number',
};

const ontarioReceivesHeaderMapping = {
  ShipmentID: '',
  ContainerID: '',
  SKU: 'SKU',
  ItemName: 'Product Name',
  UnitPrice: 'Cost',
  Vendor: 'Vendor',
  Brand: 'Brand',
  CaseGTIN: '',
  PackagedOnDate: '',
  BatchLot: 'Batch/Lot Number',
  GTINBarCode: '',
  EachGTIN: 'GTIN',
  Shipped_Qty: 'Quantity',
  UOM: '',
  UOMCONVERSION: '',
  UOMCONVERSIONQTY: '',
};

export const storedReceiveCSVMappings = [
  formatHeaders(bioTrackNewYorkReceivesHeaderMapping),
  formatHeaders(albertaReceivesHeaderMapping),
  formatHeaders(ontarioReceivesHeaderMapping),
];
