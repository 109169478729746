const keyMirror = require('keymirror');

module.exports = {
  endPoints: {
    GET_DAILY_SALES_SUMMARY: 'api/bi/v2/DailyTotals',

    GET_DISCOUNT_GROUP_LIST: 'api/posv3/maintenance/discount_group_lists',
    UPDATE_DISCOUNT_GROUP: 'api/posv3/maintenance/UpdateDiscountGroup',
    ADD_DISCOUNT_TO_GROUP: 'api/posv3/maintenance/AddDiscountToDiscountGroup',
    DELETE_DISCOUNT_FROM_GROUP: 'api/posv3/maintenance/DeleteDiscountFromDiscountGroup',
    GET_DISCOUNT_GROUP_DISCOUNTS: 'api/posv3/maintenance/get_discount_group_discounts',

    GET_LIST_OF_REGISTERS: 'api/posv3/maintenance/GetRegisters',
    UPDATE_REGISTER: 'api/posv3/maintenance/UpdateRegister',

    GET_REGISTER_TRANSACTIONS: 'api/posv3/backendpos/get-register-transactions',
    GET_CLOSEOUT_DETAILS: 'api/posv3/backendpos/CurrentCloseoutDetails',
    UPDATE_REGISTER_TRANSACTION: 'api/posv3/backendpos/UpdateRegisterTransaction',
    TRANSFER_REGISTER_TRANSACTION: 'api/posv3/backendpos/TransferRegisterTransaction',
    REGISTER_WITHDRAWAL: 'api/posv3/backendpos/RegisterWithdrawal',
    REGISTER_DEPOSIT: 'api/posv3/backendpos/RegisterDeposit',
    GET_TRANSACTION_DETAILS: 'api/v2/product/product_History_Detail',
    GET_LIST_OF_DOCTORS: 'api/posv3/maintenance/Doctors',
    UPDATE_DOCTOR_DETAILS: 'api/posv3/maintenance/UpdateDoctors',

    GET_ALL_FEES_DONATIONS: 'api/v2/fee-donation/get-all-fees-donations',
    SAVE_FEE_DONATION: 'api/v2/fee-donation/save-fee-donation',
    GET_CALCULATION_METHODS: 'api/v2/fee-donation/get-calculation-methods',

    /* DISCOUNTED PRODUCTS */
    GET_LIST_OF_DISCOUNTS: 'api/v2/discount/get-discount',
    GET_DISCOUNT_BY_ID: 'api/v2/discount/get-discount-by-id',
    GET_DISCOUNT_DETAILS: 'api/v2/discount/get-discount-record',
    UPDATE_DISCOUNT: 'api/v2/discount/update-discount',
    SAVE_DISCOUNT: 'api/v2/discount/update-discount-item',
    DELETE_DISCOUNT: 'api/v2/discount/set-delete-discount',
    SELECTED_PRODUCTS_FOR_DISCOUNT: 'api/v2/discount/SelectProductsForDiscount',
    GET_LIST_OF_PRODUCTS: 'api/v2/discount/select-discount-products',
    ADD_PRODUCT_TO_DISCOUNT: 'api/v2/discount/AddProductFromDiscount',
    REMOVE_PRODUCT_FROM_DISCOUNT: 'api/v2/discount/DeleteProductFromDiscount',
    CHECK_MANAGER_PASSWORD: 'api/v2/discount/CheckManagerApproval',
    COPY_DISCOUNT: 'api/posv3/pricing/copy-discount',

    GET_LIST_OF_PRICING: 'api/posv3/pricing/get-pricing',
    GET_PRODUCT_PRICING_OPTIONS: 'api/posv3/pricing/get-product-pricing-options',
    GET_PRICING_DETAIL: 'api/posv3/pricing/get-pricing-detail',
    UPDATE_PRICING_HDR: 'api/posv3/pricing/UpdatePricingHdr',
    UPDATE_PRICING_DTL: 'api/posv3/pricing/UpdatePricingDtl',

    REMOVE_PRICING_HDR: 'api/posv3/pricing/DeletePricingHdr',
    REMOVE_PRICING_DTL: 'api/posv3/pricing/DeletePricingDtl',
    RECONCILE_WEIGHTED_PRODUCTS: 'api/posv3/maintenance/ReconcileWeightedProduct',
    UPDATE_CONTAINER_WEIGHT: 'api/posv3/maintenance/UpdateContainerWeight',
    GET_CONTAINER_WEIGHT: 'api/posv3/maintenance/GetContainerWeight',

    GET_RETURN_REASONS: 'api/posv3/maintenance/LoadReturnReasons',
    SET_RETURN_REASONS: 'api/posv3/maintenance/UpdateReturnReasons',
    DELETE_RETURN_REASON: 'api/posv3/maintenance/DeleteReturnReason',

    GET_ADJUST_lOYALTY_REASONS: 'api/posv3/maintenance/LoadAdjustLoyaltyReasons',
    SET_ADJUST_lOYALTY_REASON: 'api/posv3/maintenance/UpdateAdjustLoyaltyReason',
    DELETE_ADJUST_lOYALTY_REASON: 'api/posv3/maintenance/DeleteAdjustLoyaltyReason',

    GET_TXN_ADJUSTMENT_REASONS: 'api/posv3/maintenance/get-txn-adjustment-reasons',
    SET_TXN_ADJUSTMENT_REASONS: 'api/posv3/maintenance/update-txn-adjustment-reason',
    DELETE_TXN_ADJUSTMENT_REASON: 'api/posv3/maintenance/delete-txn-adjustment-reason',

    REGISTER_CLOSE_OUT: 'api/posv3/backendpos/CloseOutPost',
    BULK_REGISTER_CLOSE_OUT: 'api/posv3/backendpos/bulk-close-out-post',
    REGISTER_DROP_CASH: 'api/posv3/backendpos/DropCash',
    BULK_REGISTER_DROP_CASH: 'api/posv3/backendpos/bulk-drop-cash',

    GET_CHARGE_CODES: 'api/posv3/pricing/GetChargeCodes',
    SET_CHARGE_CODES: 'api/posv3/pricing/UpdateChargeCodes',

    GET_TIER_GROUPS: 'api/posv3/pricing/GetPricingGroups',
    DELETE_TIER_GROUP: 'api/posv3/pricing/DeletePricingGroup',
    UPDATE_TIER_GROUP: 'api/posv3/pricing/UpdatePricingGroup',

    GET_DEFAULT_STATUS: 'api/posv3/maintenance/get-default-pos-status',
    UPDATE_DEFAULT_STATUS: 'api/posv3/maintenance/update-default-pos-status',

    GET_GUEST_CARD_OPTIONS: 'api/v2/card-status-display-options',
    SAVE_GUEST_CARD_OPTIONS: 'api/v2/card-status-display-options/save',

    GET_GL_STATUS_LIST: 'api/posv3/maintenance/get-pos-statuses',
    SET_GL_STATUS_LIST: 'api/posv3/maintenance/update-pos-status',
    DEL_GL_STATUS_LIST: 'api/posv3/maintenance/remove-pos-status',
    REORDER_STATUS_LIST: 'api/posv3/maintenance/order-pos-statuses',

    GENERATE_IDSCAN_WEBHOOK: 'api/idscan/generate-webhook',
    LOAD_IDSCAN_SETTINGS: 'api/idscan/get-settings',
    UPDATE_IDSCAN_SETTINGS: 'api/idscan/update-settings',
  },

  actions: keyMirror({
    DAILY_SUMMARY_LOADED: null,
    DISCOUNT_GROUP_LOADED: null,
    REGISTER_LIST_LOADED: null,
    DOCTOR_LIST_LOADED: null,
    DISCOUNT_LIST_LOADED: null,
    PRICING_LIST_LOADED: null,
    DISCOUNTS_LIST_LOADED: null,
    PRODUCT_TRANSFERRED: null,
    PRICING_TIER_LOADED: null,
    RETURN_REASONS_LOADED: null,
    LOYALTY_ADJUSTMENT_REASONS_LOADED: null,
    CALCULATION_METHODS_LOADED: null,
    TXN_ADJUSTMENT_REASONS_LOADED: null,
  }),

  events: keyMirror({
    CHANGE_EVENT: 'changeEvent',
    SEARCH_EVENT: 'changeEvent',
    REGISTERS_LOADED: 'registersLoaded',
  }),
};
