const keyMirror = require('keymirror');

module.exports = {
  endPoints: {
    GET_MOTHERS: 'api/Cultivation/GetMothers',
    // load all unique batches
    GET_PLANT_BATCHES: 'api/Cultivation/get-batches',
    GET_PLANT_BATCHES_LITE: 'api/Cultivation/get-batches-lite',
    GET_BATCHES: 'api/Cultivation/LoadBatches',

    //Batch Actions
    GENERATE_LOT_NO: 'api/Cultivation/GenerateLotNo',
    CREATE_LOT_NO: 'api/cultivation/CreateLotNo',
    MOVE_BATCHES: 'api/Cultivation/move-batches',
    SET_BATCH_UNIT: 'api/cultivation/UpdateBatchUnit',
    SET_BATCH_HEADER: 'api/harvest/create-update-batch',

    //Harvest
    GET_BATCH_DETAIL: 'api/Cultivation/LoadBatchdtl',
    GET_STRAIN_HARVEST_DATA: 'api/Cultivation/v2/GetStrainHarvestData',
    GET_COMPLETED_PROJECTED_BATCHES: 'api/Cultivation/v2/GetCompletedProjectedBatches',
    SET_BATCH_DETAIL: 'api/cultivation/UpdateBatchDtl',
    COMPLETE_HARVEST: 'api/Cultivation/complete-harvest',
    UNCOMPLETE_HARVEST: 'api/Cultivation/uncomplete-harvest',

    //Harvest Integration
    SET_HARVEST_STAGE: 'api/cultivation/set-harvest-stage',
    REPORT_PLANT_WEIGHTS: 'api/Cultivation/report-plant-weight',
    REPORT_DRY_WASTE: 'api/Cultivation/report-dry-waste',
    REPORT_WET_WASTE: 'api/Cultivation/report-wet-waste',
    SEND_WET_WEIGHT_TO_INVENTORY: 'api/Cultivation/send-wet-weight-to-inventory',

    //PlantHarvest
    GET_HARVEST_PLANT_LISTING: 'api/Cultivation/LoadBatchPlants',
    SET_PLANT_WEIGHTS: 'api/Cultivation/harvest/UpdatePlantWeights',
    SET_PLANT_HARVEST: 'api/Cultivation/PlantUpdateHarvest',
    REMOVE_PLANTS_FROM_HARVEST: 'api/Cultivation/harvest/remove-plants',

    //Harvest Reporting
    GET_HARVEST_BATCHES_FOR_ROOM: 'api/Cultivation/get-harvesting-batches',

    //Harvest Packaging
    GET_BATCH_PACKAGING: 'api/Cultivation/LoadBatchPackaging',
    SET_BATCH_PACKAGING: 'api/Cultivation/v2/UpdateBatchPackaging',
    DELETE_BATCH_PACKAGE: 'api/Cultivation/DeleteBatchPackaging',
    POST_BATCH_TO_INVENTORY: 'api/v2/inventory/PostPackagesToInventory',

    //Plants
    GET_PLANTS: 'api/Cultivation/LoadPlants',
    GET_MOTHERS: 'api/Cultivation/GetMothers',
    DELETE_BATCH: 'api/harvest/delete-batch',
    GET_PLANT_LABELS: 'api/cultivation/LoadLabels',
    GET_SEEDLINGS: 'api/cultivation/LoadSeedlings',
    METRC_RESET_PLANT_REPORT_WEIGHT_STATUS: 'api/Cultivation/metrc-reset-plant-report-weight-status',
    UPDATE_PLANT_TO_HARVEST: 'api/cultivation/UpdatePlantToHarvest',
    GET_PLANT_METADATA_TAGS: 'api/Cultivation/GetPlantMetadataTags',

    //Subrooms
    GET_BENCHES: 'api/Cultivation/LoadBenchesForLocation',
    GET_BENCH_BY_ROOM: 'api/Cultivation/LoadBenchesForLocationandRoom',
    GET_MAINTENANCE_BENCHES: 'api/posv3/maintenance/getbenches',
    SET_MAINTENANCE_BENCHES: 'api/posv3/Maintenance/UpdateBenches',
    DELETE_BENCH: 'api/posv3/Maintenance/update-delete-bench',

    GET_PRODUCT_BY_STRAIN_AND_TYPE: 'api/Cultivation/LoadProductByStrainAndType',

    GET_REASON_CODES: 'api/cultivation/LoadReasonCodes',
    SET_REASON_CODES: 'api/Cultivation/UpdateReasonCodes',

    VALIDATE_RECON_SCAN: 'api/cultivation/ValidateReconScans',
    CREATE_SAMPLE_PACKAGE: 'api/v2/inventory/create-sample',
    ADD_PLANTS_TO_INVENTORY: 'api/Cultivation/v2/AddPlantsToInventory',
    ADD_PLANTS_TO_INVENTORY_BULK: 'api/Cultivation/v2/AddPlantsToInventoryBulk',
    CHANGE_PLANT_TYPE: 'api/Cultivation/ChangePlantType',
    TRANSFER_PLANTS_TO_INVENTORY: 'api/Cultivation/TransferPlantsToInventory',

    //Plant Recon
    LOAD_RECONCILIATION_HISTORY: 'api/Cultivation/LoadReconciliationHistory',
    GET_RECON: 'api/inventory-reconciliation/Get-Reconciliations',
    GET_RECON_DETAILS: 'api/inventory-reconciliation/Get-Reconciliation-Detail',
    SET_RECON_DETAILS: 'api/inventory-reconciliation/update-reconciliation',
    CREATE_RECON: 'api/inventory-reconciliation/Create-Reconciliation',
    UPLOAD_RECON: 'api/inventory-reconciliation/Upload-Reconciliation',
    CLOSE_RECON: 'api/inventory-reconciliation/Close-reconciliation',
    DELETE_RECON: 'api/inventory-reconciliation/Delete-reconciliation',
    GET_VIEWABLE_RECON_LOCATIONS: 'api/inventory-reconciliation/viewable-reconciliation-locations',
    CHECK_RECON: 'api/inventory-reconciliation/Check-Reconciliation',
    GET_RECONCILIATION_LIST: 'api/plant-recon/get-all',
    GET_RECONCILIATION_DETAILS: 'api/plant-recon/get-detail',
    CLOSE_PLANT_RECON: 'api/plant-recon/close',
    CREATE_PLANT_RECON: 'api/plant-recon/create',

    DELETE_PLANT_RECON: 'api/plant-recon/delete',
    PLANT_RECON_PRESENT: 'api/plant-recon/action/present',
    PLANT_RECON_UNDO: 'api/plant-recon/action/undo',
    PLANT_RECON_DESTROY_PLANT: 'api/plant-recon/action/destroy',
    PLANT_RECON_MOVE_PLANT: 'api/plant-recon/action/move',
    PLANT_RECON_ADD_PLANT: 'api/plant-recon/action/add',

    //Plant Actions
    MOVE_PLANT_LOCATION: 'api/posv3/maintenance/move-plant-location',
    UPDATE_SEEDLING_COUNT: 'api/Cultivation/UpdateSeedlingCount',
    DESTROY_SEEDLING_COUNT: 'api/Cultivation/DestroyClones',
    DESTROY_CLONES_BULK: 'api/Cultivation/DestroyClonesBulk',
    ADJUST_IMMATURE_PLANT_COUNT: 'api/Cultivation/adjust-immature-plant-count',

    SET_PLANT_RETIRE_MULTIPLE: 'api/Cultivation/PlantUpdateRetireMultiple',
    SET_PLANT_RETIRE: 'api/Cultivation/PlantUpdateRetire',
    SET_PLANT_RETIRE_BY_NAME: 'api/Cultivation/PlantUpdateRetireScan',
    SCAN_PLANT_CHECK_IF_VALID: 'api/Cultivation/PlantScan',
    SET_PLANT_UN_RETIRE: 'api/Cultivation/PlantUpdateUnRetire',

    SET_PLANT_ROOM: 'api/Cultivation/PlantUpdateRoom',
    SET_PLANT_BENCH: 'api/Cultivation/PlantUpdateBench',
    SET_PLANT_IS_MOTHER_YES_NO: 'api/Cultivation/PlantUpdateSetMother',
    SET_PLANT_MOTHER: 'api/Cultivation/PlantUpdateMother',
    SET_PLANT_STAGE: 'api/Cultivation/PlantUpdateStage',
    SET_PLANT_BATCH: 'api/Cultivation/PlantUpdateBatch',

    GET_PLANT_NOTES: 'api/Cultivation/ViewPlantNotes',
    SET_PLANT_NOTES: 'api/Cultivation/UpdatePlantNotes',
    GET_BATCH_NOTES: 'api/Cultivation/ViewBatchNotes',
    SET_BATCH_NOTES: 'api/Cultivation/UpdateBatchNotes',

    //Waste Manager
    GET_WASTE_TYPES: 'api/Cultivation/get-waste-types',
    GET_WASTE: 'api/Cultivation/LoadWaste',
    SET_WASTE: 'api/Cultivation/UpdateWaste',
    DESTROY_WASTE: 'api/Cultivation/destroy-waste',
    GET_PLANTS_FOR_ROOM: 'api/Cultivation/get-plants-for-room',
    GET_WASTE_DETAILS: 'api/Cultivation/GetWasteDetails',
    GET_WASTE_METHODS: 'api/Cultivation/get-plant-waste-methods',
    GET_WASTE_REASONS: 'api/Cultivation/get-plant-waste-reasons',

    //Lab Results
    GET_LAB_RESULTS: 'api/lab/LoadLabResultSamples',
    SET_LAB_RESULTS: 'api/lab/UpdateLabResults',
    SET_LAB_RESULTS_OLD: 'api/lab/UpdateLabResults-obsolete',
    GET_LAB_RESULTS_INVENTORY_OLD: 'api/lab/LoadLabResultInventory-obsolete',
    SET_LAB_RESULTS_INVENTORY_OLD: 'api/lab/UpdateLabResultsInventory-obsolete',
    GET_LAB_RESULTS_INVENTORY: 'api/lab/LoadLabResultInventory',
    FETCH_LAB_RESULTS_INVENTORY: 'api/v2/lab/fetch',
    SET_LAB_RESULTS_INVENTORY: 'api/lab/UpdateLabResultsInventory',
    PARSE_LAB_RESULT_URL: 'api/v2/lab/parse-url',

    //Labels
    PRINT_PREVIEW_LABELS: 'api/v2/print-jobs/preview-pdf',
    PRINT_LABELS: 'api/v2/print-jobs/plant-labels',
    PRINT_PACKAGE_LABEL: 'api/v2/print-jobs/package-labels',

    //Nutrient Logs
    GET_AVAILABLE_NUTRIENTS: 'api/cultivation/nutrients/get-available',
    APPLY_NUTRIENTS: 'api/cultivation/nutrients/apply',
    GET_NUTRIENT_LOG: 'api/cultivation/nutrients/get-log',
    GET_INTEGRATION_DISCREPANCIES: 'api/cultivation/get-integration-discrepancies',

    //Plant Groups
    CREATE_PLANT_GROUP: 'api/Cultivation/create-update-plant-group',
    GET_PLANT_GROUPS: 'api/Cultivation/get-plant-groups',
    GET_PLANTS_FROM_GROUP: 'api/Cultivation/get-plants-from-group',
    ADD_PLANTS_TO_GROUP: 'api/Cultivation/add-plants-to-group',
    MOVE_PLANT_GROUP: 'api/Cultivation/move-plant-group',
    SET_PLANT_GROUP_PHASE: 'api/Cultivation/set-plant-group-phase',
    HARVEST_PLANT_GROUP: 'api/Cultivation/harvest-plant-group',
    RETIRE_PLANT_GROUP: 'api/Cultivation/retire-plant-group',
    EXPORT_PLANTS_TO_HARVEST: 'api/Cultivation/export-plants-harvest',
    IMPORT_PLANTS_TO_HARVEST: 'api/Cultivation/import-plants-harvest',
    TRIM_PLANTS: 'api/Cultivation/trim-plants',
    GET_PLANT_TRANSACTION_TYPES: 'api/Cultivation/GetPlantTransactionTypes',

    //API Queue
    GET_CHANGE_GROWTH_PHASE_QUEUES: 'api/v2/CultivationQueue/GetChangeGrowthPhaseQueues',
    GET_HARVEST_PLANT_QUEUES: 'api/v2/CultivationQueue/GetHarvestPlantQueues',
    GET_MOVE_PLANT_QUEUES: 'api/v2/CultivationQueue/GetMovePlantsQueues',
    GET_RETIRE_PLANT_QUEUES: 'api/v2/CultivationQueue/GetRetirePlantsQueues',
    GET_CHANGE_GROWTH_PHASE_QUEUE_PLANTS: 'api/v2/CultivationQueue/GetChangeGrowthPhaseQueuePlants',
    GET_HARVEST_PLANT_QUEUE_PLANTS: 'api/v2/CultivationQueue/GetHarvestPlantQueuePlants',
    GET_MOVE_PLANT_QUEUE_PLANTS: 'api/v2/CultivationQueue/GetMovePlantsQueuePlants',
    GET_RETIRE_PLANT_QUEUE_PLANTS: 'api/v2/CultivationQueue/GetRetirePlantsQueuePlants',
    APPROVE_REJECT_QUEUE_ITEM: 'api/v2/CultivationQueue/ApproveRejectItem',
    APPROVE_PLANT_STAGE_CHANGE: 'api/v2/CultivationQueue/ApprovePlantStageChange',
    APPROVE_PLANT_HARVEST_CHANGE: 'api/v2/CultivationQueue/ApprovePlantHarvestChange',
    APPROVE_PLANT_MOVE_CHANGE: 'api/v2/CultivationQueue/ApprovePlantMoveChange',
    APPROVE_PLANT_RETIRE_CHANGE: 'api/v2/CultivationQueue/ApprovePlantRetireChange',
  },

  actions: keyMirror({
    MOTHERS_LOADED: null,
    BATCHES_LOADED: null,
    LOT_NO_LOADED: null,
    BENCHES_LOADED: null,
    BENCHES_BY_ROOM_LOADED: null,
    PLANT_LABELS_LOADED: null,
    SEEDLINGS_LOADED: null,
    REASON_CODES_LOADED: null,
    PLANTS_ADDED_TO_INVENTORY: null,
    RECON_HISTORY_LOADED: null,
    PLANTS_LOADED: null,
    PLANT_GROUPS_LOADED: null,
    PROJECTED_BATCHES_LOADED: null,
    BATCHES_LITE_LOADED: null,
  }),

  events: keyMirror({
    CHANGE_EVENT: 'changeEvent',
  }),
};
