import { EventEmitter } from 'events';
import _ from 'lodash';
import AppDispatcher from '../utils/AppDispatcher';
import { events as DeliveryEvents, endPoints } from '../constants/DeliveryConstants';
import { actions as AppEvents } from '../constants/AppConstants';
import UserStore from './UserStore';
import AjaxPromises from '../utils/AjaxPromises.jsx';
import NotificationActions from '../actions/NotificationActions';
import HttpClient from '../utils/HttpClient';

const _state = {
  cars: [],
  drivers: [],
  zones: [],
  counties: [],
};

const _status = {
  cars: false,
  drivers: false,
  zones: false,
  counties: false,
};

class DeliveryStoreClass extends EventEmitter {
  constructor() {
    super();
    this.clear = this.clear.bind(this);
    this.actionHandler = this.actionHandler.bind(this);
    this.refreshCars = this.refreshCars.bind(this);
    this.refreshDrivers = this.refreshDrivers.bind(this);
    this.refreshZones = this.refreshZones.bind(this);
    // this.updateCar = this.updateCar.bind(this);
    // this.updateDriver = this.updateDriver.bind(this);
  }

  get cars() {
    if (!_status.cars) {
      _status.cars = true; // prevent multiple calls
      this.refreshCars();
    }
    return _state.cars;
  }

  get drivers() {
    if (!_status.drivers) {
      _status.drivers = true; // prevent multiple calls
      this.refreshDrivers();
    }
    return _state.drivers;
  }

  get zones() {
    if (!_status.zones) {
      _status.zones = true; // prevent multiple calls
      this.refreshZones();
    }
    return _state.zones;
  }
  get counties() {
    if (!_status.counties) {
      _status.counties = true; // prevent multiple calls
      this.refreshCounties();
    }
    return _state.counties;
  }

  public async refreshCounties() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_COUNTIES, data);
      if (resp.Result) {
        _status.counties = true;
        _state.counties = resp.Data;
      }
      this.emitChange(_state);
    } catch (e) {
      console.warn('Counties failed to load');
      _status.counties = false;
    }
  }

  getCountyDescription(county) {
    return `${county.CountyName} - ${county.StateCode}`;
  }

  public async refreshCars() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_CARS, data);
      if (resp.Result) {
        _status.cars = true;
        _state.cars = resp.Data;
      }
      this.emitChange(_state);
    } catch {
      console.warn('Cars failed to load');
      _status.cars = false;
    }
  }

  public async refreshDrivers() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await AjaxPromises.POST(endPoints.GET_DRIVERS, data);
      if (resp.Result) {
        _status.drivers = true;
        _state.drivers = resp.Data;
      }
      this.emitChange(_state);
    } catch {
      console.warn('Drivers failed to load');
    }
  }

  public async refreshZones() {
    try {
      const data = { ...UserStore.getApiData() };
      const resp = await HttpClient.post(endPoints.GET_ALL_ZONES, data, 'Zones failed to load');
      if (resp) {
        _status.zones = true;
        _state.zones = resp;
      }
      this.emitChange(_state);
    } catch {
      NotificationActions.error('Zones failed to load');
    }
  }

  emitChange(callback) {
    this.emit(DeliveryEvents.CHANGE_EVENT, callback);
  }

  addChangeListener(callback) {
    this.on(DeliveryEvents.CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(DeliveryEvents.CHANGE_EVENT, callback);
  }
  private clear() {
    _state.drivers = [];
    _status.drivers = false;
    _state.cars = [];
    _status.cars = false;
    _state.zones = [];
    _status.zones = false;
  }

  actionHandler(action) {
    switch (action.actionType) {
      case AppEvents.LOCATION_LOADED:
        this.clear();
        this.emitChange(_state);
        break;
    }
  }
}

const DeliveryStore = new DeliveryStoreClass();
AppDispatcher.register(DeliveryStore.actionHandler);
export default DeliveryStore;
