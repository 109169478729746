import { atom, selector } from 'recoil';
import UserStore from '../../app_deprecated/stores/UserStore';

type UserState = {
  Id?: number | string;
  SessionId?: string;
  UserName?: string;
  FullName?: string;
};

export const defaultUser: UserState = {
  Id: '',
  SessionId: '',
  UserName: '',
  FullName: '',
};

export const userAtom = atom({
  key: 'user',
  default: defaultUser,
  effects_UNSTABLE: [useSyncStateEffect],
});

function useSyncStateEffect({ setSelf, trigger }) {
  function changeListenerForUserState() {
    // user data is at UserStore.data
    const { data } = UserStore.getState();
    setSelf(data);
  }

  if (trigger === 'get') {
    changeListenerForUserState(); // initial call
  }

  // Subscribe to remote storage changes and update the atom value
  UserStore.addChangeListener(changeListenerForUserState);

  // Cleanup remote storage subscription
  return () => UserStore.removeChangeListener(changeListenerForUserState);
}

export const userIsLoggedInSelector = selector({
  key: 'userIsLoggedIn',
  get: ({ get }) => {
    const user = get(userAtom);

    // tests for this in ./state/__tests__/user.test.tsx
    // '1' > 0 => true
    // 1 > 0 => true
    // '' > 0 => false
    // undefined > 0 => false
    // null > 0 => false
    return user.Id > 0;
  },
});
