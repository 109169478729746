const keyMirror = require('keymirror');

module.exports = {
  endPoints: {
    LOGIN: 'api/posv3/user/EmployeeLogin',
    FORGOT: 'api/posv3/user/ForgotPassword',
    RESET: 'api/posv3/user/ResetPassword',
    LOGOUT: 'api/posv3/user/LogOut',
    PASSWORD_SETTINGS: 'api/security/password/settings',
  },

  actions: keyMirror({
    SSO_LOGIN_ENABLED: null,
    SSO_LOGIN: null,
  }),

  events: keyMirror({
    CHANGE_EVENT: 'changeEvent',
  }),
};
