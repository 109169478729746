import { LDClient, initialize } from 'launchdarkly-js-client-sdk';

let GlobalLDClient: LDClient | undefined;

export const registerLDClient = async (key: string, region: string) => {
  GlobalLDClient = initialize(key, {
    email: 'engineering@dutchie.com',
    key: 'eng-internal',
    custom: {
      region: region || '',
      host: window.location.hostname,
    },
  });

  await GlobalLDClient.waitUntilReady();
};

/// Please don't call this from the global scope!  It may not be initialized yet
export const getLDClient = () => GlobalLDClient;
